import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { Form, Layout } from 'antd';
import { Button, Input, useTheme } from '@prio365/prio365-react-library';
import { colon } from '../../../util/forms';
import { PrioTheme } from '../../../theme/types';
import { Content } from 'antd/lib/layout/layout';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { getSvgIconPathByType } from '../../../util/icon';
import SvgIcon from '../../../components/SvgIcon';
import {
  CreateProjectExtensionConfiguration,
  JiraConfiguration,
  defaultJiraConfiguration,
} from '../../../models/ProjectExtension';
import { apiCreateProjectExtensionConfiguration } from '../../projects/api';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  rootLayout: {
    margin: 0,
  },
  editContent: {
    backgroundColor: theme.colors.application.background.default,
  },
  form: {
    height: '100%',
    overflow: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  submitButton: {
    position: 'absolute',
    bottom: '32px',
  },
}));

interface JiramConfigProps {
  className?: string;
  projectExtensionConfigurationChanged: () => void;
}

export const JiraConfigurationCreate: React.FC<JiramConfigProps> = (props) => {
  //#region ------------------------------ Defaults
  const { projectExtensionConfigurationChanged } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const classes = useStyles();
  const { projektExtensionId } = useParams();
  const [dataForm] = Form.useForm<JiraConfiguration>();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  // issaving
  const [isSaving, setIsSaving] = useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Methods / Handlers

  // handle save
  const handleFinishData = async (newData: JiraConfiguration) => {
    const payload: CreateProjectExtensionConfiguration = {
      objectJson: JSON.stringify(newData),
      extensionType: 'jira',
      isEnabled: true,
    };

    //save phase
    setIsSaving(true);
    await apiCreateProjectExtensionConfiguration(payload);
    projectExtensionConfigurationChanged();
    setIsSaving(false);
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dataForm.setFieldsValue(defaultJiraConfiguration);
  }, [projektExtensionId, dataForm]);
  //#endregion

  return (
    <Layout className={classes.rootLayout}>
      <Content className={classes.editContent}>
        <>
          <Form
            layout="vertical"
            form={dataForm}
            className={classes.form}
            onFinish={handleFinishData}
          >
            <Flex.Row flex={1}>
              <SvgIcon
                width={35}
                height={35}
                path={getSvgIconPathByType('jira')}
              ></SvgIcon>
              <h3 style={{ marginTop: '3px', marginBottom: '32px' }}>
                Jira {t('settings:jiraConfig.connection')}
              </h3>
            </Flex.Row>
            <Flex.Row flex={1}>
              <Flex.Column flex={2} childrenGap={theme.spacing.small}>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  flex={1}
                  justifyContent="stretch"
                >
                  <Form.Item
                    name="displayName"
                    label={t('settings:jiraConfig.displayName')}
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                  <Form.Item
                    name="baseURL"
                    label="Jira URL"
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                </Flex.Row>
                <Form.Item
                  name="description"
                  label={t('settings:jiraConfig.description')}
                  colon={colon}
                >
                  <Input full />
                </Form.Item>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  flex={1}
                  justifyContent="stretch"
                >
                  <Form.Item
                    name="objectJson.serviceUserCredentials.mail"
                    label="Mail"
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                  <Form.Item
                    name="objectJson.serviceUserCredentials.apiKey"
                    label="API Token"
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                </Flex.Row>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  flex={1}
                  justifyContent="flex-end"
                >
                  <Button
                    disabled={isSaving}
                    onClick={() => {
                      dataForm.submit();
                    }}
                    htmlType="button"
                    className={classes.submitButton}
                  >
                    {t('settings:jiraConfig.save')}
                  </Button>
                </Flex.Row>
              </Flex.Column>
            </Flex.Row>
          </Form>
        </>
      </Content>
    </Layout>
  );
};

export default JiraConfigurationCreate;
