import React, { useCallback, useEffect, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { Tooltip } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveProject,
  getProject,
  getWidgetAreaDisplayState,
  RootReducerState,
  getAllProjects,
  getActiveProjectInMessageCenter,
  getLastOpenDriveItemFolder,
  getWidgetAreaActiveProjectId,
} from '../../../apps/main/rootReducer';
import {
  closeWidgetArea,
  openWidgetArea,
  selectWidget,
  setActiveProjectWidgetBar,
} from '../actions';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { Widget } from '../reducers';
import classNames from 'classnames';
import DocumentsWidget from '../../documents/components/Widget/DocumentsWidget';
import WidgetMailSelectionList from '../../mail/components/WidgetMailSelectionList';
import TasksWidget from '../../tasks/components/TasksWidget';
import { ProjectId } from '../../../models/Types';
import { createSelector } from 'reselect';
import { Project } from '../../../models/Project';
import { ResizeCallback } from 're-resizable';
import WidgetAreaResizable from './WidgetAreaResizable';
import WidgetBarUploadManagerNavigationButton from '../../documents/components/Widget/WidgetBarUploadManagerNavigationButton';
import UploadManager from '../../documents/components/Widget/UploadManager';
import { updateDriveItemPath } from '../../documents/actions/lastOpenDriveItemFolder';

const panelDefaultWidth = 400;
const panelMaxWidth = document.body.clientWidth - 380 - 320;
const iconBarWidth = 48;

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    borderLeft: theme.old.borders.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    zIndex: 500,
    backgroundColor: theme.old.palette.backgroundPalette.content,
  },
  hiddenElement: {
    height: '100%',
  },
  panelChild: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    borderLeft: theme.old.borders.content,
    position: 'relative',
    flexShrink: 0,
  },
  iconBar: {
    display: 'flex',
    flexDirection: 'column',
    width: iconBarWidth,
    flexShrink: 0,
    alignItems: 'center',
  },
  toggleButton: {
    background: 'transparent',
    marginBottom: theme.old.spacing.unit(1),
  },
  iconButtonActive: {
    backgroundColor: theme.colors.application.background.selected,
  },
  documents: {
    height: '100%',
    width: '100%',
    '& .ant-upload': {
      height: '100%',
      width: '100%',
    },
  },
}));

const isMyProjectSelector = (projectId: ProjectId) =>
  createSelector<[(state: RootReducerState) => Project[]], boolean>(
    getAllProjects,
    (projects) =>
      !projectId ||
      projectId === 'me' ||
      (projects ?? []).map((project) => project.projectId).includes(projectId)
  );

interface WidgetAreaProps {
  disablePersonalMessages?: boolean;
  selectedProjectId?: ProjectId;
}

export const WidgetArea: React.FC<WidgetAreaProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { disablePersonalMessages, selectedProjectId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { open, selectedWidget } = useSelector(getWidgetAreaDisplayState);
  const activeProjectId = useSelector(getActiveProject);
  const messageCenterProjectId = useSelector(getActiveProjectInMessageCenter);

  const projectId = useSelector(getWidgetAreaActiveProjectId);

  const [size, setSize] = useState<number>(panelDefaultWidth);

  const width = open ? size : iconBarWidth;

  const isMyProject = useSelector(isMyProjectSelector(projectId));

  const lastOpenFolderDriveItemIds = useSelector((state: RootReducerState) =>
    getLastOpenDriveItemFolder(state, projectId)
  );

  const project = useSelector<RootReducerState, Project>((state) =>
    getProject(state, projectId)
  );

  const isMe: boolean = (selectedProjectId ?? activeProjectId) === 'me';
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const toggle = () => {
    if (open) {
      dispatch(closeWidgetArea());
    } else {
      dispatch(openWidgetArea());
    }
  };

  const resetSize = () => {
    setSize(panelDefaultWidth);
  };

  const openAreaAndSelectWidget = (widget: Widget) => {
    if (!open) {
      dispatch(openWidgetArea(widget));
    } else if (widget !== selectedWidget) {
      dispatch(selectWidget(widget));
    } else if (open && widget === selectedWidget) {
      dispatch(closeWidgetArea());
    }
  };

  const handleResizeStop: ResizeCallback = (e, direction, ref, delta) => {
    if (!open) {
      if (delta.width + iconBarWidth < panelDefaultWidth) {
        setSize(panelDefaultWidth);
      } else {
        setSize(delta.width + iconBarWidth);
      }
      dispatch(openWidgetArea());
    } else {
      setSize(delta.width + size);
    }
  };

  const setProjectId = useCallback(
    (projectId: ProjectId) => {
      dispatch(setActiveProjectWidgetBar(projectId));
    },
    [dispatch]
  );

  const icon: (
    iconName: IconName,
    widget: Widget,
    disabled?: boolean
  ) => React.ReactNode = (iconName, widget, disabled) => (
    <Tooltip title={t(`common:widgetArea.${widget}`)} placement="left">
      <Button
        onClick={openAreaAndSelectWidget.bind(this, widget)}
        type="link"
        iconProp={['fal', iconName]}
        className={classNames({
          [classes.iconButtonActive]: widget === selectedWidget,
        })}
        disabled={disabled}
        style={{ marginTop: '8px' }}
      />
    </Tooltip>
  );
  //#endregion

  let widget: React.ReactNode = <></>;

  switch (selectedWidget) {
    case 'mail': {
      if (!!projectId && !isMe) {
        widget = !disablePersonalMessages ? (
          <WidgetMailSelectionList projectId={projectId} />
        ) : null;
      }
      break;
    }
    case 'planner': {
      widget = !!project?.masterPlanId && <TasksWidget projectId={projectId} />;
      break;
    }
    case 'documents': {
      widget = (
        <DocumentsWidget
          projectId={projectId}
          className={classes.documents}
          size={size}
          initialDriveItemId={lastOpenFolderDriveItemIds?.documentsWidget}
          onProjectIdChange={setProjectId}
        />
      );
      break;
    }
    case 'uploadManager': {
      widget = (
        <UploadManager
          projectId={projectId}
          onProjectIdChange={setProjectId}
          onParentDriveItemClick={(driveItemId) => {
            dispatch(
              updateDriveItemPath(projectId, {
                documentsWidget: driveItemId,
              })
            );
          }}
        />
      );
      break;
    }
  }

  //#region ------------------------------ Effects
  useEffect(() => {
    if (selectedWidget === 'mail' && disablePersonalMessages) {
      dispatch(closeWidgetArea(null));
    }
  }, [selectedWidget, disablePersonalMessages, dispatch]);

  useEffect(() => {
    if (!isMyProject && selectedWidget !== 'documents') {
      dispatch(closeWidgetArea());
    }
  }, [isMyProject, selectedWidget, dispatch]);

  useEffect(() => {
    if (selectedProjectId) {
      setProjectId(selectedProjectId);
    } else if (activeProjectId !== 'me') {
      setProjectId(activeProjectId);
    } else if (messageCenterProjectId && messageCenterProjectId !== 'me') {
      setProjectId(messageCenterProjectId);
    }
  }, [
    selectedProjectId,
    activeProjectId,
    messageCenterProjectId,
    setProjectId,
  ]);

  useEffect(() => {
    if (open && selectedWidget === 'mail' && (isMe || !projectId)) {
      dispatch(closeWidgetArea('documents'));
    }
  }, [open, isMe, projectId, selectedWidget, dispatch]);
  //#endregion

  return (
    <>
      <div className={classes.hiddenElement} style={{ width: width }} />
      <WidgetAreaResizable
        onResizeStop={handleResizeStop}
        size={{ width: size, height: '100%' }}
        minWidth={panelDefaultWidth}
        maxWidth={panelMaxWidth}
        closedMinWidth={iconBarWidth}
        open={open}
      />
      <div
        className={classes.root}
        style={{ width: open ? size : iconBarWidth }}
      >
        <div className={classes.iconBar}>
          {!disablePersonalMessages &&
            !isMe &&
            icon('inbox', 'mail', !isMyProject)}
          {icon('folder-tree', 'documents')}
          <WidgetBarUploadManagerNavigationButton
            onClick={() => openAreaAndSelectWidget('uploadManager')}
            isActive={selectedWidget === 'uploadManager'}
            widgetBarIsOpen={open}
            className={
              selectedWidget === 'uploadManager' && classes.iconButtonActive
            }
          />
          {!!project?.masterPlanId && icon('check', 'planner', !isMyProject)}
          <Flex.Item flex={1} />
          {size > panelDefaultWidth && open && (
            <Button
              type="link"
              onClick={resetSize}
              iconProp={['fal', 'chevron-right']}
              className={classes.toggleButton}
            />
          )}
          <Button
            type="link"
            onClick={toggle}
            iconProp={[
              'fal',
              open ? 'chevron-double-right' : 'chevron-double-left',
            ]}
            className={classes.toggleButton}
          />
        </div>
        {open && (
          <div
            className={classes.panelChild}
            style={{ width: size - iconBarWidth - 1 }}
          >
            {isMyProject ? widget : null}
          </div>
        )}
      </div>
    </>
  );
};

export default WidgetArea;
