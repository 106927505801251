import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  chartIconPositionAbsolute: {
    position: 'absolute',
    left: -32,
    top: 0,
  },
  chartIcon: {
    height: 64,
    width: 64,
    borderRadius: 32,
    border: `2px solid ${theme.colors.application.border}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface ChartIconProps {
  className?: string;
  isMinimal?: boolean;
  iconName: IconName;
  days: number;
  color: string;
  label?: string;
}

export const ChartIcon: React.FC<ChartIconProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, isMinimal = false, iconName, days, color, label } = props;
  const classes = useStyles();

  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames(
        classes.chartIcon,
        { [classes.chartIconPositionAbsolute]: !isMinimal },
        className
      )}
      style={{ backgroundColor: color }}
      title={label ?? undefined}
    >
      <span className="fa-layers" style={{ width: 28, height: 28 }}>
        <FontAwesomeIcon
          icon={['fal', iconName]}
          color={theme.old.typography.colors.inverted}
          style={{ width: 28, height: 28 }}
        />
        <span
          className="fa-layers-counter"
          style={{
            transform: 'scale(0.65)',
            backgroundColor: color,
            color: theme.old.typography.colors.inverted,
            right: '-0.25em',
            top: '-0.25em',
            outline: '3px solid #ffffff',
          }}
        >
          {days}
        </span>
      </span>
    </div>
  );
};

export default ChartIcon;
