import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import VirtualTable, {
  Column,
} from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterResultNoItemsScreen from '../../../components/Filter/FilterResultNoItemsScreen';
import moment from 'moment';
import XLSX from 'xlsx';
import { Dropdown, Menu, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import {
  DateTimeString,
  OfficeHolidayId,
  OfficeId,
} from '../../../models/Types';
import useOfficesContext from '../../companies/hooks/useOfficesContext';
import { GenericSearchResultItem } from '../../../components/Filter/types';
import { createTemporaryId } from '../../../util';
import { apiCopyOfficeHolidayToNextYear } from '../api';
import useAccessRights2 from '../../users/hooks/useAccessRights2';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  cell: {
    padding: theme.spacing.small,
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    cursor: 'pointer',
    '& button': {
      visibility: 'hidden',
    },
    '&:hover button': {
      visibility: 'visible',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  spacingLeftRight: {
    padding: `0 ${theme.spacing.small}px`,
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  rowButton: {
    '& > svg': {
      color: theme.colors.application.typography.default,
    },
  },
  headerCenter: {
    textAlign: 'center',
  },
}));

export type SearchResultHolidayPage = GenericSearchResultItem<
  HolidaySearchResult,
  HolidaySearchResultCalculatedData
>;

export type HolidaySearchResult = {
  isHalfDay: boolean;
  date: DateTimeString;
  name: string;
};

export type HolidaySearchResultCalculatedData = {
  internalPublic: 'Public' | 'Internal';
  officeIds: OfficeId[];
  isoCodes: string[];
  officeIdOfficeHolidayIdDtos: Array<{
    officeId: OfficeId;
    officeHolidayId: OfficeHolidayId;
  }>;
};

export interface HRHolidaysPageTableRef {
  fetchHolidays: () => void;
}

interface HRHolidaysPageTableProps {
  className?: string;
  officeId: OfficeId;
  tableId: string;
  setHolidayInModal: (value: SearchResultHolidayPage) => void;
  setDeleteHolidayInModal: (value: SearchResultHolidayPage) => void;
}

export const HRHolidaysPageTable = (props: HRHolidaysPageTableProps) => {
  //#region ------------------------------ Defaults
  const {
    className,
    officeId,
    tableId,
    setHolidayInModal,
    setDeleteHolidayInModal,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getOfficeById } = useOfficesContext();

  const { checkGlobalRoles } = useAccessRights2();

  const { data, isLoading } = useFilterContext<
    HolidaySearchResult,
    HolidaySearchResultCalculatedData
  >();

  const [selectedHolidays, setSelectedHolidays] = useState<
    Array<SearchResultHolidayPage>
  >([]);

  const _data: Array<SearchResultHolidayPage> = useMemo(() => {
    return (data?.items ?? [])?.sort((a, b) =>
      moment(a.data.date).diff(moment(b.data.date))
    );
  }, [data]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onSelectionChange = (items: Array<SearchResultHolidayPage>) => {
    if (setSelectedHolidays) {
      setSelectedHolidays(items);
    }
  };

  const exportHolidayListToCsv = useCallback(() => {
    const holidaysToExport = selectedHolidays
      .sort((a, b) => {
        if (
          a.calculated.internalPublic === 'Internal' &&
          b.calculated.internalPublic === 'Public'
        ) {
          return -1;
        } else if (
          a.calculated.internalPublic === 'Public' &&
          b.calculated.internalPublic === 'Internal'
        ) {
          return 1;
        } else {
          return moment(a.data.date).diff(b.data.date);
        }
      })
      .map((holiday) => {
        return {
          [t('absences:officeHolidays.table.export.dateTitle')]: moment(
            holiday.data.date
          ).format('DD.MM.YYYY'),
          [t('absences:officeHolidays.table.export.nameTitle')]:
            holiday.data.name,
          [t('absences:officeHolidays.table.export.isoCodesTitle')]:
            holiday.calculated.officeIds.join(', '),
          [t('absences:officeHolidays.table.export.holidayTypeTitle')]:
            holiday.calculated.internalPublic === 'Internal'
              ? t('absences:officeHolidays.table.export.holidayTypeOffice')
              : t('absences:officeHolidays.table.export.holidayTypePublic'),
          [t('absences:officeHolidays.table.export.isHalfDayTitle')]:
            holiday.data.isHalfDay === true
              ? t('absences:officeHolidays.table.export.isHalfDayYes')
              : t('absences:officeHolidays.table.export.isHalfDayNo'),
        };
      });

    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(holidaysToExport);

    XLSX.utils.book_append_sheet(
      wb,
      ws,
      t(`${t('absences:officeHolidays.table.export.sheetTitle')}`)
    );
    XLSX.writeFile(
      wb,
      `${t('absences:officeHolidays.table.export.sheetTitle')}.xlsx`
    );
  }, [selectedHolidays, t]);

  const onCopy = useCallback(
    (item: SearchResultHolidayPage) => {
      setHolidayInModal({
        ...item,
        calculated: {
          ...item.calculated,
          officeIdOfficeHolidayIdDtos:
            item.calculated.officeIdOfficeHolidayIdDtos.map(
              (officeHoliday) => ({
                ...officeHoliday,
                officeHolidayId: 'copy-' + createTemporaryId(),
              })
            ),
        },
        isTemporary: true,
      });
    },
    [setHolidayInModal]
  );

  const onEdit = useCallback(
    (item: SearchResultHolidayPage) => {
      setHolidayInModal(item);
    },
    [setHolidayInModal]
  );

  const onDelete = useCallback(
    (item: SearchResultHolidayPage) => {
      setDeleteHolidayInModal(item);
    },
    [setDeleteHolidayInModal]
  );

  const onCopyToNextYear = useCallback(
    async (item: SearchResultHolidayPage) => {
      const hasGlobalRights = checkGlobalRoles([
        'globalAdmin',
        'globalAssistance',
        'globalHR',
      ]);

      const promises = item.calculated.officeIdOfficeHolidayIdDtos.map(
        async ({ officeHolidayId, officeId }) => {
          return await apiCopyOfficeHolidayToNextYear(
            officeHolidayId.toLowerCase(),
            moment(item.data.date).add(1, 'year').format('YYYY'),
            hasGlobalRights ? undefined : officeId
          );
        }
      );
      const responses = await Promise.all(promises);
      if (responses.some(({ result }) => !result.ok)) {
        notification.open({
          message: t('absences:officeHolidays.messages.copyToNextYear.error'),
        });
      }
    },
    [t, checkGlobalRoles]
  );
  //#endregion

  //#region ------------------------------ Components
  const menuProjectList = useCallback(
    (
      entry: SearchResultHolidayPage,
      allow: string[],
      onCopy: (officeHoliday: SearchResultHolidayPage) => void,
      onEdit: (officeHoliday: SearchResultHolidayPage) => void,
      onDelete: (officeHoliday: SearchResultHolidayPage) => void,
      onCopyToNextYear: (officeHoliday: SearchResultHolidayPage) => void
    ) => (
      <Menu>
        {(!allow || allow.includes('copy')) && (
          <Menu.Item
            key={'copyHolidays'}
            onClick={() => onCopy(entry)}
            icon={<FontAwesomeIcon icon={['fal', 'copy']} />}
          >
            {t('absences:officeHolidays.table.dropdown.actions.copy')}
          </Menu.Item>
        )}
        {(!allow || allow.includes('copyToNextYear')) && (
          <Menu.Item
            key={'copyToNextYearHolidays'}
            onClick={() => onCopyToNextYear(entry)}
            icon={<FontAwesomeIcon icon={['fal', 'square-arrow-right']} />}
          >
            {t('absences:officeHolidays.table.dropdown.actions.copyToNextYear')}
          </Menu.Item>
        )}
        {(!allow || allow.includes('edit')) && (
          <Menu.Item
            key={'editHolidays'}
            onClick={() => onEdit(entry)}
            icon={<FontAwesomeIcon icon={['fal', 'edit']} />}
          >
            {t('absences:officeHolidays.table.dropdown.actions.edit')}
          </Menu.Item>
        )}
        {(!allow || allow.includes('delete')) && (
          <Menu.Item
            key={'deleteHoliday'}
            onClick={() => onDelete(entry)}
            icon={<FontAwesomeIcon icon={['fal', 'trash']} />}
          >
            {t('absences:officeHolidays.table.dropdown.actions.delete')}
          </Menu.Item>
        )}
      </Menu>
    ),
    [t]
  );
  //endregion

  //#region ------------------------------ Columns
  const columns: Column<SearchResultHolidayPage>[] = useMemo(
    () => [
      {
        id: 'type',
        alignSelf: true,
        title: () => <div></div>,
        width: 3,
        minWidth: 34,
        accessor: 'calculated.internalPublic',
        sortingFn: (a: SearchResultHolidayPage, b: SearchResultHolidayPage) =>
          a.calculated.internalPublic?.localeCompare(
            b.calculated.internalPublic
          ),
        Cell: (cellProps) => {
          const type = cellProps.originalData.calculated.internalPublic;
          return (
            <div
              className={classes.center}
              title={t(
                `absences:officeHolidays.table.iconDescription.${
                  type === 'Internal' ? 'briefcase' : 'globe'
                }`
              )}
            >
              <FontAwesomeIcon
                icon={['fal', type === 'Internal' ? 'briefcase' : 'globe']}
              />
            </div>
          );
        },
      },
      {
        id: 'date',
        alignSelf: true,
        width: 16,
        title: t('absences:officeHolidays.table.columnTitle.date'),
        accessor: 'data.date',
        Cell: (cellProps) => {
          const date = moment(cellProps.originalData.data.date).format(
            'DD.MM.YYYY'
          );
          return <div className={classes.ellipsis}>{date}</div>;
        },
        sortingFn: (a: SearchResultHolidayPage, b: SearchResultHolidayPage) => {
          const dateCompare = moment(a.data.date).diff(moment(b.data.date));
          return dateCompare > 0 ? 1 : dateCompare < 0 ? -1 : 0;
        },
      },
      {
        id: 'name',
        alignSelf: true,
        width: 37,
        title: t('absences:officeHolidays.table.columnTitle.name'),
        accessor: 'data.name',
        Cell: (cellProps) => (
          <div title={cellProps.value} className={classes.ellipsis}>
            {cellProps.value}
          </div>
        ),
        sortingFn: (a: SearchResultHolidayPage, b: SearchResultHolidayPage) =>
          a.data.name.localeCompare(b.data.name),
      },
      {
        id: 'offices',
        alignSelf: true,
        width: 23,
        title: t('absences:officeHolidays.table.columnTitle.offices'),
        accessor: 'calculated.officeIds',
        Cell: ({ originalData }) => {
          const names = originalData.calculated.officeIds
            .map((id) => {
              const office = getOfficeById(id.toLowerCase());
              return office?.name;
            })
            .filter((name) => !!name)
            .join(', ');
          return (
            <div title={names} className={classes.ellipsis}>
              {names}
            </div>
          );
        },
      },
      {
        id: 'isHalfDay',
        alignSelf: true,
        width: 16,
        title: t('absences:officeHolidays.table.columnTitle.isHalfDay'),
        classNameHeaderCell: classes.headerCenter,
        accessor: 'data.isHalfDay',
        sortingFn: (a: SearchResultHolidayPage, b: SearchResultHolidayPage) =>
          a.data.isHalfDay === b.data.isHalfDay ? 0 : a.data.isHalfDay ? 1 : -1,
        Cell: (cellProps) =>
          cellProps.value && (
            <div className={classes.center}>
              <FontAwesomeIcon icon={['fal', 'check']} />
            </div>
          ),
      },
      {
        id: 'options',
        alignSelf: true,
        width: 4,
        minWidth: 50,
        title: '',
        accessor: 'data.date',
        Cell: ({ originalData }) => {
          const isInternal =
            originalData.calculated.internalPublic === 'Internal';
          const officeHolidayId =
            originalData?.calculated.officeIdOfficeHolidayIdDtos
              .find((o) => o.officeId === officeId)
              ?.officeHolidayId?.toLowerCase();
          return isInternal ? (
            <div className={classes.center}>
              <Dropdown
                overlay={() =>
                  menuProjectList(
                    originalData,
                    ['copy', 'copyToNextYear', 'edit', 'delete'],
                    onCopy,
                    onEdit,
                    onDelete,
                    onCopyToNextYear
                  )
                }
                trigger={['click']}
                placement="bottomRight"
                disabled={!officeHolidayId}
              >
                <Button
                  className={classes.rowButton}
                  type="link"
                  iconProp={['fal', 'ellipsis-v']}
                />
              </Dropdown>
            </div>
          ) : (
            <div className={classes.center}>
              <Dropdown
                overlay={() =>
                  menuProjectList(
                    originalData,
                    ['copy'],
                    onCopy,
                    onEdit,
                    onDelete,
                    onCopyToNextYear
                  )
                }
                trigger={['click']}
                placement="bottomRight"
              >
                <Button
                  className={classes.rowButton}
                  type="link"
                  iconProp={['fal', 'ellipsis-v']}
                />
              </Dropdown>
            </div>
          );
        },
      },
    ],
    [
      classes,
      menuProjectList,
      t,
      officeId,
      onCopy,
      onEdit,
      onDelete,
      onCopyToNextYear,
      getOfficeById,
    ]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <VirtualTable<SearchResultHolidayPage>
      id={tableId}
      className={classNames(classes.root, className)}
      columns={columns}
      data={_data}
      selectedItems={selectedHolidays}
      resizable="relative"
      onSelectionChange={onSelectionChange}
      onCheckEquality={({ data: a }, { data: b }) =>
        moment(a.date).isSame(b.date, 'day') && a.name === b.name
      }
      noItemsScreen={<FilterResultNoItemsScreen />}
      loading={
        isLoading && {
          type: 'noItems',
        }
      }
      rowsAreSelectable
      actionBarActions={[
        {
          children: t('absences:officeHolidays.table.actions.csvExport'),
          iconProp: ['fal', 'file-csv'],
          type: 'primary',
          onClick: exportHolidayListToCsv,
        },
      ]}
    />
  );
};

export default HRHolidaysPageTable;
