import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { AbsenceOverview } from '../../../../models/AbsenceProposal';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { calcTextWidth } from '../../../../util/calcTextWidth';
import ChartIcon from './ChartIcon';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  mainContent: {
    paddingLeft: 32,
  },
}));

interface AbsenceOverviewChartProps {
  className?: string;
  absenceOverview: AbsenceOverview;
}

export const AbsenceOverviewChart: React.FC<AbsenceOverviewChartProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className, absenceOverview } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const {
    remainingDaysPreviousYear,
    vacationDay,
    takenVacationDays,
    requestedVacationDays,
    acceptedVacationDays,
  } = absenceOverview ?? {
    remainingDaysPreviousYear: 0,
    vacationDay: 0,
    takenVacationDays: 0,
    requestedVacationDays: 0,
    acceptedVacationDays: 0,
  };
  const remainingVacationDays =
    vacationDay +
    remainingDaysPreviousYear -
    takenVacationDays -
    acceptedVacationDays -
    requestedVacationDays;

  const ref = useRef<HTMLDivElement>(null);

  const [resizeObserver, setResizeObserver] = useState<ResizeObserver>(null);

  const [showFullText, setShowFullText] = useState<boolean>(true);
  const classes = useStyles({ showFullText });
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (!resizeObserver) {
      const _resizeObserver = new ResizeObserver((entries) => {
        if (entries.length === 1) {
          const visibleWidth = entries[0].contentRect.width;
          setShowFullText(visibleWidth > 648);
        }
      });
      setResizeObserver(_resizeObserver);
    }
  }, [resizeObserver]);

  useEffect(() => {
    if (ref.current && resizeObserver) {
      resizeObserver.observe(ref.current);
    }
  }, [ref, resizeObserver]);

  useEffect(() => {
    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [resizeObserver]);
  //#endregion

  return (
    <Flex.Column
      className={classNames(classes.root, className)}
      childrenGap={theme.old.spacing.unit(2)}
      ref={ref}
    >
      <Flex.Column
        className={classes.mainContent}
        childrenGap={theme.old.spacing.baseSpacing}
      >
        <Flex.Row alignItems="center">
          {remainingDaysPreviousYear > 0 && (
            <ChartItem
              iconName="history"
              days={remainingDaysPreviousYear}
              label={t(
                'absences:absenceOverview.popOver.remainingDaysPreviousYear'
              )}
              color={theme.old.palette.chromaticPalette.blue}
              showFullText={showFullText}
              includeNegativeNumbers
            />
          )}
          <ChartItem
            iconName="island-tropical"
            days={vacationDay}
            label={t('absences:absenceOverview.popOver.vacationDay')}
            color={theme.old.palette.chromaticPalette.darkBlue}
            showFullText={showFullText}
          />
          {remainingDaysPreviousYear < 0 && (
            <ChartItem
              iconName="history"
              days={remainingDaysPreviousYear}
              label={t(
                'absences:absenceOverview.popOver.remainingDaysPreviousYear'
              )}
              color={theme.old.palette.chromaticPalette.red}
              showFullText={showFullText}
              includeNegativeNumbers
            />
          )}
        </Flex.Row>
        <Flex.Row alignItems="center">
          <ChartItem
            iconName="calendar-alt"
            days={takenVacationDays}
            label={t('absences:absenceOverview.popOver.takenVacationDays')}
            color={theme.old.palette.chromaticPalette.grey}
            showFullText={showFullText}
          />
          <ChartItem
            iconName="calendar-check"
            days={acceptedVacationDays}
            label={t('absences:absenceOverview.popOver.acceptedVacationDays')}
            color={theme.old.palette.chromaticPalette.green}
            showFullText={showFullText}
          />
          <ChartItem
            iconName="calendar-plus"
            days={requestedVacationDays}
            label={t('absences:absenceOverview.popOver.requestedVacationDays')}
            color={theme.old.palette.chromaticPalette.yellow}
            showFullText={showFullText}
          />
          <ChartItem
            days={remainingVacationDays}
            iconName="calendar"
            label={t('absences:absenceOverview.popOver.remainingVacationDays')}
            color={theme.old.palette.chromaticPalette.darkBlue}
            showFullText={showFullText}
          />
        </Flex.Row>
      </Flex.Column>
    </Flex.Column>
  );
};

export default AbsenceOverviewChart;

const useCharItemStyles = makePrioStyles((theme) => ({
  root: ({ showFullText, textWidth }) =>
    showFullText
      ? {}
      : {
          '&:hover': {
            minWidth: textWidth + 2 * 41,
          },
        },
  chart: {
    position: 'relative',
    height: 64,
    minWidth: ({ showFullText }) => (showFullText ? 'unset' : 80), //64,
    transition: '0.2s all',
  },
  chartIcon: {
    position: 'absolute',
    height: 64,
    width: 64,
    left: -32,
    top: 0,
    borderRadius: 32,
    border: `2px solid ${theme.old.borders.colors.content}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  chartBar: {
    height: 64,
    borderRadius: 8,
    padding: '0 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.old.typography.colors.inverted,
  },
  description: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: theme.old.typography.colors.invertedMuted,
  },
}));

interface ChartItemProps {
  className?: string;
  iconName: IconName;
  label?: string;
  days?: number;
  color: string;
  includeNegativeNumbers?: boolean;
  showFullText?: boolean;
}

const ChartItem: React.FC<ChartItemProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    iconName,
    label,
    days,
    color,
    includeNegativeNumbers,
    showFullText,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const description = days;

  const textWidth = useMemo(
    () => Math.round(label ? calcTextWidth(label, '15px') : 0),
    [label]
  );
  const classes = useCharItemStyles({ textWidth, showFullText });
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  if (days === 0 || (!includeNegativeNumbers && days < 0)) {
    return null;
  }

  if (!label && !description && !iconName) {
    return (
      <div
        className={classNames(classes.chart, classes.root, className)}
        style={{ flex: days, backgroundColor: color }}
      />
    );
  }

  return (
    <div
      className={classNames(classes.chart, className, {
        [classes.root]: !showFullText,
      })}
      style={{ flex: Math.abs(days) }}
    >
      {iconName && (
        <ChartIcon iconName={iconName} color={color} days={days}></ChartIcon>
      )}
      <div className={classes.chartBar} style={{ backgroundColor: color }}>
        {label && <div className={classes.title}>{label}</div>}
        {description && (
          <div className={classes.description}>{description}</div>
        )}
      </div>
    </div>
  );
};
