import React, { useEffect, useState } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import { ProjectId } from '../../../models/Types';
import Flex from '../../../components/Flex';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Col, Modal, Row, Typography, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import AddProjectExtensionDrawer from './ProjectExtension/AddProjectExtensionDrawer';
import ProjectExtensionDrawer, {
  ProjectExtensionDrawerTabs,
} from './ProjectExtension/ProjectExtensionDrawer';
import { useTranslation } from 'react-i18next';
import {
  apiDeleteProjectExtensionForProject,
  apiFetchInternalProjectContacts,
  apiFetchProjectExtensionsForProject,
} from '../api';
import {
  CreateProjectExtension,
  ProjectExtension,
  ProjectExtensionType,
} from '../../../models/ProjectExtension';
import { InternalProjectContact } from '../../../models/ProjectContacts';
import { Card } from '@prio365/prio365-react-library';
import { rowGutter } from '../../../util/forms';
import { getSvgIconPathByType } from '../../../util/icon';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import SvgIcon from '../../../components/SvgIcon';

const useStyles = makePrioStyles((theme) => ({
  root: {
    overflowY: 'hidden',
  },
  grid: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  colGrid: {
    padding: theme.old.spacing.baseSpacing,
  },
  addCard: {
    height: '100%',
    width: '100%',
    marginRight: theme.old.spacing.baseSpacing,
    marginBottom: -theme.old.spacing.baseSpacing * 2,
    boxShadow: theme.old.palette.boxShadow.regular,
  },
  iconAdd: {
    margin: 'auto',
    height: '100px;',
  },
  wrapContainer: {
    flexWrap: 'wrap',
    display: 'flex',
  },
  wrapContainerWhite: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(2)}px`,
    marginBottom: `${theme.old.spacing.unit(2)}px`,
    '& > div:not(:last-child)': {
      marginBottom: `${theme.old.spacing.unit(2)}px`,
    },
  },
  wrapContainerWhiteFull: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(2)}px`,
    marginBottom: `${theme.old.spacing.unit(2)}px`,
    '& > div:not(:last-child)': {
      marginBottom: `${theme.old.spacing.unit(2)}px`,
    },
    height: '100%',
  },
  secondary: {
    color: theme.old.typography.colors.muted,
  },
  contentContainerFloat: {
    gap: `${theme.old.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    '& .ant-form-item': {
      width: '100%',
    },
    '& .ant-row': {
      width: '100%',
    },
  },
  extensionItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100px',
    height: '100px',
  },
  extensionItemText: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#42526E',
    marginBottom: '0px',
    marginTop: '8px',
  },
  extensionContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'flex-start',
  },
}));

interface AddInsPageProps {
  projectId: ProjectId;
  handleCancelNoExtensionsModal?: () => void;
  noExtensionsModalVisible?: boolean;
  setNoExtensionsModalVisible?: (visible: boolean) => void;
  withNoExtensionsModal?: boolean;
}

export const AddInsPage: React.FC<AddInsPageProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();

  const { t } = useTranslation();
  const {
    projectId,
    handleCancelNoExtensionsModal,
    noExtensionsModalVisible,
    setNoExtensionsModalVisible,
    withNoExtensionsModal,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [
    addProjectExtensionDrawerVisible,
    setAddProjectExtensionDrawerVisible,
  ] = useState<boolean>(false);
  const [projectExtensionDrawerVisible, setProjectExtensionDrawerVisible] =
    useState<boolean>(false);
  const [loadingExtensions, setLoadingExtensions] = useState<boolean>(false);
  const [projectExtensionDrawerTab, setProjectExtensionDrawerTab] =
    useState<ProjectExtensionDrawerTabs>('settings');

  const [projectExtensions, setProjectExtensions] = useState<
    ProjectExtension[]
  >([]);

  const [projectExtensionsToAdd, setProjectExtensionsToAdd] = useState<
    CreateProjectExtension[]
  >([]);
  const [internalProjectContacts, setInternalProjectContacts] = useState<
    InternalProjectContact[]
  >([]);

  const [selectedProjectExtensions, setSelectedProjectExtensions] =
    useState<ProjectExtension>(null);

  const [disabled, setDisabled] = useState<boolean>(false);
  const [deleteProjectExtension, setDeleteProjectExtension] =
    useState<ProjectExtension>(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const removeProjectExtension = async () => {
    var extensionId = deleteProjectExtension.projectExtensionId;
    var { result } = await apiDeleteProjectExtensionForProject(
      projectId,
      extensionId
    );
    if (result.status > 299 || result.status < 200) {
      notification.open({
        message: t('common:error'),
      });
    }
    setDeleteModalVisible(false);
    setProjectExtensionDrawerVisible(false);
    setDeleteProjectExtension(null);
    // delete extension id from projectExtensions
    const newProjectExtensions = projectExtensions.filter(
      (projectExtension) => projectExtension.projectExtensionId !== extensionId
    );
    setProjectExtensions(newProjectExtensions);
  };

  const projectExtensionCreated = (projectExtension: ProjectExtension) => {
    setProjectExtensions([...projectExtensions, projectExtension]);
    setSelectedProjectExtensions(projectExtension);

    const removeAdded = projectExtensionsToAdd.filter(
      (ext) =>
        projectExtension.projectExtensionType !== ext.projectExtensionType
    );
    setProjectExtensionsToAdd(removeAdded);
  };

  const reloadExtensions = async () => {
    const { data } = await apiFetchProjectExtensionsForProject(projectId);
    if (data) {
      setProjectExtensions(data);
      setSelectedProjectExtensions((prevSelectedExtensions) => {
        const newSelectedExtension = data.find(
          ({ projectExtensionId }) =>
            projectExtensionId === prevSelectedExtensions?.projectExtensionId
        );
        return newSelectedExtension || prevSelectedExtensions;
      });
    }
  };

  const checkIfProjectExtensionTypesExist = (
    projectExtensionTypes: string[]
  ): boolean => {
    return projectExtensions.some((extension) =>
      projectExtensionTypes.includes(extension.projectExtensionType)
    );
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (projectId) {
      setDisabled(true);
      const getExtensions = async () => {
        setLoadingExtensions(true);
        const { data } = await apiFetchProjectExtensionsForProject(projectId);
        if (data) {
          setProjectExtensions(data);
        }

        setLoadingExtensions(false);
      };
      getExtensions();
      setDisabled(false);
    }
  }, [disabled, projectId, setLoadingExtensions]);

  useEffect(() => {
    if (projectId) {
      const getInternalContacts = async (projectId) => {
        const { data } = await apiFetchInternalProjectContacts(projectId);
        if (data) {
          setInternalProjectContacts(data);
        }
      };
      getInternalContacts(projectId);
    }
  }, [projectId]);
  //#endregion

  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapContainerWhite}>
          <div className={classes.contentContainerFloat}>
            <Button
              onClick={() => {
                setAddProjectExtensionDrawerVisible(true);
              }}
              iconProp={['fal', 'puzzle-piece']}
            >
              {t(`projects:addIn.add`)}
            </Button>
          </div>
        </div>
        <div className={classes.wrapContainerWhiteFull}>
          {(loadingExtensions ||
            projectExtensions === null ||
            projectExtensions?.length === 0) && (
            <Card
              secondaryCard
              contentNode={
                <Row justify="center">
                  {loadingExtensions && <PrioSpinner />}
                  {(projectExtensions === null ||
                    projectExtensions?.length === 0) && (
                    <p>{t(`projects:addIn.empty`)}</p>
                  )}
                </Row>
              }
            />
          )}
          {projectExtensions
            .sort((a, b) =>
              a.projectExtensionType.localeCompare(b.projectExtensionType)
            )
            .map((projectExtension) => {
              return (
                <Card
                  key={projectExtension.projectExtensionId}
                  secondaryCard
                  contentNode={
                    <Row
                      gutter={theme.old.spacing.unit(rowGutter)}
                      justify="space-between"
                      align="middle"
                    >
                      <Col span={1}>
                        <SvgIcon
                          width={40}
                          height={40}
                          path={getSvgIconPathByType(
                            projectExtension.projectExtensionType
                          )}
                        ></SvgIcon>
                      </Col>
                      <Col span={13}>{projectExtension?.displayName}</Col>
                      <Col span={10}>
                        <Row align="middle" justify="end">
                          <span className={classes.secondary}>
                            (
                            {internalProjectContacts?.length
                              ? `${projectExtension?.accesses?.filter(
                                  (access) => access.shouldHaveAccess
                                ).length}/${internalProjectContacts?.length}`
                              : '0'}
                            )
                          </span>{' '}
                          <Button
                            type="link"
                            onClick={() => {
                              setSelectedProjectExtensions(projectExtension);
                              setProjectExtensionDrawerTab('users');
                              setProjectExtensionDrawerVisible(true);
                            }}
                            iconProp={['fal', 'users']}
                          ></Button>
                          <Button
                            type="link"
                            onClick={() => {
                              setSelectedProjectExtensions(projectExtension);
                              setProjectExtensionDrawerTab('settings');
                              setProjectExtensionDrawerVisible(true);
                            }}
                            iconProp={['fal', 'cog']}
                          ></Button>
                        </Row>
                      </Col>
                    </Row>
                  }
                ></Card>
              );
            })}
        </div>

        {!checkIfProjectExtensionTypesExist(['sharedMailbox', 'planner']) && (
          <>
            <h3>{t(`projects:addIn.recommanded.title`)}</h3>

            <div
              className={classNames(
                classes.extensionContainer,
                classes.wrapContainerWhite
              )}
            >
              {!checkIfProjectExtensionTypesExist(['sharedMailbox']) && (
                <ExtensionItem
                  projectExtensionType={'sharedMailbox'}
                  setAddProjectExtensionDrawerVisible={
                    setAddProjectExtensionDrawerVisible
                  }
                />
              )}
              {!checkIfProjectExtensionTypesExist(['sharepoint']) && (
                <ExtensionItem
                  projectExtensionType={'sharepoint'}
                  setAddProjectExtensionDrawerVisible={
                    setAddProjectExtensionDrawerVisible
                  }
                />
              )}
            </div>
          </>
        )}
      </div>
      <AddProjectExtensionDrawer
        addProjectExtensionDrawerVisible={addProjectExtensionDrawerVisible}
        projectId={projectId}
        setAddProjectExtensionDrawerVisible={
          setAddProjectExtensionDrawerVisible
        }
        reloadProjectExtension={reloadExtensions}
      />
      <ProjectExtensionDrawer
        projectExtensionDrawerVisible={projectExtensionDrawerVisible}
        setProjectExtensionDrawerVisible={setProjectExtensionDrawerVisible}
        projectExtension={selectedProjectExtensions}
        addProjectExtension={undefined}
        projectExtensionDrawerTab={projectExtensionDrawerTab}
        setProjectExtensionDrawerTab={setProjectExtensionDrawerTab}
        projectId={projectId}
        projectExtensionCreated={(newProjectExtension) => {
          projectExtensionCreated(newProjectExtension);
        }}
        projectExtensionUpdated={(updatedProjectExtension) => {
          setSelectedProjectExtensions(updatedProjectExtension);
        }}
        removeExtension={(projectExtension) => {
          setDeleteModalVisible(true);
          setDeleteProjectExtension(projectExtension);
        }}
        reloadProjectExtension={reloadExtensions}
      />
      <Modal
        title={t('projects:addIn.delete.title')}
        okText={t('projects:addIn.delete.okText')}
        cancelText={t('projects:addIn.delete.cancelText')}
        onOk={removeProjectExtension}
        onCancel={() => {
          setDeleteModalVisible(false);
        }}
        visible={deleteModalVisible}
      >
        <Flex.Column>
          <Typography.Text>
            {t('projects:addIn.delete.confirm')}
          </Typography.Text>
          <ul>
            <li>{deleteProjectExtension?.displayName}</li>
          </ul>
        </Flex.Column>
      </Modal>
      {projectExtensions.length === 0 && withNoExtensionsModal === true && (
        <Modal
          title={t('projects:addIn.noExtensions.title')}
          okText={t('projects:addIn.noExtensions.okText')}
          cancelText={t('projects:addIn.noExtensions.cancelText')}
          onOk={() => {
            setNoExtensionsModalVisible(false);
            setAddProjectExtensionDrawerVisible(true);
          }}
          visible={noExtensionsModalVisible}
          onCancel={handleCancelNoExtensionsModal}
        >
          <Flex.Column>
            <Typography.Text>
              {t('projects:addIn.noExtensions.confirm')}
            </Typography.Text>
          </Flex.Column>
        </Modal>
      )}
    </>
  );
};

export default AddInsPage;

//#region ------------------------------ ExtensionItem

interface ExtensionItemProps {
  projectExtensionType?: ProjectExtensionType;
  setAddProjectExtensionDrawerVisible?: (visible: boolean) => void;
}
const ExtensionItem: React.FC<ExtensionItemProps> = (props) => {
  const { projectExtensionType, setAddProjectExtensionDrawerVisible } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Card
      secondaryCard
      contentNode={
        <Row justify="start">
          <div className={classes.extensionItem}>
            <SvgIcon
              width={64}
              height={64}
              path={getSvgIconPathByType(projectExtensionType, true)}
            ></SvgIcon>
            <p className={classes.extensionItemText}>
              {t(`projects:addIn.${projectExtensionType}`)}
            </p>
            <Button
              size="small"
              suffixIconProp={['fal', 'arrow-right']}
              type="link"
              onClick={() => {
                setAddProjectExtensionDrawerVisible(true);
              }}
            >
              {t(`projects:addIn.access.add`)}
            </Button>
          </div>
        </Row>
      }
    ></Card>
  );
};
//#endregion
