import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { Form, Layout } from 'antd';
import { Button, Input, useTheme } from '@prio365/prio365-react-library';
import { colon } from '../../../util/forms';
import { PrioTheme } from '../../../theme/types';
import { Content } from 'antd/lib/layout/layout';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { getSvgIconPathByType } from '../../../util/icon';
import SvgIcon from '../../../components/SvgIcon';
import {
  CreateProjectExtensionUserConfiguration,
  JiraUserConfigurationData,
  defaultJiraUserConfigurationData,
} from '../../../models/ProjectExtension';
import { apiCreateProjectExtensionUserConfiguration } from '../../projects/api';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  rootLayout: {
    margin: 0,
  },
  editContent: {
    backgroundColor: theme.colors.application.background.default,
  },
  form: {
    padding: `${theme.spacing.regular}px ${theme.spacing.regular * 2}px`,
    height: '100%',
    overflow: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
  header: {
    marginLeft: '30px',
    marginBlock: theme.spacing.regular,
  },
  submitButton: {
    position: 'absolute',
    bottom: '32px',
  },
}));

interface JiramConfigProps {
  className?: string;
  projectExtensionUserConfigurationChanged?: () => void;
  contactId: string;
}

export const JiraUserConfigurationCreate: React.FC<JiramConfigProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { contactId, projectExtensionUserConfigurationChanged } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const classes = useStyles();
  const { projectExtensionUserConfigurationId } = useParams();
  const [dataForm] = Form.useForm<JiraUserConfigurationData>();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  // issaving
  const [isSaving, setIsSaving] = useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Methods / Handlers

  // handle save
  const handleFinishData = async (newData: JiraUserConfigurationData) => {
    const payload: CreateProjectExtensionUserConfiguration = {
      contactId: contactId,
      objectJson: JSON.stringify(newData.objectJson),
      extensionType: 'jira',
    };

    //save phase
    setIsSaving(true);
    await apiCreateProjectExtensionUserConfiguration(payload);
    projectExtensionUserConfigurationChanged();
    setIsSaving(false);
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dataForm.setFieldsValue(defaultJiraUserConfigurationData);
  }, [projectExtensionUserConfigurationId, dataForm]);
  //#endregion

  return (
    <Layout className={classes.rootLayout}>
      <Content className={classes.editContent}>
        <>
          <Flex.Row className={classes.header} flex={1}>
            <SvgIcon
              width={35}
              height={35}
              path={getSvgIconPathByType('jira')}
            ></SvgIcon>
            <h3 style={{ marginTop: '3px' }}>Jira</h3>
          </Flex.Row>
          <Form
            layout="vertical"
            form={dataForm}
            className={classes.form}
            onFinish={handleFinishData}
          >
            <Flex.Row flex={1}>
              <Flex.Column flex={2} childrenGap={theme.spacing.small}>
                <Flex.Row flex={1} alignItems="center">
                  <h4>{t('projects:addIn.jiraConfig.connection')}</h4>
                </Flex.Row>
                <Form.Item
                  name={['objectJson', 'displayName']}
                  label={t('projects:addIn.jiraConfig.displayName')}
                  colon={colon}
                >
                  <Input disabled={false} full />
                </Form.Item>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  flex={1}
                  justifyContent="stretch"
                >
                  <Form.Item
                    name={['objectJson', 'userCredentials', 'mail']}
                    label="Mail"
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                  <Form.Item
                    name={['objectJson', 'userCredentials', 'apiKey']}
                    label="API Token"
                    colon={colon}
                    style={{ flex: 1 }}
                  >
                    <Input disabled={false} full />
                  </Form.Item>
                </Flex.Row>
                <Flex.Row
                  childrenGap={theme.spacing.small}
                  flex={1}
                  justifyContent="flex-end"
                >
                  <Button
                    disabled={isSaving}
                    onClick={() => {
                      dataForm.submit();
                    }}
                    htmlType="button"
                    className={classes.submitButton}
                  >
                    {t('projects:addIn.jiraConfig.save')}
                  </Button>
                </Flex.Row>
              </Flex.Column>
            </Flex.Row>
          </Form>
        </>
      </Content>
    </Layout>
  );
};

export default JiraUserConfigurationCreate;
