import {
  DateString,
  EmployeeId,
  Gender,
  DaysOfWork,
  DurationType,
  OfficeId,
  ContactId,
} from './Types';

export declare type HolidayEntitlementId = string;

export interface Employee {
  employeeId?: EmployeeId;
  employeeHolidayEntitlements?: HolidayEntitlement[];
  /** Phone, email, address, ... is all personal (private) data of employee */
  phone?: string;
  eMail?: string;
  street?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
  federalStateCode?: string;
  gender?: Gender;
  nationality?: string;
  birthday?: DateString; //absolut
  allowShareBirthday?: boolean;
  allowShareBirthdayCompany?: boolean;
  birthplace?: string;
  dateOfEmployment?: DateString; //absolut
  probationDurationWeek?: number; //int
  endOfContract?: DateString; //absolut
  cancelationPeriodDuration?: number; //int
  cancelationPeriodType?: DurationType; //int
  formOfEmployment?: FormOfEmployment;
  hoursPerWeek?: number; //int
  workingDayMonday?: boolean;
  workingDayTuesday?: boolean;
  workingDayWednesday?: boolean;
  workingDayThursday?: boolean;
  workingDayFriday?: boolean;
  workingDaySaturday?: boolean;
  workingDaySunday?: boolean;
  isSecondaryEmployment?: boolean;
  civilStatus?: CivilStatus;
  children?: number; //int
  healthInsurance?: string;
  nationalInsuranceNumber?: string;
  severeDisability?: SevereDisability;
  SevereDisabilityMarks?: string;
  confession?: Confession;
  hasCompanyCar?: boolean;
  iban?: string;
  bic?: string;
  creditInstitutionName?: string;
  taxCategory?: number; //int
  taxId?: string;
  taxOfficeId?: string;
  childAllowanceCount?: number; //int
  createdAt?: DateString;
  rowVersion?: string;
  nonTransferableOvertimeHours?: number;
  nonTransferableOvertimeHoursPercent?: number;
  timeKeepingEnableDate?: DateString;
}

export interface EmployeeMe {
  employeeId?: EmployeeId;
  /** Phone, email, address, ... is all personal (private) data of employee */
  phone?: string;
  eMail?: string;
  street?: string;
  zip?: string;
  city?: string;
  countryCode?: string;
  gender?: Gender;
  nationality?: string;
  birthday?: DateString; //absolut
  allowShareBirthday?: boolean;
  allowShareBirthdayCompany?: boolean;
  birthplace?: string;
  iban?: string;
  bic?: string;
  rowVersion?: string;
}

export interface HolidayEntitlement {
  employeeHolidayEntitlementId?: HolidayEntitlementId;
  employeeId?: EmployeeId;
  year?: number; //int
  holidayEntitlement?: number; // double
  description?: string;
  isArchived?: boolean;
  isValid?: boolean;
  rowVersion?: string;
  remainingEntitlement?: number;
}

export declare type FormOfEmployment =
  | 'fulltime'
  | 'halftime'
  | 'minijob'
  | 'apprenticeship'
  | 'internship'
  | 'studentEmployee';

export const allFormOfEmploymentTypes: FormOfEmployment[] = [
  'fulltime',
  'halftime',
  'minijob',
  'apprenticeship',
  'internship',
  'studentEmployee',
];

export declare type CivilStatus =
  | 'notSpecified'
  | 'married'
  | 'divorced'
  | 'widowed'
  | 'single';
export declare type SevereDisability =
  | 'notSpecified'
  | 'none'
  | 'GdB20'
  | 'GdB30'
  | 'GdB40'
  | 'GdB50'
  | 'GdB60'
  | 'GdB70'
  | 'GdB80'
  | 'GdB90'
  | 'GdB100';

export declare type Confession = 'oa' | 'ak' | 'ev' | 'lt' | 'rf' | 'isby';

export interface CreateRegistrationEmployee {
  gender: Gender;
  birthday: DateString; //absolut
  allowShareBirthday: boolean;
  allowShareBirthdayCompany: boolean;
  dateOfEmployment?: DateString; //absolut
  hoursPerWeek: number;
  workingDays: number;
  daysOfWork: DaysOfWork[];
}

export interface AddEmployee {
  gender?: Gender;
  firstName?: string;
  lastName?: string;
  title?: string;
  phone?: string;
  cellphone?: string;
  eMail?: string;
  eMailPrefix?: string;
  eMailSuffix?: string;
  officeId?: OfficeId;
  managerId?: ContactId;
  holidayEntitlement?: number;
  dateOfEmployment?: DateString; //absolut
  formOfEmployment?: FormOfEmployment;
  hoursPerWeek?: number; //int
  workingDayMonday?: boolean;
  workingDayTuesday?: boolean;
  workingDayWednesday?: boolean;
  workingDayThursday?: boolean;
  workingDayFriday?: boolean;
  workingDaySaturday?: boolean;
  workingDaySunday?: boolean;
  templateContactId: string;
}

export interface Birthday{
  employeeId?: EmployeeId;
  birthday?: DateString;
}