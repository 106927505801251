import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'react-jss';

import {
  CreateProjectDraftDto,
  emptyCreateProjectDraftDto,
  emptyProject,
  Project,
} from '../../../../models/Project';
import { PrioTheme } from '../../../../theme/types';
import { apiFetchConfigurations } from '../../../settings/api';
import { ConfigurationKeys } from '../../../../models/Types';
import { colon } from '../../../../util/forms';
import { defaultProjectNrPlaceholder } from '../../../../util';
import CompanyPicker from '../../../companies/components/CompanyPicker';
import ProjectPicker from '../ProjectPicker';
import { useNavigate } from 'react-router-dom';

import OfficePicker from '../../../companies/components/OfficePicker';
import {
  apiCreateProjectDraft,
  apiGetProjectById,
  apiUpdateProjectDraft,
} from '../../api';
import { useProjectDraftValidate } from '../../hooks/useProjectDraftValidate';
import { Button, Card } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.old.spacing.baseSpacing * 3,
  },
  submitButtonFormItem: {
    textAlign: 'right',
  },
  background: {
    boxShadow: theme.old.palette.boxShadow.regular,
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
  },
  projectCreationInProgress: {
    width: '100%',
    height: '100%',
    display: 'absolute',
    zIndex: 1000,
    backgroundColor: theme.old.palette.chromaticPalette.white,
    opacity: 0.9,
  },
  steps: {
    width: '100%',
    marginBottom: `${theme.old.spacing.baseSpacing * 4}px`,
  },
  wrapContainerWhite: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(2)}px`,
    marginBottom: `${theme.old.spacing.unit(2)}px`,
    overflow: 'hidden',
    minHeight: `${theme.old.spacing.unit(8)}px`,
  },
  wrapContainerWhiteMain: {
    backgroundColor: theme.old.palette.backgroundPalette.content,
    padding: `${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(2)}px`,
    overflow: 'hidden',
    height: '100%',
  },
  contentContainerFloat: {
    gap: `${theme.old.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden',
    '& .ant-form-item': {
      width: '100%',
    },
    '& .ant-row': {
      width: '100%',
    },
  },
  contentContainer: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    gap: `${theme.old.spacing.unit(2)}px`,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  spacing: {
    marginLeft: theme.old.spacing.unit(2),
  },
  floatRight: {
    float: 'right',
  },
}));

interface CreateProjectDataFormProps {
  className?: string;
  editMode?: boolean;
  disableActionButton?: boolean;
  disableForm?: boolean;
  projectId?: string;
  nextStep?: (projectId: string, step: string) => void;
}

export const CreateProjectDataForm: React.FC<CreateProjectDataFormProps> = (
  props
) => {
  const classes = useStyles();
  // eslint-disable-next-line
  const theme = useTheme<PrioTheme>();
  // eslint-disable-next-line
  const { className, editMode, disableForm, nextStep, projectId } = props;
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [projectNrPlaceholder, setProjectNumberPlaceholder] =
    useState<string>(null);
  const [project, setProject] = useState<Project>(null);
  const [disabled, setDisabled] = useState<boolean>(disableForm);
  const [dataDirty, setDataDirty] = useState<boolean>(true);

  var {
    validationResult,
    validateProjectCreationData,
    projectToCreateIsValid,
  } = useProjectDraftValidate(form);

  useEffect(() => {
    if (!disableForm) {
      const loadConfigs = async () => {
        const { data } = await apiFetchConfigurations();
        if (data) {
          const config = data.find(
            (configuration) =>
              configuration.key === ConfigurationKeys.PLACEHOLDER_PROJECTNR
          );
          setProjectNumberPlaceholder(config?.value ?? null);
        }
      };
      loadConfigs();
    }
  }, [disableForm]);

  useEffect(() => {
    if (validationResult) {
      if (
        !validationResult?.projectName?.isValid &&
        form.isFieldTouched('name') &&
        project.name !== form.getFieldValue('name')
      ) {
        form.setFields([
          {
            name: 'name',
            errors: [validationResult?.projectName?.description],
          },
        ]);
      } else {
        form.setFields([
          {
            name: 'name',
            errors: [],
          },
        ]);
      }
      if (
        !validationResult?.projectNumber?.isValid &&
        form.isFieldTouched('number') &&
        project.number !== form.getFieldValue('number')
      ) {
        form.setFields([
          {
            name: 'number',
            errors: [validationResult?.projectNumber?.description],
          },
        ]);
      } else {
        form.setFields([
          {
            name: 'number',
            errors: [],
          },
        ]);
      }
    }
    setDataDirty(false);
  }, [validationResult, form, t, project, projectToCreateIsValid]);

  useEffect(() => {
    if (projectId) {
      setDisabled(true);
      const loadProject = async () => {
        const { data } = await apiGetProjectById(projectId);
        if (
          (data && data?.projectStatus && data?.projectStatus === 'draft') ||
          data.projectStatus === 10
        ) {
          setProject(data);
          form.resetFields();
        } else {
          navigate(`../`);
        }
        setDisabled(false);
      };
      loadProject();
    } else {
      setProject(emptyProject);
      form.resetFields();
    }
  }, [projectId, form, navigate]);

  const handleFinish = async (
    values: CreateProjectDraftDto,
    forceUpdate: boolean = true
  ) => {
    if (projectId) {
      if (dataDirty) {
        const { data } = await apiUpdateProjectDraft(values, projectId);
        if (data) {
          nextStep(data.projectId, `settings`);
        }
      } else {
        if (forceUpdate) {
          await apiUpdateProjectDraft(values, projectId);
        }
        nextStep(projectId, `settings`);
      }
    } else {
      const { data } = await apiCreateProjectDraft(values);
      if (data) {
        nextStep(data.projectId, `settings`);
      }
    }
  };

  const saveAndContinue = async () => {
    const values = await form.validateFields();
    handleFinish(values, true);
  };

  return (
    <>
      <div className={classes.contentContainer}>
        <div className={classes.wrapContainerWhiteMain}>
          <Card
            secondaryCard={true}
            content={t('projects:form.labels.general')}
          ></Card>
          <p></p>
          <Form
            form={form}
            initialValues={project ?? emptyCreateProjectDraftDto}
            onFinish={handleFinish}
            layout="vertical"
          >
            <div className={classes.contentContainerFloat}>
              <Form.Item
                name="number"
                label={t('projects:form.labels.number')}
                labelAlign="left"
                validateTrigger="onBlur"
                colon={colon}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      await validateProjectCreationData();
                    },
                  }),
                  {
                    required: true,
                    message: t('projects:form.validation.missingNumber'),
                  },
                ]}
              >
                <Input
                  placeholder={
                    projectNrPlaceholder ?? defaultProjectNrPlaceholder
                  }
                  disabled={editMode || disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
              <Form.Item
                name="shortName"
                label={t('projects:form.labels.shortName')}
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t('projects:form.validation.missingShortName'),
                  },
                ]}
              >
                <Input
                  disabled={disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
            </div>
            <div className={classes.contentContainerFloat}>
              <Form.Item
                name="name"
                label={t('projects:form.labels.name')}
                validateTrigger="onBlur"
                colon={colon}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      await validateProjectCreationData();
                    },
                  }),
                  {
                    required: true,
                    message: t('projects:form.validation.missingName'),
                  },
                ]}
              >
                <Input
                  disabled={disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
              <Form.Item
                name="parentProject"
                label={t('projects:form.labels.parentProject')}
              >
                <ProjectPicker
                  disabled={disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
            </div>
            <div className={classes.contentContainerFloat}>
              <Form.Item
                name="internalOfficeId"
                label={t('projects:form.labels.internalOffice')}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      await validateProjectCreationData();
                    },
                  }),
                  {
                    required: true,
                    message: t('projects:form.validation.missingSubsidiary'),
                  },
                ]}
              >
                <OfficePicker
                  onChange={() => setDataDirty(true)}
                  disabled={disableForm || disabled}
                />
              </Form.Item>
              <Form.Item
                name="companyId"
                label={t('projects:form.labels.companyId')}
                rules={[
                  () => ({
                    async validator(rule, value) {
                      await validateProjectCreationData();
                    },
                  }),
                  {
                    required: true,
                    message: t('projects:form.validation.missingCompanyId'),
                  },
                ]}
              >
                <CompanyPicker
                  disabled={disableForm || disabled}
                  onChange={() => setDataDirty(true)}
                />
              </Form.Item>
            </div>
          </Form>
        </div>
        <div className={classes.wrapContainerWhite}>
          <Button
            className={classes.floatRight}
            disabled={
              projectId !== undefined && !disableForm && !disabled
                ? false
                : disableForm ||
                  disabled ||
                  dataDirty ||
                  !projectToCreateIsValid
            }
            onClick={() => {
              saveAndContinue();
            }}
          >
            {t('projects:createProject.buttons.save')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default CreateProjectDataForm;
