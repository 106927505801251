export declare type DateTimeString = string;
export declare type EmailString = string;
export declare type DateString = string;

export declare type ProjectId = string;
export declare type ProjectExtensionId = string;
export declare type ProjectExtensionAccessId = string;
export declare type ProjectExtensionOptionId = string;
export declare type ProjectExtensionAccessOptionId = string;
export declare type ProjectPhaseId = string;
export declare type GroupId = string;
export declare type DriveItemId = string;
export declare type DriveFavoriteId = string;
export declare type SubProjectId = string;
export declare type ContactId = string;
export declare type EmployeeId = ContactId;
export declare type CompanyId = string;
export declare type OfficeId = string;
export declare type ConfigurationId = string;
export declare type HourlyRateId = string;
export declare type HourlyRateSuggestionId = string;
export declare type TitleSuggestionId = string;
export declare type InternalJobTitleSuggestionId = string;
export declare type ExternalJobTitleSuggestionId = string;
export declare type KilometerRateId = string;
export declare type KilometerRateSuggestionId = string;
export declare type InternalProjectContactId = string;
export declare type ExternalProjectContactId = string;
export declare type ProjectAssignmentId = number;
export declare type TimeRecordId = string;
export declare type AbsenceProposalId = string;
export declare type InvoiceId = string;
export declare type ProjectFileSystemStructureId = string;
export declare type BankAccountId = string;
export declare type DocumentTemplateId = string;
export declare type DocumentTemplateCategoryId = string;
export declare type DocumentTemplateTagString = string;
export declare type EMailSuffix = string;
export declare type MessageId = string;
export declare type MailFolderId = string;
export declare type TrainingId = string;
export declare type QualificationId = string;
export declare type AudienceId = string;
export declare type DepartmentId = string;
export declare type InvoicePaymentId = string;
export declare type InvoicePositionId = string;
export declare type TrainingRequestId = string;
export declare type TrainingAudienceId = string;
export declare type CalendarEventId = string;
export declare type ICalUId = string;
export declare type TransactioId = string;
export declare type ResourceId = string;
export declare type TaskId = string;
export declare type BucketId = string;
export declare type PlanId = string;
export declare type TextBlockId = string;
export declare type NotificationId = string;
export declare type ProjectDistributionListId = string;
export declare type PlannerId = string;
export declare type ZendeskId = string;
export declare type GlobalProjectSettingId = string;
export declare type ProjectSettingId = string;
export declare type DriveUserRemoteItemId = string;
export declare type RemoteDriveItemId = string;
export declare type DashboardId = string;
export declare type DashboardItemId = string;
export declare type OfficeHolidayId = string;
export declare type OfficeHolidayFeedbackStatus = 'success' | 'error' | 'new';
export declare type TimeKeepingDayId = string;
export declare type MonthlyCloseId = string;
export declare type TimeKeepingEntryId = string;
export declare type CompensationPaymentId = string;
export declare type DriveItemListItemId = string;
export declare type MedicalCertificateId = string;

export declare type RecursivePartial<T> = T extends object
  ? {
      [P in keyof T]?: T[P] extends (infer U)[]
        ? RecursivePartial<U>[]
        : T[P] extends object
        ? RecursivePartial<T[P]>
        : T[P];
    }
  : any;

export declare type EditTimeRecordContextType =
  | 'me'
  | 'office'
  | 'global'
  | 'project';

export declare type TimeRecordsModuleContextType =
  | 'me'
  | 'office'
  | 'global'
  | 'project';

export declare type FetchProjectContextType =
  | 'me'
  | 'office'
  | 'global'
  | 'project';
export declare type ProgressStatus =
  | 'success'
  | 'normal'
  | 'active'
  | 'exception';

export declare type NotifcationProgressStatus =
  | 'waiting'
  | 'inProgress'
  | 'done'
  | 'error';

export declare type GlobalProjectSettingType =
  | 'emailSubject'
  | 'emailSignature'
  | 'projectNumberRegex'
  | 'projectDocumentPrefix';

export declare type FolderPermissionValue = 'none' | 'read' | 'write';

export declare type ZendeskType =
  | 'question'
  | 'enhancement'
  | 'featurerequest'
  | 'incident';

export declare type MessageViewLayout = 'bottom' | 'right' | 'hidden';
export declare type MailSelectionListSpacing = 'full' | 'middle' | 'tight';

export declare type DurationType = 'month' | 'week' | 'day';

export declare type DaysOfWork =
  | 'sun'
  | 'mon'
  | 'tue'
  | 'wed'
  | 'thu'
  | 'fri'
  | 'sat';

export declare type MeetingMessageType =
  | 'None'
  | 'MeetingRequest'
  | 'MeetingCancelled'
  | 'Meeting Accepted'
  | 'MeetingTenativelyAccepted'
  | 'MeetingDeclined';

export declare type MailCategoryColorName =
  | 'Keine'
  | 'Preset0'
  | 'Preset1'
  | 'Preset2'
  | 'Preset3'
  | 'Preset4'
  | 'Preset5'
  | 'Preset6'
  | 'Preset7'
  | 'Preset8'
  | 'Preset9'
  | 'Preset10'
  | 'Preset11'
  | 'Preset12'
  | 'Preset13'
  | 'Preset14'
  | 'Preset15'
  | 'Preset16'
  | 'Preset17'
  | 'Preset18'
  | 'Preset19'
  | 'Preset20'
  | 'Preset21'
  | 'Preset22'
  | 'Preset23'
  | 'Preset24';

export declare type TemplateTag = string;

export declare type InvoiceStatus = 'pending' | 'paid' | 'partlyPaid';
export declare type InvoiceType = 'incomingInvoice' | 'outgoingInvoice';

export declare type InvoicePaymentType =
  | 'payment'
  | 'credit'
  | 'discount'
  | 'annulation';
export const allInvoicePaymentTypes: InvoicePaymentType[] = [
  'payment',
  'credit',
  'discount',
  'annulation',
];
export declare type Gender = 'male' | 'female' | 'diverse';
export declare type ContactType = 'InternalContact' | 'ExternalContact';
export declare type CompanyType =
  | 'InternalCompany'
  | 'ExternalCompany'
  | 'ContactCompany';
export declare type AbsenceType =
  | 'annualLeave'
  | 'paidSpecialLeave'
  | 'unpaidLeave'
  | 'dayOfIllness'
  | 'parentalLeave'
  | 'maternityLeave'
  | 'other'
  | 'annualLeavePlanning'
  | 'overtimeCompensation'
  | 'school'
  | 'training';
export declare type MonthlyCloseAbsenceType =
  | 'annualLeave'
  | 'paidSpecialLeave'
  | 'unpaidLeave'
  | 'dayOfIllness'
  | 'parentalLeave'
  | 'maternityLeave'
  | 'other'
  | 'annualLeavePlanning'
  | 'overtimeCompensation'
  | 'training'
  | 'school'
  | 'publicHoliday';
export declare type AbsenceState =
  | 'planned'
  | 'requested'
  | 'accepted'
  | 'declined'
  | 'revokeRequested'
  | 'revokeAccepted'
  | 'revokeDeclined';

export declare type OfficeRole =
  | 'officeAdmin'
  | 'officeHR'
  | 'officeAssistance'
  | 'officeController';

export declare type ProjectRole =
  | 'projectAdmin'
  | 'projectMember'
  | 'projectAssistance'
  | 'projectController';

export declare type GlobalRole =
  | 'globalAdmin'
  | 'globalAssistance'
  | 'globalEmployee'
  | 'globalController'
  | 'globalHR';

export declare type ContactSearchType =
  | 'all'
  | 'members'
  | 'internal'
  | 'external'
  | 'companies'
  | 'allContacts'
  | 'allInternalContacts'
  | 'allInternalContactsInOffice'
  | 'allCompanies';

export declare type AppContext = 'teams' | 'outlook' | 'browser';

export declare type TrainingRequestState =
  | 'requested'
  | 'accepted'
  | 'declined'
  | 'revokeRequested'
  | 'revokeAccepted'
  | 'revokeDeclined';

export declare type SubMenuStateType = 'closed' | 'subMenu' | 'dropDownMenu';

export declare type ProjectTabViews = 'favorites' | 'myProjects' | 'all';

export enum ConfigurationKeys {
  PLACEHOLDER_PHONE = 'Placeholder-Phone',
  PLACEHOLDER_MOBILE = 'Placeholder-Mobile',
  PLACEHOLDER_FAX = 'Placeholder-Fax',
  PLACEHOLDER_PROJECTNR = 'Placeholder-Project-Number',
  REGEX_PHONE = 'Regex-Phone',
  REGEX_MOBILE = 'Regex-Mobile',
  REGEX_FAX = 'Regex-Fax',
  REGEX_PROJECTNR = 'Regex-Project-Number',
  SHOW_OFFICE_SETTING_TEMPLATES = 'showOfficeSettingTemplates',
  SHOW_OFFICE_SETTING_PROJECT_FILE_STRUCTURE = 'showOfficeSettingProjectFileStructure',
  SHOW_OFFICE_SETTING_TEXT_BLOCK = 'showOfficeSettingTextBlock',
  USE_PREFIX_TO_SAVE_ATTACHMENT_TO_CLOUD = 'usePrefixToSaveAttachmentToCloud',
}

export declare type ProjectNewsType = 'message';

export declare type TimeKeepingDayType =
  | 'homeOffice'
  | 'office'
  | 'remoteWork'
  | 'outOfOffice'
  | 'other';
export declare type TimeKeepingDayState =
  | 'recording'
  | 'ok'
  | 'notOk'
  | 'approvalRequested'
  | 'approved'
  | 'notApproved'
  | 'locked';

export declare type PrioFileUploadStatus = 'pending' | 'done' | 'error';
