import React from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import classNames from 'classnames';
import { Menu, Typography } from 'antd';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'react-jss';
import { makePrioStyles } from '../../../../theme/utils';
import { PrioTheme } from '../../../../theme/types';
import Flex from '../../../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  menu: {
    background: 'none',
    border: 'none',
    padding: `0 ${theme.old.spacing.defaultPadding}px`,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

const navItem = (
  itemKey: string,
  icon: IconName,
  t: TFunction,
  classes: Classes,
  urlPrefix?: string,
  customUrl?: string
) => (
  <Menu.Item key={itemKey}>
    <Link to={customUrl ?? `${urlPrefix ?? ''}${itemKey}`}>
      <FontAwesomeIcon
        fixedWidth
        icon={['fal', icon]}
        className={classes.icon}
      />
      {t(`hr:timekeepingMenu.${itemKey}`)}
    </Link>
  </Menu.Item>
);

interface HRTimekeepingMenuProps {
  selectedList?: string;
  className?: string;
  urlPrefix?: string;
}

export const HRTimekeepingMenu: React.FC<HRTimekeepingMenuProps> = (props) => {
  //#region ------------------------------ Defaults
  const { selectedList, urlPrefix, className } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column
      flexGrow={1}
      className={classNames('prio-module-navigation', classes.root, className)}
    >
      <Typography.Title className={classes.title}>
        {t('hr:timekeepingMenu.title')}
      </Typography.Title>
      <Menu
        className={classes.menu}
        selectedKeys={[selectedList]}
        mode="inline"
        inlineIndent={theme.old.components.menuInlineIndent}
      >
        {navItem(
          `timekeepingDays`,
          'business-time',
          t,
          classes,
          urlPrefix
          // `${urlPrefix ?? ''}timekeepingDays${
          //   officeId ? `?s=Calculated.OfficeId+eq+%27${officeId}%27` : ''
          // }`
        )}
        {navItem(
          'monthlyClose',
          'clock-seven',
          t,
          classes,
          urlPrefix
          // `${urlPrefix ?? ''}monthlyClose${
          //   officeId ? `?s=Calculated.OfficeId+eq+%27${officeId}%27` : ''
          // }`
        )}
        {navItem(
          'compensationPayments',
          'money-bill',
          t,
          classes,
          urlPrefix
          // `${urlPrefix ?? ''}compensationPayments${
          //   officeId ? `?s=Calculated.OfficeId+eq+%27${officeId}%27` : ''
          // }`
        )}
      </Menu>
    </Flex.Column>
  );
};

export default HRTimekeepingMenu;
