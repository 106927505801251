import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE, CLEAR_PRIO_CONTACTS } from '../../../actions';
import { Company } from '../../../models/Company';
import { distinct } from '../../../util';
import {
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_COMMIT,
  CREATE_COMPANY_ROLLBACK,
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_ROLLBACK,
  FETCH_COMPANIES_COMMIT,
  UPDATE_COMPANY_REQUEST,
  UPDATE_COMPANY_ROLLBACK,
  UPDATE_COMPANY_COMMIT,
  ARCHIVE_COMPANY_REQUEST,
  ARCHIVE_COMPANY_ROLLBACK,
  UNARCHIVE_COMPANY_REQUEST,
  UNARCHIVE_COMPANY_ROLLBACK,
  CREATE_BANKACCOUNT_REQUEST,
  CREATE_BANKACCOUNT_COMMIT,
  CREATE_BANKACCOUNT_ROLLBACK,
  UPDATE_BANKACCOUNT_COMMIT,
  DELETE_BANKACCOUNT_COMMIT,
  WS_UPDATE_OR_ADD_COMPANY,
} from '../actions';
import moment from 'moment';
import {
  ARCHIVE_EXTERNAL_OFFICE_REQUEST,
  ARCHIVE_EXTERNAL_OFFICE_ROLLBACK,
  ARCHIVE_INTERNAL_OFFICE_REQUEST,
  ARCHIVE_INTERNAL_OFFICE_ROLLBACK,
  CREATE_EXTERNAL_OFFICE_COMMIT,
  CREATE_EXTERNAL_OFFICE_REQUEST,
  CREATE_EXTERNAL_OFFICE_ROLLBACK,
  CREATE_INTERNAL_OFFICE_COMMIT,
  CREATE_INTERNAL_OFFICE_REQUEST,
  CREATE_INTERNAL_OFFICE_ROLLBACK,
  UPDATE_EXTERNAL_OFFICE_COMMIT,
  UPDATE_EXTERNAL_OFFICE_REQUEST,
  UPDATE_EXTERNAL_OFFICE_ROLLBACK,
  UPDATE_INTERNAL_OFFICE_COMMIT,
  UPDATE_INTERNAL_OFFICE_REQUEST,
  UPDATE_INTERNAL_OFFICE_ROLLBACK,
} from '../actions';

export interface CompaniesState {
  byId: CompaniesByIdState;
  ids: string[];
  redirects: any;
  meta: CompaniesMeta;
  offset: OffsetState;
}

export interface CompaniesByIdState {
  [companyId: string]: Company;
}

const byId: Reducer<CompaniesByIdState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_COMPANIES_COMMIT: {
      const {
        payload,
        meta: { offset },
      } = action;

      return (payload.values as Array<Company>).reduce(
        function (map, item) {
          map[item.companyId] = item;
          return map;
        },
        offset ? state : {}
      );
    }

    case CREATE_COMPANY_REQUEST: {
      const {
        payload: { company },
        meta: { temporaryId },
      } = action;
      return {
        ...state,
        [temporaryId]: {
          companyId: temporaryId,
          ...company,
        },
      };
    }

    case CREATE_COMPANY_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      // eslint-disable-next-line
      const { [temporaryId]: company, ...newState } = state;
      return newState;
    }

    case CREATE_COMPANY_COMMIT: {
      const {
        payload,
        payload: { companyId },
        meta: { temporaryId },
      } = action;
      const currentCompany = state[temporaryId];
      if (!currentCompany) return state;
      const { [temporaryId]: company, ...newState } = state;
      return {
        ...newState,
        [companyId]: payload,
      };
    }

    case UPDATE_COMPANY_REQUEST: {
      const {
        payload: { updateRequest },
        meta: { companyId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          ...updateRequest,
        },
      };
    }

    case UPDATE_COMPANY_COMMIT: {
      const {
        payload: { rowVersion },
        meta: { companyId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          rowVersion,
        },
      };
    }

    case UPDATE_COMPANY_ROLLBACK: {
      const {
        meta: { companyId },
        rollbackCompany,
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: rollbackCompany,
      };
    }

    case ARCHIVE_COMPANY_REQUEST: {
      const {
        meta: { companyId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          isArchived: true,
        },
      };
    }

    case ARCHIVE_COMPANY_ROLLBACK: {
      const {
        meta: { companyId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          isArchived: false,
        },
      };
    }

    case UNARCHIVE_COMPANY_REQUEST: {
      const {
        meta: { companyId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          isArchived: false,
        },
      };
    }

    case UNARCHIVE_COMPANY_ROLLBACK: {
      const {
        meta: { companyId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          isArchived: true,
        },
      };
    }

    case CREATE_INTERNAL_OFFICE_REQUEST: {
      const {
        payload: { office },
        meta: { companyId, temporaryId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: [
            ...currentCompany.offices,
            {
              ...office,
              officeId: temporaryId,
            },
          ],
        },
      };
    }

    case CREATE_INTERNAL_OFFICE_COMMIT: {
      const {
        payload,
        meta: { companyId, temporaryId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.map((office) =>
            office.officeId === temporaryId ? payload : office
          ),
        },
      };
    }

    case CREATE_INTERNAL_OFFICE_ROLLBACK: {
      const {
        meta: { companyId, temporaryId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.filter(
            (office) => office.officeId !== temporaryId
          ),
        },
      };
    }

    case UPDATE_INTERNAL_OFFICE_REQUEST: {
      const {
        payload: { updateRequest },
        meta: { companyId, officeId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.map((office) =>
            office.officeId === officeId
              ? {
                  ...office,
                  ...updateRequest,
                }
              : office
          ),
        },
      };
    }

    case UPDATE_INTERNAL_OFFICE_COMMIT: {
      const {
        payload: { rowVersion },
        meta: { companyId, officeId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.map((office) =>
            office.officeId === officeId
              ? {
                  ...office,
                  rowVersion,
                }
              : office
          ),
        },
      };
    }

    case UPDATE_INTERNAL_OFFICE_ROLLBACK: {
      const {
        meta: { companyId, officeId },
        rollbackOffice,
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.map((office) =>
            office.officeId === officeId ? rollbackOffice : office
          ),
        },
      };
    }
    case ARCHIVE_INTERNAL_OFFICE_REQUEST: {
      const {
        meta: { companyId, officeId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.filter(
            (office) => office.officeId !== officeId
          ),
        },
      };
    }

    case ARCHIVE_INTERNAL_OFFICE_ROLLBACK: {
      const {
        meta: { companyId },
        rollbackOffice,
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: [...currentCompany.offices, rollbackOffice],
        },
      };
    }

    case CREATE_EXTERNAL_OFFICE_REQUEST: {
      const {
        payload: { office },
        meta: { companyId, temporaryId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: [
            ...currentCompany.offices,
            {
              ...office,
              officeId: temporaryId,
            },
          ],
        },
      };
    }

    case CREATE_EXTERNAL_OFFICE_COMMIT: {
      const {
        payload,
        meta: { companyId, temporaryId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.map((office) =>
            office.officeId === temporaryId ? payload : office
          ),
        },
      };
    }

    case CREATE_EXTERNAL_OFFICE_ROLLBACK: {
      const {
        meta: { companyId, temporaryId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.filter(
            (office) => office.officeId !== temporaryId
          ),
        },
      };
    }

    case UPDATE_EXTERNAL_OFFICE_REQUEST: {
      const {
        payload: { updateRequest },
        meta: { companyId, officeId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.map((office) =>
            office.officeId === officeId
              ? {
                  ...office,
                  ...updateRequest,
                }
              : office
          ),
        },
      };
    }

    case UPDATE_EXTERNAL_OFFICE_COMMIT: {
      const {
        payload: { rowVersion },
        meta: { companyId, officeId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.map((office) =>
            office.officeId === officeId
              ? {
                  ...office,
                  rowVersion,
                }
              : office
          ),
        },
      };
    }

    case UPDATE_EXTERNAL_OFFICE_ROLLBACK: {
      const {
        meta: { companyId, officeId },
        rollbackOffice,
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.map((office) =>
            office.officeId === officeId ? rollbackOffice : office
          ),
        },
      };
    }
    case ARCHIVE_EXTERNAL_OFFICE_REQUEST: {
      const {
        meta: { companyId, officeId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: currentCompany.offices?.filter(
            (office) => office.officeId !== officeId
          ),
        },
      };
    }

    case ARCHIVE_EXTERNAL_OFFICE_ROLLBACK: {
      const {
        meta: { companyId },
        rollbackOffice,
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          offices: [...currentCompany.offices, rollbackOffice],
        },
      };
    }
    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      return {};
    }

    case CREATE_BANKACCOUNT_REQUEST: {
      const {
        payload: { bankAccount },
        meta: { companyId, temporaryId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          bankAccounts: [
            ...currentCompany.bankAccounts,
            {
              ...bankAccount,
              bankAccountId: temporaryId,
            },
          ],
        },
      };
    }

    case CREATE_BANKACCOUNT_COMMIT: {
      const {
        payload,
        meta: { companyId, temporaryId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          bankAccounts: currentCompany.bankAccounts?.map((bankAccount) =>
            bankAccount.bankAccountId === temporaryId ? payload : bankAccount
          ),
        },
      };
    }

    case CREATE_BANKACCOUNT_ROLLBACK: {
      const {
        meta: { companyId, temporaryId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          bankAccounts: currentCompany.bankAccounts?.filter(
            (bankAccount) => bankAccount.bankAccountId !== temporaryId
          ),
        },
      };
    }

    case DELETE_BANKACCOUNT_COMMIT: {
      const {
        meta: { companyId, bankAccountId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      const index = currentCompany.bankAccounts.findIndex(
        (item) => item.bankAccountId === bankAccountId
      );
      if (index === -1) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          bankAccounts: [
            ...currentCompany.bankAccounts.slice(0, index),
            ...currentCompany.bankAccounts.slice(index + 1),
          ],
        },
      };
    }

    case UPDATE_BANKACCOUNT_COMMIT: {
      const {
        payload,
        meta: { companyId },
      } = action;
      const currentCompany = state[companyId];
      if (!currentCompany) return state;
      const index = currentCompany.bankAccounts.findIndex(
        (item) => item.bankAccountId === payload.bankAccountId
      );
      if (index === -1) return state;
      return {
        ...state,
        [companyId]: {
          ...currentCompany,
          bankAccounts: [
            ...currentCompany.bankAccounts.slice(0, index),
            payload,
            ...currentCompany.bankAccounts.slice(index + 1),
          ],
        },
      };
    }

    case WS_UPDATE_OR_ADD_COMPANY: {
      const { company } = action;
      return {
        ...state,
        [company.companyId]: company,
      };
    }

    default:
      return state;
  }
};

interface OffsetState {
  offset: string | null;
  lastSync?: string;
}

const offset: Reducer<OffsetState, any> = (
  state = { offset: null },
  action
) => {
  switch (action.type) {
    case FETCH_COMPANIES_REQUEST: {
      const {
        meta: { offset },
      } = action;
      const today = moment().toISOString().slice(0, 23);
      return {
        offset: offset ? state.offset : null,
        lastSync: offset ? state.lastSync : today,
      };
    }
    case FETCH_COMPANIES_COMMIT: {
      const {
        payload: { offset },
      } = action;
      return {
        ...state,
        offset: (offset as string) ?? null,
      };
    }

    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      const today = moment().toISOString().slice(0, 23);
      return {
        lastSync: today,
        offset: null,
      };
    }
    default:
      return state;
  }
};

const ids: Reducer<Array<string>, any> = (state = [], action) => {
  switch (action.type) {
    case FETCH_COMPANIES_COMMIT: {
      const {
        payload: { values },
        meta: { offset },
      } = action;
      return distinct([
        ...(offset ? state : []),
        ...(values as Array<Company>).map((item) => item.companyId),
      ]);
    }
    case CREATE_COMPANY_REQUEST: {
      const {
        meta: { temporaryId },
      } = action;
      if (state.includes(temporaryId)) return state;
      return [...state, temporaryId];
    }
    case CREATE_COMPANY_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      return state.filter((id) => id !== temporaryId);
    }
    case CREATE_COMPANY_COMMIT: {
      const {
        payload: { companyId },
        meta: { temporaryId },
      } = action;
      if (!state.includes(temporaryId)) return state;
      return [...state.filter((id) => id !== temporaryId), companyId];
    }

    case WS_UPDATE_OR_ADD_COMPANY: {
      const { company } = action;
      if (state.includes(company.companyId)) return state;
      return [...state, company.companyId];
    }

    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      return [];
    }
    default:
      return state;
  }
};

const redirects: Reducer<any, any> = (state = {}, action) => {
  switch (action.type) {
    case CREATE_COMPANY_COMMIT: {
      const {
        payload: { companyId },
        meta: { temporaryId },
      } = action;
      return {
        ...state,
        [temporaryId]: companyId,
      };
    }

    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface CompaniesMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<CompaniesMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_COMPANIES_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_COMPANIES_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_COMPANIES_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'companies:errorMessages.fetchError',
      };
    }

    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<CompaniesState>({
  byId,
  ids,
  redirects,
  meta,
  offset,
});

export const getOffset: (state: CompaniesState) => string | null = (state) =>
  state.offset.offset;

export const getLastSync: (state: CompaniesState) => string = (state) =>
  state.offset.lastSync;

export const getCompaniesByIdState: (
  state: CompaniesState
) => CompaniesByIdState = (state) => state.byId;

export const getCompanyIds: (state: CompaniesState) => Array<string> = (
  state
) => state.ids;

export const getAllCompanies: (
  state: any,
  includeArchived?: boolean
) => Array<Company> = (state, includeArchived = false) =>
  (state.ids ?? [])
    .map((id) => state.byId[id])
    .filter(
      (company: Company) => (company.isArchived ?? false) === includeArchived
    )
    .sort((a: Company, b: Company) => {
      const shortNameCompare = a.shortName?.localeCompare(b.shortName);
      if (shortNameCompare !== 0) return shortNameCompare;
      return a.fullName?.localeCompare(b.fullName);
    });
export const getCompany: (state: any, companyId: string) => Company = (
  state,
  companyId
) => state.byId[companyId];
export const getRedirect: (state: any, temporaryId: string) => string = (
  state,
  temporaryId
) => state.redirects[temporaryId];

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;
