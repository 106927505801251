import {
  FilterHistoryDrawer,
  HistoryItem,
} from '@prio365/prio365-react-library';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { apiFetchSearchHistory, apiPutSearchHistory } from './api';
import {
  FilterConfig,
  calculatePickerStringsBasedOnSearchString,
} from './FilterBar';
import { useTranslation } from 'react-i18next';
import { Input, Modal } from 'antd';
import useContactsContext from '../../modules/contacts/hooks/useContactsProvider';
import useCompaniesContext from '../../modules/companies/hooks/useCompaniesContext';
import useProjectsContext from '../../modules/projects/hooks/useProjectsContext';
import moment from 'moment';
import { FilterPickerTypeStrings } from './FilterPicker';
import useOfficesContext from '../../modules/companies/hooks/useOfficesContext';

interface FilterBarHistoryDrawerProps {
  searchType: string;
  visible: boolean;
  filters: FilterConfig[];
  setVisible: (value: boolean) => void;
  onFilterAgain?: (HistoryItem: any) => void;
}

export const FilterBarHistoryDrawer: React.FC<FilterBarHistoryDrawerProps> = (
  props
) => {
  //#region -------------------------------- Variables
  const {
    searchType,
    visible,
    setVisible = () => {},
    filters,
    onFilterAgain = () => {},
  } = props;

  const { t } = useTranslation();

  //#endregion

  //#region -------------------------------- Redux

  const { getContactById } = useContactsContext();

  // const { internalContactsRedux, externalContactsRedux} = useMemo(() => {
  //   const internalContactsRedux = contactsRedux.filter(
  //     (contact) => contact.contactType === 'InternalContact'
  //   );
  //   const externalContactsRedux = contactsRedux.filter(
  //     (contact) => contact.contactType === 'ExternalContact'
  //   );
  //   return { internalContactsRedux, externalContactsRedux };
  // }, [contactsRedux]);

  const { getCompanyById } = useCompaniesContext();
  const { getProjectById } = useProjectsContext();
  const { getOfficeById } = useOfficesContext();

  // const reduxGet = {
  //   contact: (uuids: string[]) => {},
  //   internalContact: (uuids: string[]) => {},
  //   externalContact: (uuids: string[]) => {},
  //   company: (uuids: string[]) => {},
  //   project: (uuids: string[]) => {},
  //   office: (uuids: string[]) => {},
  //   employee: (uuids: string[]) => {},
  // };

  const getRedux = useCallback(
    (type: string, uuids: string[]): string[] => {
      switch (type) {
        case 'contact':
        case 'internalContact':
        case 'externalContact':
        case 'employee':
          const contacts =
            uuids?.map((uuid) => getContactById(uuid))?.filter((c) => c) || [];
          const contactNames = contacts.map(
            (contact) => contact?.firstName + ' ' + contact?.lastName
          );
          return contactNames;
        case 'company':
          const companies =
            uuids?.map((uuid) => getCompanyById(uuid))?.filter((c) => c) || [];
          const companyNames = companies.map(
            (company) =>
              company?.shortName || company?.fullName || company?.fullName2
          );
          return companyNames;
        case 'project':
          const projects =
            uuids?.map((uuid) => getProjectById(uuid))?.filter((c) => c) || [];
          const projectNames = projects.map(
            (project) => project?.shortName || project?.name
          );
          return projectNames;
        case 'office':
          const offices =
            uuids?.map((uuid) => getOfficeById(uuid))?.filter((c) => c) || [];
          const officeNames = offices.map((office) => office?.name);
          return officeNames;
        default:
          return null;
      }
    },
    [getContactById, getCompanyById, getProjectById, getOfficeById]
  );

  //#endregion

  //#region -------------------------------- States
  const [filterHistory, setFilterHistory] = useState<HistoryItem[]>(null);

  const [editDescriptionModalOpen, setEditDescriptionModalOpen] =
    useState<boolean>(false);
  const [historyItemInModal, setHistoryItemInModal] =
    useState<HistoryItem>(null);

  const historyItems = useMemo<HistoryItem[] | null>(() => {
    const historyItems =
      filterHistory?.map((item) => {
        item.pills = [];

        if (!item?.searchString?.length) {
          return item;
        }

        const searchStringSplit = item?.searchString?.split('&') || [];
        const allFiltersInItem = searchStringSplit?.map((string: string) => {
          const [parameterName] = string?.trim()?.split(' ');
          return parameterName;
        });
        const allUniqueFiltersInItem = Array.from(new Set(allFiltersInItem));

        allUniqueFiltersInItem?.forEach((pickerName) => {
          const filter = filters?.find(
            (filter) => filter.parameterName === pickerName
          );
          if (!filter) {
            return;
          }
          const pickerStrings = calculatePickerStringsBasedOnSearchString(
            pickerName,
            item?.searchString
          );

          // handle picker value conversion

          if (FilterPickerTypeStrings?.includes(filter?.pickerType)) {
            const uuids = [];
            pickerStrings?.forEach((pickerString) => {
              const { value } = pickerString;
              const valueArray =
                value.split(',')?.map((v) => v.replace(/'/g, '')) || [];
              uuids.push(...valueArray);
            });

            const values = getRedux(filter?.pickerType, uuids);

            if (values?.length > 0) {
              const label = values?.join(', ');
              item.pills.push({
                title: filter.parameterNameTranslated,
                text: label,
              });
              return;
            }
          }

          if (filter?.selectOptions?.[0]) {
            const values = [];
            pickerStrings?.forEach((pickerString) => {
              const { value } = pickerString;
              const valueArray =
                value.split(',')?.map((v) => v.replace(/'/g, '')) || [];
              valueArray.forEach((v) => {
                const option = filter?.selectOptions?.find(
                  (option) => option.selectValue === v
                );
                if (option) {
                  values.push(option.selectValueTranslated);
                }
              });
            });

            if (values?.length > 0) {
              const label = values?.join(', ');
              item.pills.push({
                title: filter.parameterNameTranslated,
                text: label,
              });
            }

            return;
          }

          switch (filter?.parameterType) {
            case 'date':
            case 'dateTime':
              // es-lint-disable-next-line
              var [startISOString, endISOString] =
                pickerStrings
                  ?.map((S) => S.value)
                  .sort((a, b) => a.localeCompare(b)) || [];

              if (startISOString && endISOString) {
                // 🚨 label funktion in der PrioCl komponente auslagern und exportieren und hier importieren und einbinden
                const label = generateLabel(
                  moment(startISOString),
                  moment(endISOString)
                );
                item.pills.push({
                  title: filter.parameterNameTranslated,
                  text: label,
                });
              }

              return;
            case 'month':
              // es-lint-disable-next-line
              [startISOString, endISOString] =
                pickerStrings
                  ?.map((S) => S.value)
                  .sort((a, b) => a.localeCompare(b)) || [];

              if (startISOString && endISOString) {
                // 🚨 label funktion in der PrioCl komponente auslagern und exportieren und hier importieren und einbinden
                const label = `${moment(startISOString).format(
                  'MMMM YYYY'
                )} bis ${moment(endISOString).format('MMMM YYYY')}`;

                item.pills.push({
                  title: filter.parameterNameTranslated,
                  text: label,
                });
              }
              return;
            case 'year':
              // es-lint-disable-next-line
              [startISOString, endISOString] =
                pickerStrings
                  ?.map((S) => S.value)
                  .sort((a, b) => a.localeCompare(b)) || [];

              if (startISOString && endISOString) {
                // 🚨 label funktion in der PrioCl komponente auslagern und exportieren und hier importieren und einbinden
                const label = `${moment(startISOString).format(
                  'YYYY'
                )} bis ${moment(endISOString).format('YYYY')}`;

                item.pills.push({
                  title: filter.parameterNameTranslated,
                  text: label,
                });
              }
              return;
            case 'boolean':
              // 🚨 label funktion in der PrioCl komponente auslagern und exportieren und hier importieren und einbinden
              const label =
                pickerStrings?.[0]?.value === 'true' ? 'Wahr' : 'Falsch';
              item.pills.push({
                title: filter.parameterNameTranslated,
                text: label,
              });

              return;
            default:
              if (pickerStrings?.length > 0) {
                const labelLogik = `${pickerStrings?.length} Filter`;
                item.pills.push({
                  title: filter.parameterNameTranslated,
                  text: labelLogik,
                });
              }
              return;
          }
        });

        return item;
      }) || [];

    return historyItems?.[0] ? historyItems : null;
  }, [filterHistory, filters, getRedux]);
  //#endregion

  //#region -------------------------------- Methods / Handlers
  const getFilterHistory = useCallback(async () => {
    const { data } = await apiFetchSearchHistory(searchType);
    if (data) {
      setFilterHistory(data);
    } else {
      setVisible(false);
    }
  }, [searchType, setVisible]);

  const handleBookmark = useCallback(
    async (historyItem: HistoryItem, isBookmarked: boolean = false) => {
      setFilterHistory(
        (prev) =>
          prev?.map((item) =>
            item?.searchHistoryId === historyItem?.searchHistoryId
              ? {
                  ...item,
                  isBookmarked,
                }
              : item
          )
      );
      const { data } = await apiPutSearchHistory(historyItem?.searchHistoryId, {
        description: historyItem?.description,
        isBookmarked,
        sortKey: historyItem?.sortKey,
      });
      if (data) {
      } else {
        getFilterHistory();
      }
    },
    [setFilterHistory, getFilterHistory]
  );

  const handleDescription = useCallback(
    async (historyItem: HistoryItem) => {
      setFilterHistory(
        (prev) =>
          prev?.map((item) =>
            item?.searchHistoryId === historyItem?.searchHistoryId
              ? {
                  ...item,
                  description: historyItem?.description,
                }
              : item
          )
      );

      const { data } = await apiPutSearchHistory(historyItem?.searchHistoryId, {
        description: historyItem?.description,
        isBookmarked: historyItem?.isBookmarked,
        sortKey: historyItem?.sortKey,
      });
      if (data) {
      } else {
        getFilterHistory();
      }
    },
    [getFilterHistory]
  );

  async function handleEditDescriptionModalOk(historyItem: HistoryItem) {
    setEditDescriptionModalOpen(false);
    handleDescription(historyItem);
  }
  //#endregion

  //#region -------------------------------- Effects
  useEffect(() => {
    if (visible) {
      getFilterHistory();
    } else {
      setFilterHistory(null);
    }
  }, [searchType, visible, getFilterHistory]);
  //#endregion

  return (
    <div>
      <FilterHistoryDrawer
        onFilterAgain={onFilterAgain}
        onBookmark={handleBookmark}
        onClose={() => setVisible(false)}
        historyItems={historyItems}
        onEditDescription={(historyItem) => {
          setHistoryItemInModal(historyItem);
          setEditDescriptionModalOpen(true);
        }}
        visible={visible}
        title={t('components:filter.historyDrawer.title')}
        header={t('components:filter.historyDrawer.searchHistory')}
      />
      <Modal
        style={{ zIndex: 9999999 }}
        title={'Name Bearbeiten'}
        visible={editDescriptionModalOpen}
        onOk={() => handleEditDescriptionModalOk(historyItemInModal)}
        onCancel={() => {
          setEditDescriptionModalOpen(false);
        }}
        cancelText={t('mail:modals.assignMessage.cancelText')}
      >
        <Input
          type="text"
          value={historyItemInModal?.description}
          onChange={(e) =>
            setHistoryItemInModal((historyItemInModal) => ({
              ...historyItemInModal,
              description: e.target.value,
            }))
          }
        />
      </Modal>
    </div>
  );
};

// 🚨 will be imported from CL in future
function generateLabel(start: moment.Moment, end: moment.Moment) {
  if (start && end) {
    if (start?.isSame(end, 'day')) {
      return `${start?.format('DD.MM.YYYY')}`;
    }
    if (start?.isSame(end, 'month')) {
      return `${start?.format('DD.')} bis ${end?.format('DD.MM.YYYY')}`;
    }
    if (start?.isSame(end, 'year')) {
      return `${start?.format('DD.MM.')} bis ${end?.format('DD.MM.YYYY')}`;
    }
    return `${start?.format('DD.MM.YY')} bis ${end?.format('DD.MM.YYYY')}`;
  }
  return 'Alle';
}
