import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../../apps/main/rootReducer';
import { ProjectId, ProjectTabViews } from '../../../../models/Types';
import ProjectsNavigationSkeleton from '../ProjectsNavigationSkeleton';
import { AutoSizer, Index, List } from 'react-virtualized';
import { distinct } from '../../../../util';
import ProjectDropDownNavigationItem from './ProjectDropDownNavigationItem';
import useProjectSearch from '../../hooks/useProjectSearch';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../../theme/utils';
import { ProjectByIdState } from '../../reducers/projects';
import { Project } from '../../../../models/Project';
import { getProjectAccess } from '../../utils';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  noContent: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
}));

const useProjectCollapsible: (
  projectIds: ProjectId[],
  byId: ProjectByIdState,
  ref: React.MutableRefObject<any>
) => [
  {
    [projectId: ProjectId]: boolean;
  },
  {
    [projectId: ProjectId]: ProjectId[];
  },
  (projectId: ProjectId, collapsed: boolean) => void,
] = (projectIds, byId, ref) => {
  const [collapsedParentProjects, setCollapsedParentProjects] = useState<{
    [projectId: ProjectId]: boolean;
  }>({});

  const parentProjectSubProjects = useMemo(
    () =>
      projectIds.reduce((map, id) => {
        const project = byId[id];
        return {
          ...map,
          [project.parentProject]: distinct([
            ...(map[project.parentProject] ?? []),
            project.projectId,
          ]),
        };
      }, {}),
    [projectIds, byId]
  );

  const setCollapseParentProject = useCallback(
    (projectId: ProjectId, collapsed: boolean) => {
      setCollapsedParentProjects({
        ...collapsedParentProjects,
        [projectId]: collapsed,
      });
      ref?.current?.recomputeRowHeights();
      ref?.current?.forceUpdate();
    },
    [collapsedParentProjects, ref]
  );

  useEffect(() => {
    const _projectIds = distinct(
      projectIds
        .filter((id) => byId[id].parentProject)
        .map((id) => byId[id].parentProject)
    );
    const currentProjectIds = Object.keys(collapsedParentProjects);
    const idDiff = _projectIds.filter(
      (projectId) => !currentProjectIds.includes(projectId)
    );
    if (idDiff.length > 0) {
      setCollapsedParentProjects(
        projectIds
          .filter((id) => byId[id].parentProject)
          .reduce((map, id) => {
            const project = byId[id];
            return {
              ...map,
              [project.parentProject]:
                collapsedParentProjects[project.parentProject] ?? true,
            };
          }, {})
      );
    }
  }, [projectIds, byId, collapsedParentProjects]);

  return [
    collapsedParentProjects,
    parentProjectSubProjects,
    setCollapseParentProject,
  ];
};

interface ProjectDropDownNavigationProps {
  activeProjectId?: string;
  selectedSubModule?: string;
  type?: ProjectTabViews;
  searchString?: string;
  isSubMenu?: boolean;
  pathPrefix?: string;
  isSearching?: boolean;
  activeTab?: string;
  archivedProjectsHidden?: boolean;
}

export interface ProjectDropDownNavigationRefProps {
  getCurrentProjects: () => Project[];
}

export const ProjectDropDownNavigation = React.memo(
  React.forwardRef<
    ProjectDropDownNavigationRefProps,
    ProjectDropDownNavigationProps
  >((props, componentRef) => {
    //#region ------------------------------ Defaults
    const {
      activeProjectId,
      selectedSubModule,
      type,
      searchString,
      isSubMenu,
      pathPrefix,
      isSearching,
      activeTab,
      archivedProjectsHidden,
    } = props;
    const ref = useRef(null);
    const { t } = useTranslation();
    const classes = useStyles();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const userMe = useSelector(getUserMe);
    const userMeProjectRoles = useSelector(getUserMe)?.prioData?.projectRoles;
    const userMeProjectIds = Object.keys(userMeProjectRoles ?? {});

    const myProjectIdsSet = useMemo(
      () => new Set(userMeProjectIds),
      [userMeProjectIds]
    );

    const [projectIds, byId, isFetching] = useProjectSearch({
      searchTerm: searchString,
      type: isSearching ? 'all' : type,
      archivedProjectsHidden: archivedProjectsHidden,
    });

    const [
      collapsedParentProjects,
      parentProjectSubProjects,
      setCollapseParentProject,
    ] = useProjectCollapsible(projectIds, byId, ref);

    // necessary for re-rendering the list!
    const activeProject = useMemo(
      () => byId[activeProjectId],
      [byId, activeProjectId]
    );
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const calcRowHeight: (params: Index) => number = useCallback(
      ({ index }) => {
        const project = byId[projectIds[index]];
        if (
          !project ||
          (!isSearching && type === 'favorites' && !project.favorite)
        ) {
          return 0;
        }
        if (!isSearching && collapsedParentProjects[project?.parentProject]) {
          if (type !== 'favorites') {
            return 0;
          } else if (projectIds.find((id) => project?.parentProject === id)) {
            return 0;
          }
        }

        const accessRights = getProjectAccess(project, userMe, activeTab);

        if (!isSubMenu && project?.projectId === activeProjectId) {
          const menuItems = Object.values(accessRights).filter(
            (item) => item
          ).length;
          return 40 + menuItems * 40;
        }
        return 40;
      },
      [
        projectIds,
        byId,
        activeProjectId,
        userMe,
        isSearching,
        isSubMenu,
        collapsedParentProjects,
        type,
        activeTab,
      ]
    );

    const rerenderProjectList = useCallback(() => {
      ref?.current?.recomputeRowHeights();
      ref?.current?.forceUpdate();
    }, [ref]);

    //#endregion

    //#region ------------------------------ Effects
    useImperativeHandle(componentRef, () => ({
      getCurrentProjects: () => {
        return projectIds.map((id) => byId[id]);
      },
    }));

    useEffect(() => {
      rerenderProjectList();
    }, [
      ref,
      activeProjectId,
      selectedSubModule,
      isSearching,
      type,
      projectIds,
      byId,
      activeProject,
      calcRowHeight,
      rerenderProjectList,
    ]);

    if (projectIds?.length === 0) {
      if (isFetching) {
        return <ProjectsNavigationSkeleton />;
      }
      return (
        <div className={classes.noContent}>
          {t('projects:navigation.noContent')}
        </div>
      );
    }
    //endregion

    return (
      <div className={classes.root}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              rowCount={projectIds.length}
              rowHeight={calcRowHeight}
              height={height}
              width={width}
              overscanRowCount={10}
              ref={ref}
              rowRenderer={({ index, key, style }) => {
                const project = byId[projectIds[index]];
                const isParentProject =
                  !isSearching &&
                  collapsedParentProjects[project.projectId] !== undefined &&
                  parentProjectSubProjects[project.projectId]?.length > 0;
                if (
                  !project ||
                  (!isSearching && type === 'favorites' && !project.favorite)
                ) {
                  return null;
                }
                if (
                  !isSearching &&
                  collapsedParentProjects[project?.parentProject]
                ) {
                  if (type !== 'favorites') {
                    return null;
                  } else if (
                    projectIds.find((id) => project?.parentProject === id)
                  ) {
                    return null;
                  }
                }
                const isProjectMember = myProjectIdsSet.has(project.projectId);
                return (
                  <ProjectDropDownNavigationItem
                    project={project}
                    pathPrefix={pathPrefix}
                    isSubMenu={isSubMenu}
                    style={style}
                    activeProjectId={activeProjectId}
                    selectedSubModule={selectedSubModule}
                    key={key}
                    type={type}
                    isProjectMember={isProjectMember}
                    isParentProject={isParentProject}
                    isAnySubProjectFavorited={
                      isParentProject &&
                      parentProjectSubProjects[project.projectId]?.some(
                        (subProject) => byId[subProject]?.favorite
                      )
                    }
                    activeTab={activeTab}
                    collapsed={collapsedParentProjects[project.projectId]}
                    setCollapsed={setCollapseParentProject}
                    rerenderProjectList={rerenderProjectList}
                  />
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
    );
  })
);

export default ProjectDropDownNavigation;
