import React, { useEffect, useState } from 'react';
import Flex from '../../../components/Flex';
import { Center } from '../../../components/Center';
import {
  AbsenceOverview,
  AbsenceProposal,
} from '../../../models/AbsenceProposal';
import { makePrioStyles } from '../../../theme/utils';
import PrioSpinner from '../../../components/PrioSpinner';
import { OfficeId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import { getOfficesByIdState } from '../../../apps/main/rootReducer';
import AbsenceProposalTable from '../../absences/components/AbsenceProposalTable';
import AbsenceProposalDetailsDrawer from '../../absences/components/AbsenceProposalDetailsDrawer';
import AbsenceOverviewChartMinimal from '../../absences/components/overview/AbsenceOverviewChartMinimal';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    paddingTop: theme.spacing.regular,
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  loadingOverlayAbsenceOverview: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffffaa',
  },
  absenceListView: {
    borderTop: '1px solid rgb(240, 240, 240)',
    marginTop: theme.spacing.regular,
  },
}));

interface EmployeeAbsencesOverviewProps {
  absenceOverview: AbsenceOverview;
  absenceOverviewIsFetching;
  officeId?: OfficeId;
  fetchAbsenceOverview: () => void;
}

const EmployeeAbsencesOverview: React.FC<EmployeeAbsencesOverviewProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const {
    absenceOverview,
    absenceOverviewIsFetching,
    officeId,
    fetchAbsenceOverview,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [open, setOpen] = useState<boolean>(false);
  const [selectedAbsenceProposal, setSelectedAbsenceProposal] =
    useState<AbsenceProposal | null>(null);
  const [absenceProposals, setAbsenceProposals] = useState<AbsenceProposal[]>(
    absenceOverview?.absenceProposals ?? []
  );

  const officesById = useSelector(getOfficesByIdState);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const updateAbsenceTable = (absenceProposal: AbsenceProposal) => {
    const copyAbsenceProposals = [...absenceProposals];
    const index = absenceOverview?.absenceProposals.findIndex(
      (proposal) =>
        proposal.absenceProposalId === absenceProposal.absenceProposalId
    );
    if (index > -1) {
      copyAbsenceProposals.splice(index, 1, absenceProposal);
      setAbsenceProposals(copyAbsenceProposals);
    }
  };

  const openDrawer = (value: AbsenceProposal) => {
    setOpen(true);
    setSelectedAbsenceProposal(value);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (absenceOverview) {
      setAbsenceProposals(absenceOverview?.absenceProposals);
    }
  }, [absenceOverview]);

  useEffect(() => {
    if (fetchAbsenceOverview) {
      fetchAbsenceOverview();
    }
  }, [fetchAbsenceOverview]);
  //#endregion

  return (
    <div className={classes.root}>
      <Flex.Column className={classes.content}>
        <AbsenceOverviewChartMinimal absenceOverview={absenceOverview} />
        <AbsenceProposalTable
          className={classes.absenceListView}
          absenceProposals={absenceProposals
            ?.filter(function (obj) {
              return obj.absenceState !== 'revokeAccepted';
            })
            .sort((a, b) => Date.parse(b.from) - Date.parse(a.from))}
          tableId="prio-employee"
          onRowClick={openDrawer}
        />
        <AbsenceProposalDetailsDrawer
          absenceProposalDrawerOpen={open}
          setAbsenceProposalDrawerOpen={setOpen}
          absenceProposal={selectedAbsenceProposal}
          officesById={officesById}
          setClickedAbsenceProposal={setSelectedAbsenceProposal}
          officeId={officeId}
          updateAbsenceTable={updateAbsenceTable}
        ></AbsenceProposalDetailsDrawer>
      </Flex.Column>

      {absenceOverviewIsFetching && (
        <div className={classes.loadingOverlayAbsenceOverview}>
          <Center>
            <PrioSpinner size="large" />
          </Center>
        </div>
      )}
    </div>
  );
};

export default EmployeeAbsencesOverview;
