import { Divider, Form, Modal, Switch, notification } from 'antd';
import { Button, Input, Select } from '@prio365/prio365-react-library';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreateProjectExtension,
  ProjectExtension,
  UpdateProjectExtension,
} from '../../../../../../models/ProjectExtension';
import { ProjectExtensionId, ProjectId } from '../../../../../../models/Types';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { colon } from '../../../../../../util/forms';
import { apiFetchProjectFileStructure } from '../../../../../settings/api';
import { ProjectFileSystemStructureId } from '../../../../../../models/ProjectFileStructure';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getUserMe,
} from '../../../../../../apps/main/rootReducer';
import { User } from '../../../../../../models/User';
import { apiProjectExtensionTrigger } from '../../../../api';
import InformationBox from '../../../../../../components/InformationBox';
import Flex from '../../../../../../components/Flex';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '100px',
  },
  label: {
    color: theme.colors.application.typography.muted,
  },
  submitButton: {
    position: 'absolute',
    bottom: '32px',
  },
}));

export interface SharepointProjectExtensionFormModel {
  displayName: string;
  projectFileSystemStructureId: string;
  hasDefaultAccess: boolean;
  isTeamEnabled?: boolean;
}

interface SharepointProjectExtensionFormProps {
  projectExtension: ProjectExtension;
  addProjectExtension: CreateProjectExtension;
  projectId: ProjectId;
  updateProjectExtension: (
    projectId: ProjectId,
    projectExtensionId: ProjectExtensionId,
    projectExtension: UpdateProjectExtension
  ) => Promise<ProjectExtension>;
  createProjectExtension: (
    projectId: ProjectId,
    createProjectExtension: CreateProjectExtension
  ) => Promise<ProjectExtension>;
}

export const SharepointProjectExtensionForm: React.FC<
  SharepointProjectExtensionFormProps
> = (props) => {
  const {
    projectExtension,
    addProjectExtension,
    projectId,
    updateProjectExtension,
    createProjectExtension,
  } = props;
  const classes = useStyles();
  const [form] = Form.useForm();

  const userMe = useSelector<RootReducerState, User>(getUserMe);
  // loading
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValue, setInitialValue] =
    useState<SharepointProjectExtensionFormModel>(
      projectExtension
        ? {
            displayName: projectExtension.displayName,
            projectFileSystemStructureId: projectExtension.options?.find(
              (option) => option.key === 'projectFileSystemStructureId'
            )?.value,
            hasDefaultAccess: projectExtension.hasDefaultAccess,
            isTeamEnabled: projectExtension.options?.find(
              (option) => option.key === 'teams'
            )
              ? JSON.parse(
                  projectExtension.options?.find(
                    (option) => option.key === 'teams'
                  )?.value
                ).enableTeams
              : false,
          }
        : {
            displayName: 'Dokumente',
            projectFileSystemStructureId: '',
            hasDefaultAccess: true,
            isTeamEnabled: true,
          }
    );
  const [projectFileStructure, setProjectFileStructure] = useState<
    ProjectFileSystemStructureId[]
  >([]);
  const { t } = useTranslation();

  const saveOrUpdateExtension = async (
    sharepointProjectExtensionFormModel: SharepointProjectExtensionFormModel
  ) => {
    setLoading(true);
    if (projectExtension && !addProjectExtension) {
      await updateProjectExtension(
        projectId,
        projectExtension.projectExtensionId,
        {
          displayName: sharepointProjectExtensionFormModel.displayName,

          hasDefaultAccess:
            sharepointProjectExtensionFormModel?.hasDefaultAccess,
        }
      );
    } else if (addProjectExtension && !projectExtension) {
      await createProjectExtension(projectId, {
        displayName: sharepointProjectExtensionFormModel.displayName,
        projectExtensionType: 'sharepoint',
        hasDefaultAccess: true,
        addProjectExtensionOptions: [
          {
            key: 'projectFileSystemStructureId',
            value:
              sharepointProjectExtensionFormModel.projectFileSystemStructureId,
          },
          {
            key: 'teams',
            value: JSON.stringify({
              enableTeams: sharepointProjectExtensionFormModel.isTeamEnabled,
              allowCreatePrivateChannels: true,
              allowCreateUpdateChannels: true,
              allowUserEditMessages: true,
              allowUserDeleteMessages: true,
              allowGiphy: true,
              giphyContentRating: 'strict',
            }),
          },
        ],
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (projectExtension) {
      setInitialValue({
        displayName: projectExtension.displayName,
        projectFileSystemStructureId: projectExtension.options?.find(
          (option) => option.key === 'projectFileSystemStructureId'
        )?.value,
        hasDefaultAccess: projectExtension.hasDefaultAccess,
      });
    }
    form.resetFields();
  }, [projectExtension, form]);

  useEffect(() => {
    const loadProjectFileStructure = async () => {
      const { data } = await apiFetchProjectFileStructure();

      if (data) {
        setProjectFileStructure(data);
        if (data?.length > 0) {
          form.setFieldsValue({
            projectFileSystemStructureId:
              data[0]['projectFileSystemStructureId'],
          });
        }
      }
    };
    loadProjectFileStructure();
  }, [form]);

  const handleOkCreateFolder = async () => {
    if (userMe.prioData.globalRoles.includes('globalAdmin')) {
      const result = await apiProjectExtensionTrigger(
        projectId,
        projectExtension.projectExtensionId,
        'CreateFolderStructure'
      );
      if (result.status >= 200 && result.status < 300) {
        notification.open({
          message: t('common:success'),
          description: t(
            'projects:successMessages.createNewFolderSuccessMessage'
          ),
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('projects:errorMessages.createNewFolderError'),
        });
      }
    }
  };

  const showModal = (type: 'createFolderStructureModal') => {
    switch (type) {
      case 'createFolderStructureModal':
        Modal.confirm({
          title: t(`projects:adminSetting.${type}.title`),
          onOk() {
            handleOkCreateFolder();
          },
          onCancel() {},
          content: t(`projects:adminSetting.${type}.content`),
          okText: t(`projects:adminSetting.${type}.okText`),
          cancelText: t(`projects:adminSetting.${type}.cancelText`),
          icon: null,
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      {projectExtension != null ? (
        <>
          <p>
            {t('projects:addIn.sharepoint')}: {initialValue.displayName}
          </p>
          <p>
            {t('projects:form.labels.fileStructure')}:{' '}
            {
              projectFileStructure.find(
                (fileStructure) =>
                  fileStructure.projectFileSystemStructureId ===
                  initialValue.projectFileSystemStructureId
              )?.name
            }
          </p>
          {userMe.prioData.globalRoles.includes('globalAdmin') && (
            <>
              <Divider />
              <h2>{t('projects:adminSetting.title')}</h2>
              <Divider />
              <p className={classes.label}>
                {t('projects:adminSetting.createFolderStructureModalDesc')}
              </p>
              <Button onClick={() => showModal('createFolderStructureModal')}>
                {t('projects:adminSetting.buttons.createFolderStructureModal')}
              </Button>
              <Divider />
            </>
          )}
        </>
      ) : (
        <>
          <Form
            form={form}
            layout="vertical"
            className={classes.root}
            initialValues={initialValue}
            onFinish={(values) => {
              saveOrUpdateExtension(values);
            }}
            onValuesChange={(_, values) => {
              //nothingToDo
            }}
          >
            <Form.Item
              name="displayName"
              required={true}
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t('projects:form.validation.displayName'),
                },
              ]}
              label={t('projects:addIn.extensions.sharepoint.form.displayName')}
            >
              <Input full disabled={loading} />
            </Form.Item>
            <InformationBox
              description={t(
                'projects:addIn.extensions.sharepoint.displayNameDescription'
              )}
              marginBottom={true}
            />
            <Form.Item
              name="projectFileSystemStructureId"
              label={t('projects:form.labels.fileStructure')}
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t('projects:form.validation.missingFileStructure'),
                },
              ]}
            >
              <Select>
                {projectFileStructure.map((fileStructure) => (
                  <Select.Option
                    value={fileStructure.projectFileSystemStructureId}
                    key={fileStructure.projectFileSystemStructureId}
                  >
                    {fileStructure.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>{' '}
            <InformationBox
              description={t(
                'projects:addIn.extensions.sharepoint.folderStructureDescription'
              )}
              marginBottom={true}
            />
            <Form.Item
              name="isTeamEnabled"
              colon={colon}
              validateTrigger="onBlur"
              label={t(
                'projects:addIn.extensions.sharepoint.form.isTeamEnabled'
              )}
              valuePropName="checked"
            >
              <Switch disabled={loading} />
            </Form.Item>
            <InformationBox
              description={t(
                'projects:addIn.extensions.sharepoint.isTeamEnabledDescription'
              )}
              marginBottom={true}
            />
            <Form.Item
              name="hasDefaultAccess"
              colon={colon}
              required={true}
              label={t('projects:addIn.extensions.form.hasDefaultAccess')}
              rules={[
                {
                  required: true,
                  message: t(
                    'projects:form.validation.hasDefaultAccessRequired'
                  ),
                },
              ]}
              valuePropName="checked"
            >
              <Switch disabled={loading} />
            </Form.Item>
            <InformationBox
              description={t(
                'projects:addIn.extensions.sharepoint.hasDefaultAccessDescription'
              )}
              marginBottom={true}
            />
            <Flex.Row flex={1} justifyContent="flex-end">
              <Button
                htmlType="submit"
                disabled={loading}
                loading={loading}
                className={classes.submitButton}
              >
                {t('projects:projectCreation.save')}
              </Button>
            </Flex.Row>
          </Form>
        </>
      )}
    </>
  );
};

export default SharepointProjectExtensionForm;
