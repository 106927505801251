import { useMemo } from 'react';
import useOfficesContext from '../../../modules/companies/hooks/useOfficesContext';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import useAccessRights2 from '../../../modules/users/hooks/useAccessRights2';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import useContactsContext from '../../../modules/contacts/hooks/useContactsProvider';
import { distinctByProperty } from '../../../util';

const OfficeFilterPickerConsumer: React.FC<FilterPickerConsumerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { children, roles } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const {
    checkGlobalRoles,
    checkOfficeRolesOfOfficeId,
    checkProjectRolesOfOfficeId,
  } = useAccessRights2();

  const { getContactById } = useContactsContext();

  const { myOffices, internalOffices, getOfficeById } = useOfficesContext();

  const userMe = useSelector(getUserMe);
  const officeMe = useMemo(() => {
    return getOfficeById(getContactById(userMe?.id)?.officeId);
  }, [getOfficeById, getContactById, userMe]);

  const offices = useMemo(() => {
    if (
      roles.globalRoles.length > 0 &&
      checkGlobalRoles(
        roles.globalRoles.filter((role) => role !== 'globalEmployee')
      )
    ) {
      return internalOffices;
    }
    if (roles.officeRoles.length > 0 && roles.projectRoles.length > 0) {
      return internalOffices.filter(
        (office) =>
          checkOfficeRolesOfOfficeId(roles.officeRoles, office.officeId) ||
          checkProjectRolesOfOfficeId(roles.projectRoles, office.officeId)
      );
    }
    return distinctByProperty([...myOffices, officeMe], 'officeId');
  }, [
    roles,
    myOffices,
    officeMe,
    internalOffices,
    checkGlobalRoles,
    checkOfficeRolesOfOfficeId,
    checkProjectRolesOfOfficeId,
  ]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      {children({
        options: offices.map(({ officeId, name }) => {
          return {
            value: officeId,
            label: name,
            searchValue: `${name} ${officeId}`,
          };
        }),
      })}
    </>
  );
};

export default OfficeFilterPickerConsumer;
