import { GenericSearchResultItem, SearchOperation } from '../types';
import moment from 'moment';

interface SearchCondition {
  field: string;
  operator: SearchOperation;
  value: string;
}

const isUuid = (value: string): boolean => {
  return !!value.match(
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/
  );
};

const isBoolean = (value: string): boolean => {
  return value === 'true' || value === 'false';
};

const isNumber = (value: string): boolean => {
  return !isNaN(Number(value));
};

const isDateTimeString = (value: string): boolean => {
  return !isNaN(Date.parse(value));
};

const toCamelCase: (str: string) => string = (str) => {
  return str[0].toLowerCase() + str.slice(1);
};

const parseCondition: (conditionString: string) => SearchCondition = (
  conditionString
) => {
  const parts = conditionString.trim().split(/\s+/);
  const field = parts[0];
  const operator = parts[1] as SearchOperation;
  const value = parts.slice(2).join(' ');
  return { field, operator, value: value.substring(1, value.length - 1) };
};

const getField: (item: GenericSearchResultItem, field: string) => any = (
  item,
  field
) => {
  if (field.startsWith('Data.')) {
    const fieldName = toCamelCase(field.substring(5));
    return item.data[fieldName];
  } else if (field.startsWith('Calculated.')) {
    const fieldName = toCamelCase(field.substring(11));
    return item.calculated[fieldName];
  } else {
    throw new Error(`Invalid field: ${field}`);
  }
};

const _equals: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  const valueIsArray = value.includes("','");
  if (isBoolean(value)) {
    return fieldValue === (value === 'true');
  } else if (isNumber(value)) {
    return fieldValue === Number(value);
  } else if (isDateTimeString(value)) {
    return (
      moment(fieldValue).seconds(0).milliseconds(0).toISOString(true) ===
      moment(value).seconds(0).milliseconds(0).toISOString(true)
    );
  } else if (isUuid(value)) {
    return fieldValue.toLowerCase() === value.toLowerCase();
  } else if (valueIsArray) {
    const array = value.split(',').map((v) => {
      if (v.startsWith("'") || v.endsWith("'")) {
        v = v.replace("'", '');
        v = v.replace("'", '');
      }
      if (isUuid(v)) {
        return v.toLowerCase();
      }
      return v;
    });

    if (Array.isArray(fieldValue)) {
      return fieldValue.every((v) => array.includes(v.toLowerCase()));
    }
    return array.includes(fieldValue);
  } else {
    return (
      fieldValue === value || fieldValue.toLowerCase() === value.toLowerCase()
    );
  }
};

const _greaterThan: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  if (isNumber(value)) {
    return fieldValue > Number(value);
  } else if (isDateTimeString(value)) {
    return moment(fieldValue).isAfter(moment(value));
  } else {
    throw new Error(`Invalid value: ${value}`);
  }
};

const _lessThan: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  if (isNumber(value)) {
    return fieldValue < Number(value);
  } else if (isDateTimeString(value)) {
    return moment(fieldValue).isBefore(moment(value));
  } else {
    throw new Error(`Invalid value: ${value}`);
  }
};

const _greaterThanEquals: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  if (isNumber(value)) {
    return fieldValue >= Number(value);
  } else if (isDateTimeString(value)) {
    return moment(fieldValue).isSameOrAfter(moment(value));
  } else {
    throw new Error(`Invalid value: ${value}`);
  }
};

const _lessThanEquals: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  if (isNumber(value)) {
    return fieldValue <= Number(value);
  } else if (isDateTimeString(value)) {
    return moment(fieldValue).isSameOrBefore(moment(value));
  } else {
    throw new Error(`Invalid value: ${value}`);
  }
};

const _isIn: (value: string, fieldValue: any) => boolean = (
  value,
  fieldValue
) => {
  const fieldValueIsArray = Array.isArray(fieldValue);
  const array = value.split(',').map((v) => {
    if (v.startsWith("'") || v.endsWith("'")) {
      v = v.replace("^'", '');
      v = v.replace("'$", '');
    }
    if (isUuid(v)) {
      return v.toLowerCase();
    }
    return v;
  });

  if (fieldValueIsArray) {
    return array.every((v) =>
      fieldValue
        .map((value) => {
          if (isUuid(value)) {
            return value.toLowerCase();
          }
          return value;
        })
        .includes(v)
    );
  }
  return array.includes(fieldValue);
};

const applyCondition: (
  item: GenericSearchResultItem,
  condition: SearchCondition
) => boolean = (item, condition) => {
  const { field, operator, value } = condition;
  const fieldValue = getField(item, field);

  switch (operator) {
    case 'eq':
      return _equals(value, fieldValue);
    case 'ne':
      return !_equals(value, fieldValue);
    case 'gt':
      return _greaterThan(value, fieldValue);
    case 'lt':
      return _lessThan(value, fieldValue);
    case 'ge':
      return _greaterThanEquals(value, fieldValue);
    case 'le':
      return _lessThanEquals(value, fieldValue);
    case 'in':
      return _isIn(value, fieldValue);
    case 'notin':
      return !_isIn(value, fieldValue);
    case 'like':
      return fieldValue.includes(value);
    case 'notlike':
      return !fieldValue.includes(value);
    case 'isnull':
      return fieldValue === null || fieldValue === undefined;
    case 'isnotnull':
      return fieldValue !== null && fieldValue !== undefined;
    default:
      throw new Error(`Invalid operator: ${operator}`);
  }
};

export const filterSearchDataBasedOnSearchString: <
  ResultData = unknown,
  Calculated = unknown,
>(
  data: GenericSearchResultItem<ResultData, Calculated>[],
  searchString: string
) => GenericSearchResultItem<ResultData, Calculated>[] = (
  data,
  searchString
) => {
  const conditions = searchString.split('&').map(parseCondition);

  return data.filter((item) =>
    conditions.every((condition) => applyCondition(item, condition))
  );
};
