import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Flex from '../../../components/Flex';
import { Button, Drawer, useTheme } from '@prio365/prio365-react-library';
import { PrioTheme } from '../../../theme/types';
import ActionBar from '../../../components/ActionBar';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import ListMenuArea from '../../common/ListMenuArea';
import JiraConfigurationForm from './JiraConfigurationForm';
import { apiGetAllProjectExtensionConfiguration } from '../../projects/api';
import { ProjectExtensionConfiguration } from '../../../models/ProjectExtension';
import SvgIcon from '../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../util/icon';
import JiraConfigurationCreate from './JiraConfigurationCreate';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  item: {
    color: theme.colors.application.typography.default,
    fontWeight: theme.font.fontSize.regular,
  },
  subItem: {
    color: theme.colors.application.typography.muted,
    fontWeight: theme.font.fontSize.extraSmall,
    float: 'left',
  },
  listItem: {
    padding: `${theme.spacing.regular * 0.5}px ${theme.spacing.regular}px`,
    cursor: 'pointer',
    width: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    '& h2': {
      width: '100%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      marginBottom: `${theme.spacing.regular * 0.5}px`,
    },
  },
  icon: {
    minWidth: theme.spacing.regular * 1.5,
    fontSize: theme.font.fontSize.extraSmall,
  },
}));

interface ConfigAddInsProps {
  className?: string;
}

export const ConfigAddIns: React.FC<ConfigAddInsProps> = (props) => {
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //   const { id } = useParams();
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [projectExtensionConfiguration, setProjectExtensionConfiguration] =
    useState<ProjectExtensionConfiguration[]>([]);
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [
    fetchProjectExtensionConfiguration,
    setfetchProjectExtensionConfiguration,
  ] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchProjectExtensionConfiguration = async () => {
      setfetchProjectExtensionConfiguration(true);
      const { data } = await apiGetAllProjectExtensionConfiguration();
      setProjectExtensionConfiguration(data);
      setfetchProjectExtensionConfiguration(false);
    };
    fetchProjectExtensionConfiguration();
  }, [setfetchProjectExtensionConfiguration]);

  const projectExtensionConfigurationChanged = async () => {
    setfetchProjectExtensionConfiguration(true);
    const { data } = await apiGetAllProjectExtensionConfiguration();
    setProjectExtensionConfiguration(data);
    setfetchProjectExtensionConfiguration(false);
  };

  //#endregion

  const ProjectExtensionListItem: React.FC<{
    index: number;
    key: string;
    isScrolling: boolean;
    isVisible: boolean;
  }> = ({ index }) => {
    return (
      <div className={classes.listItem}>
        <Flex.Row
          className={classes.item}
          childrenGap={theme.spacing.extraSmall}
          alignItems="center"
        >
          <SvgIcon
            width={40}
            height={40}
            path={getSvgIconPathByType(
              projectExtensionConfiguration[index].extensionType
            )}
          ></SvgIcon>
          <h2 style={{ marginTop: '5px' }}>
            {projectExtensionConfiguration[index].extensionType
              .charAt(0)
              .toUpperCase() +
              projectExtensionConfiguration[index].extensionType.slice(1)}
          </h2>
        </Flex.Row>
      </div>
    );
  };

  return (
    <>
      <Flex.Column flex={1} className={classes.root}>
        <Flex.Row childrenGap={theme.spacing.regular} marginBottom={16}>
          <ActionBar>
            <Button
              type="primary"
              onClick={() => {
                setOpenNew(true);
              }}
            >
              {t('settings:addInsConfig.newConfig')}
            </Button>
          </ActionBar>
        </Flex.Row>
        <Flex.Row className={classes.root}>
          <ListMenuArea
            // sort by name
            menuItems={projectExtensionConfiguration?.sort((a, b) =>
              a.extensionType.localeCompare(b.extensionType)
            )}
            menuItemIdKey="projectExtensionConfigurationId"
            menuItemEmptyString={t('settings:addInsConfig.emptyText')}
            listItem={ProjectExtensionListItem}
            pageNode={
              <JiraConfigurationForm
                projectExtensionConfigurationChanged={
                  projectExtensionConfigurationChanged
                }
              />
            }
            isLoading={fetchProjectExtensionConfiguration}
          />
        </Flex.Row>
      </Flex.Column>
      <Drawer
        title={t('settings:jiraConfig.newJiraConfig')}
        visible={openNew}
        closable={true}
        onClose={() => {
          setOpenNew(false);
        }}
      >
        <JiraConfigurationCreate
          projectExtensionConfigurationChanged={
            projectExtensionConfigurationChanged
          }
        />
      </Drawer>
    </>
  );
};

export default ConfigAddIns;
