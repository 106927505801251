import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Drawer, Modal, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { makePrioStyles } from '../../../theme/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonthlyClose } from '../../../models/TimeKeeping';
import moment from 'moment';
import Flex from '../../../components/Flex';
import MonthlyCloseTimekeepingDaysTable from './MonthlyCloseTimekeepingDaysTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getContact,
  getUserMe,
} from '../../../apps/main/rootReducer';
import {
  apiConfirmMonthlyCloseMe,
  apiFetchEditableMonthlyClose,
  apiFetchMonthlyCloseMeById,
} from '../api';
import TimeKeepingDrawerContent from './TimeKeepingDrawerContent';
import {
  DateTimeString,
  MonthlyCloseId,
  TimeKeepingDayState,
} from '../../../models/Types';
import { fetchSingleMonthlyCloseMe } from '../actions';
import { distinct } from '../../../util';
import WorkingHoursCircularProgressChart from '../../timeAndLeaveManagement/components/WorkingHoursCircularProgressChart';
import TimeRecordHoursCircularProgressChart from '../../timeAndLeaveManagement/components/TimeRecordHoursCircularProgressChart';
import { Contact } from '../../../models/Contact';
import { debouncedFetchOfficeHolidaysMe } from '../../absences/actions';
import { fetchUserMe } from '../../users/actions';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    '& .ant-drawer-body': {
      display: 'flex',
      flexDirection: 'column',
    },
    '& .ant-drawer-body > div:nth-child(n):not(:last-child):not(:nth-child(2))':
      {
        marginBottom: theme.old.spacing.defaultPadding,
      },
    '& .ant-typography.ant-typography-secondary': {
      whiteSpace: 'nowrap',
      fontSize: theme.old.typography.fontSize.label,
    },
  },
  details: {
    padding: theme.old.spacing.defaultPadding,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  warningInfo: {
    backgroundColor: `${theme.old.palette.chromaticPalette.yellow}20`,
    padding: theme.old.spacing.defaultPadding,
  },
  timeKeepingDrawerContent: {
    height: '100%',
    overflow: 'hidden',
  },
}));

interface MonthlyCloseConfirmDrawerProps {
  className?: string;
  monthlyClose: MonthlyClose;
  visible: boolean;
  onClose?: () => void;
  enableEditableTimeKeepingDays?: boolean;
  isClosedMonth?: boolean;
  isMonthClosable?: boolean;
}

export const MonthlyCloseConfirmDrawer: React.FC<MonthlyCloseConfirmDrawerProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const {
      className,
      visible,
      monthlyClose,
      enableEditableTimeKeepingDays,
      onClose,
      isClosedMonth,
      isMonthClosable,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme<PrioTheme>();

    const dispatch = useDispatch();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const userMe = useSelector(getUserMe);
    const contact = useSelector<RootReducerState, Contact>((state) =>
      getContact(state, userMe?.id)
    );

    const hasOpenTimeKeepingDays = monthlyClose?.timeKeepingDays?.some(
      (timeKeepingDay) =>
        (
          [
            'approvalRequested',
            'notApproved',
            'notOk',
            'recording',
          ] as TimeKeepingDayState[]
        ).includes(timeKeepingDay.state)
    );

    const [nextToClosableMonthlyClose, setNextToCloseMonthlyClose] =
      useState<MonthlyClose>(null);

    const [
      isFetchingNextToCloseMonthlyClose,
      setIsFetchingNextToCloseMonthlyClose,
    ] = useState<boolean>(false);

    const isClosable = useMemo(() => {
      if (monthlyClose && nextToClosableMonthlyClose) {
        const { month: currentMonth } = monthlyClose;
        const { month: nextToCloseMonth } = nextToClosableMonthlyClose;
        return moment(currentMonth).isSame(nextToCloseMonth, 'month');
      }
      return true;
    }, [nextToClosableMonthlyClose, monthlyClose]);

    const [subDrawerVisible, setSubDrawerVisible] = useState<boolean>(false);

    const [updatedMonthlyCloseIds, setUpdatedMonthlyCloseIds] = useState<
      MonthlyCloseId[]
    >([]);

    const [subDrawerInitialDay, setSubDrawerInitialDay] =
      useState<DateTimeString>(null);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOnConfirmMonthlyClose = async () => {
      Modal.confirm({
        title: t('timeKeeping:monthlyCloseConfirmationDrawer.modal.title', {
          month: moment(monthlyClose.month).format('MMMM YYYY'),
        }),
        content: t('timeKeeping:monthlyCloseConfirmationDrawer.modal.content'),
        okText: t('timeKeeping:monthlyCloseConfirmationDrawer.modal.okText'),
        cancelText: t(
          'timeKeeping:monthlyCloseConfirmationDrawer.modal.cancelText'
        ),
        maskClosable: true,
        onOk: async () => {
          const { data } = await apiConfirmMonthlyCloseMe({
            monthlyCloseId: monthlyClose.monthlyCloseId,
            userTime: moment().toISOString(true).split('.')[0],
          });
          if (data) {
            dispatch(fetchSingleMonthlyCloseMe(monthlyClose.monthlyCloseId));
            dispatch(fetchUserMe());
            onClose();
          }
        },
        onCancel: () => {},
      });
    };

    const handleOnCloseSubDrawer = () => {
      updatedMonthlyCloseIds.forEach((id) => {
        dispatch(fetchSingleMonthlyCloseMe(id));
      });
      setUpdatedMonthlyCloseIds([]);
      setSubDrawerVisible(false);
    };

    const addMonthlyCloseId = (id: MonthlyCloseId) => {
      setUpdatedMonthlyCloseIds(distinct([...updatedMonthlyCloseIds, id]));
    };

    const handleOnRowClick = (day: DateTimeString) => {
      setSubDrawerInitialDay(day);
      setSubDrawerVisible(true);
    };
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      if (userMe?.id && visible) {
        const fetchClosableMonthlyClose = async () => {
          setIsFetchingNextToCloseMonthlyClose(true);
          const { data } = await apiFetchEditableMonthlyClose(userMe.id);

          if (data) {
            const { nextToCloseMonthlyCloseId } = data;

            const { data: nextToCloseMonthlyClose } =
              await apiFetchMonthlyCloseMeById(nextToCloseMonthlyCloseId);

            if (nextToCloseMonthlyClose) {
              setNextToCloseMonthlyClose(nextToCloseMonthlyClose);
            }
          }
          setIsFetchingNextToCloseMonthlyClose(false);
        };
        fetchClosableMonthlyClose();
      }
    }, [userMe, visible]);

    useEffect(() => {
      if (contact?.officeId && monthlyClose?.month) {
        const start = moment(monthlyClose?.month)
          .subtract(1, 'month')
          .startOf('month')
          .toISOString(true)
          .split('T')[0];
        const end = moment(monthlyClose?.month)
          .add(1, 'month')
          .endOf('month')
          .toISOString(true)
          .split('T')[0];
        dispatch(debouncedFetchOfficeHolidaysMe(contact?.officeId, start, end));
      }
    }, [contact, monthlyClose?.month, dispatch]);
    //#endregion

    return (
      <Drawer
        className={classNames(classes.root, className)}
        width={theme.old.components.drawerWidth}
        closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
        visible={visible}
        onClose={onClose}
        push={{ distance: 400 }}
        closable
        destroyOnClose
      >
        <Typography.Title level={2}>
          {t(
            `timeKeeping:monthlyCloseConfirmationDrawer.${
              isMonthClosable ? 'title' : 'details'
            }`,
            {
              month: moment(monthlyClose?.month).format('MMMM YYYY'),
            }
          )}
        </Typography.Title>
        <Flex.Column
          className={classes.details}
          childrenGap={theme.old.spacing.defaultPadding}
          marginBottom={theme.old.spacing.baseSpacing}
        >
          <Flex.Row childrenGap={theme.old.spacing.defaultPadding}>
            <Flex.Column flex={1} alignItems="space-around">
              <WorkingHoursCircularProgressChart monthlyClose={monthlyClose} />
            </Flex.Column>
            <Flex.Column flex={1}>
              <TimeRecordHoursCircularProgressChart
                monthlyClose={monthlyClose}
              />
            </Flex.Column>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.defaultPadding}>
            <Flex.Column flex={1}>
              <Typography.Text type="secondary">
                {t(
                  'timeKeeping:monthlyCloseConfirmationDrawer.labels.overtimeHoursChange'
                )}
              </Typography.Text>
              <Typography.Text>{`${monthlyClose?.overtimeHoursChange} h`}</Typography.Text>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Typography.Text type="secondary">
                {t(
                  'timeKeeping:monthlyCloseConfirmationDrawer.labels.timeKeepingDays'
                )}
              </Typography.Text>
              <Typography.Text>
                {monthlyClose?.timeKeepingDays?.length}
              </Typography.Text>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Typography.Text type="secondary">
                {t(
                  'timeKeeping:monthlyCloseConfirmationDrawer.labels.shouldBeWorkingDays'
                )}
              </Typography.Text>
              <Typography.Text>
                {monthlyClose?.totalWorkingDays}
              </Typography.Text>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Typography.Text type="secondary">
                {t(
                  'timeKeeping:monthlyCloseConfirmationDrawer.labels.absenceDays'
                )}
              </Typography.Text>
              <Typography.Text>{monthlyClose?.absenceDays}</Typography.Text>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Typography.Text type="secondary">
                {t(
                  'timeKeeping:monthlyCloseConfirmationDrawer.labels.holidays'
                )}
              </Typography.Text>
              <Typography.Text>{monthlyClose?.holidays}</Typography.Text>
            </Flex.Column>
          </Flex.Row>
        </Flex.Column>
        <Flex.Item flex={1}>
          <MonthlyCloseTimekeepingDaysTable
            monthlyClose={monthlyClose}
            onRowClick={
              enableEditableTimeKeepingDays ? handleOnRowClick : undefined
            }
          />
        </Flex.Item>
        {(hasOpenTimeKeepingDays || !isClosable) && !isClosedMonth && (
          <Flex.Column
            childrenGap={theme.old.spacing.defaultPadding}
            className={classes.warningInfo}
          >
            {hasOpenTimeKeepingDays && (
              <Flex.Row
                alignItems="baseline"
                childrenGap={theme.old.spacing.baseSpacing}
              >
                <Flex.Item>
                  <FontAwesomeIcon
                    icon={['fal', 'exclamation-circle']}
                    color={theme.old.palette.chromaticPalette.yellow}
                  />
                </Flex.Item>
                <Flex.Item>
                  {t(
                    'timeKeeping:monthlyCloseConfirmationDrawer.warningInfo.openTimeKeepingDays',
                    {
                      month: moment(monthlyClose?.month).format('MMMM YYYY'),
                    }
                  )}
                </Flex.Item>
              </Flex.Row>
            )}
            {!isClosable && (
              <Flex.Row
                alignItems="baseline"
                childrenGap={theme.old.spacing.baseSpacing}
              >
                <Flex.Item>
                  <FontAwesomeIcon
                    icon={['fal', 'exclamation-circle']}
                    color={theme.old.palette.chromaticPalette.yellow}
                  />
                </Flex.Item>
                <Flex.Item>
                  {moment(monthlyClose?.month)
                    .subtract(1, 'months')
                    .isSame(moment(nextToClosableMonthlyClose?.month), 'month')
                    ? t(
                        'timeKeeping:monthlyCloseConfirmationDrawer.warningInfo.previousMonth',
                        {
                          month: moment(monthlyClose?.month)
                            .subtract(1, 'months')
                            .format('MMMM YYYY'),
                        }
                      )
                    : t(
                        'timeKeeping:monthlyCloseConfirmationDrawer.warningInfo.previousMonthTillNextMonthToClose',
                        {
                          month: moment(monthlyClose?.month)
                            .subtract(1, 'months')
                            .format('MMMM YYYY'),
                          nextMonthToClose: moment(
                            nextToClosableMonthlyClose?.month
                          ).format('MMMM YYYY'),
                        }
                      )}
                </Flex.Item>
              </Flex.Row>
            )}
          </Flex.Column>
        )}
        <Flex.Row justifyContent="flex-end">
          <Button
            onClick={handleOnConfirmMonthlyClose}
            loading={isFetchingNextToCloseMonthlyClose}
            disabled={
              !monthlyClose ||
              monthlyClose?.employeeConfirmation ||
              hasOpenTimeKeepingDays ||
              isFetchingNextToCloseMonthlyClose ||
              !isClosable
            }
          >
            {t('timeKeeping:monthlyCloseConfirmationDrawer.confirmButton')}
          </Button>
        </Flex.Row>
        {enableEditableTimeKeepingDays && (
          <Drawer
            width={'100%'}
            closeIcon={<FontAwesomeIcon icon={['fal', 'times']} />}
            visible={subDrawerVisible}
            onClose={handleOnCloseSubDrawer}
            closable
            destroyOnClose
          >
            <Flex.Column height="100%" overflow="hidden">
              <Typography.Title level={2}>
                {t('timeKeeping:timeKeeping')}
              </Typography.Title>
              <Flex.Item flex={1}>
                <TimeKeepingDrawerContent
                  className={classes.timeKeepingDrawerContent}
                  monthlyCloseChange={addMonthlyCloseId}
                  initialDay={subDrawerInitialDay}
                  isVisible={subDrawerVisible}
                />
              </Flex.Item>
            </Flex.Column>
          </Drawer>
        )}
      </Drawer>
    );
  });

export default MonthlyCloseConfirmDrawer;
