import React, { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { makePrioStyles } from '../../../../theme/utils';
import {
  MonthlyClose,
  MonthlyCloseCalculatedData,
  MonthlyCloseSearchResultItem,
} from '../../../../models/TimeKeeping';
import ContactText from '../../../contacts/components/ContactText';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VirtualTable } from '@prio365/prio365-react-library';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import FilterResultNoItemsScreen from '../../../../components/Filter/FilterResultNoItemsScreen';
import { OptimisticWriteFunction } from '../../../../components/Filter/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
    '& svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa': {
      width: '14px',
    },
  },
  cell: {
    padding: theme.spacing.small,
    display: 'flex',
    alignItems: 'center',
  },
  cellCentered: {
    justifyContent: 'center',
  },
  row: {
    cursor: 'pointer',
    '& button': {
      visibility: 'hidden',
    },
    '&:hover button': {
      visibility: 'visible',
    },
  },
}));

export interface HRMonthlyClosePageTableRef {
  getSelectedMonthlyCloses: () => MonthlyCloseSearchResultItem[];
  optimisticWrite: OptimisticWriteFunction<
    MonthlyClose,
    MonthlyCloseCalculatedData
  >;
}

interface HRMonthlyClosePageTableProps {
  className?: string;
  tableId: string;
  onRowClick?: (entry: MonthlyCloseSearchResultItem) => void;
  onRowSelectionChange?: (
    selectedSearchItems: MonthlyCloseSearchResultItem[]
  ) => void;
  selectedSearchItems: MonthlyCloseSearchResultItem[];
}

export const HRMonthlyClosePageTable = forwardRef(
  (
    props: HRMonthlyClosePageTableProps,
    ref: React.Ref<HRMonthlyClosePageTableRef>
  ) => {
    //#region ------------------------------ Defaults
    const {
      className,
      tableId,
      selectedSearchItems,
      onRowClick,
      onRowSelectionChange,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { getContactById } = useContactsContext();

    const { data, isLoading, optimisticWrite } = useFilterContext<
      MonthlyClose,
      MonthlyCloseCalculatedData
    >();
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const onSelectionChange = (items: MonthlyCloseSearchResultItem[]) => {
      if (onRowSelectionChange) {
        onRowSelectionChange(items);
      }
    };

    const handleOnRow: (
      item: MonthlyCloseSearchResultItem
    ) => VirtualListItemOnRowProps = useCallback(
      (item) => {
        return {
          onClick: (e) => {
            if (onRowClick) {
              onRowClick(item);
            }
          },
          className: classes.row,
        };
      },
      [classes, onRowClick]
    );
    //#endregion

    //#region ------------------------------ Columns
    const columns: Column<MonthlyCloseSearchResultItem>[] = [
      {
        Cell: ({
          originalData: {
            data: { employeeConfirmation },
          },
        }) => {
          return (
            employeeConfirmation && (
              <div
                title={t('hr:timeAndLeaveManagement.monthlyCloseTable.closed')}
              >
                <FontAwesomeIcon icon={['fal', 'lock']} />
              </div>
            )
          );
        },
        title: () => <div></div>,
        width: 3,
        minWidth: 34,
        id: 'status',
        accessor: 'data.employeeConfirmation',
        className: classNames(classes.cell, classes.cellCentered),
        sortingFn: (rowA, rowB) => {
          const monthlyCloseA = !!rowA.data.employeeConfirmation;
          const monthlyCloseB = !!rowB.data.employeeConfirmation;
          if (monthlyCloseA === monthlyCloseB) return 0;
          if (monthlyCloseA) return -1;
          return 1;
        },
      },
      {
        id: 'employeeId',
        width: 20,
        title: t('hr:timeAndLeaveManagement.monthlyCloseTable.employeeId'),
        accessor: 'data.employeeId',
        sortingFn: (rowA, rowB) => {
          const a = getContactById(rowA.data.employeeId.toLowerCase());
          const b = getContactById(rowB.data.employeeId.toLowerCase());
          const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
          if (lastNameCompare !== 0) return lastNameCompare;
          return a?.firstName?.localeCompare(b?.firstName);
        },
        Cell: ({
          originalData: {
            data: { employeeId },
          },
        }) => <ContactText contactId={employeeId.toLowerCase()} hasTitle />,
        className: classes.cell,
      },
      {
        Cell: ({
          originalData: {
            data: { accumulatedOvertimeHours },
          },
        }) => {
          return accumulatedOvertimeHours;
        },
        title: t(
          'hr:timeAndLeaveManagement.monthlyCloseTable.accumulatedOvertimeHours'
        ),
        width: 13,
        id: 'accumulatedOvertimeHours',
        accessor: 'data.accumulatedOvertimeHours',
        className: classNames(classes.cell, classes.cellCentered),
        cellTitle: (monthlyClose) => {
          return `${monthlyClose.data.accumulatedOvertimeHours}`;
        },
      },
      {
        Cell: ({
          originalData: {
            data: { overtimeHoursChange },
          },
        }) => {
          return overtimeHoursChange;
        },
        title: t(
          'hr:timeAndLeaveManagement.monthlyCloseTable.overtimeHoursChange'
        ),
        width: 15,
        id: 'overtimeHoursChange',
        accessor: 'data.overtimeHoursChange',
        className: classNames(classes.cell, classes.cellCentered),
        cellTitle: (monthlyClose) => {
          return `${monthlyClose.data.overtimeHoursChange}`;
        },
      },
      {
        Cell: ({
          originalData: {
            data: { compensationPaymentHours },
          },
        }) => {
          return compensationPaymentHours;
        },
        title: t(
          'hr:timeAndLeaveManagement.monthlyCloseTable.compensationPaymentHours'
        ),
        width: 10,
        id: 'compensationPaymentHours',
        accessor: 'data.compensationPaymentHours',
        className: classNames(classes.cell, classes.cellCentered),
        cellTitle: (monthlyClose) => {
          return `${monthlyClose.data.compensationPaymentHours}`;
        },
      },
      {
        Cell: ({
          originalData: {
            data: { hoursPerWeek },
          },
        }) => {
          return hoursPerWeek;
        },
        title: t('hr:timeAndLeaveManagement.monthlyCloseTable.hoursPerWeek'),
        width: 9,
        id: 'hoursPerWeek',
        accessor: 'data.hoursPerWeek',
        className: classNames(classes.cell, classes.cellCentered),
        cellTitle: (monthlyClose) => {
          return `${monthlyClose.data.hoursPerWeek}`;
        },
      },
      {
        Cell: ({
          originalData: {
            data: { actualWorkHours, expectedWorkHours },
          },
        }) => {
          return `${actualWorkHours} / ${expectedWorkHours}`;
        },
        title: t(
          'hr:timeAndLeaveManagement.monthlyCloseTable.actualExpectedWorkHours'
        ),
        width: 9,
        id: 'actualWorkHours',
        accessor: 'data.actualWorkHours',
        className: classNames(classes.cell, classes.cellCentered),
        cellTitle: (monthlyClose) => {
          return `${monthlyClose.data.actualWorkHours} / ${monthlyClose.data.expectedWorkHours}`;
        },
      },
      {
        Cell: ({
          originalData: {
            data: { totalWorkingDays },
          },
        }) => {
          return totalWorkingDays;
        },
        title: t(
          'hr:timeAndLeaveManagement.monthlyCloseTable.totalWorkingDays'
        ),
        width: 10,
        id: 'totalWorkingDays',
        accessor: 'data.totalWorkingDays',
        className: classNames(classes.cell, classes.cellCentered),
        cellTitle: (monthlyClose) => {
          return `${monthlyClose.data.totalWorkingDays}`;
        },
      },
      {
        Cell: ({
          originalData: {
            data: { absenceDays },
          },
        }) => {
          return <Flex.Row justifyContent="center">{absenceDays}</Flex.Row>;
        },
        title: t('hr:timeAndLeaveManagement.monthlyCloseTable.absenceDays'),
        width: 10,
        id: 'absenceDays',
        accessor: 'data.absenceDays',
        className: classNames(classes.cell, classes.cellCentered),
        cellTitle: (monthlyClose) => {
          return `${monthlyClose.data.absenceDays}`;
        },
      },
    ];
    //#endregion

    //#region ------------------------------ Effects
    useImperativeHandle(ref, () => ({
      getSelectedMonthlyCloses: () => {
        return selectedSearchItems;
      },
      optimisticWrite,
    }));
    //#endregion

    return (
      <VirtualTable<MonthlyCloseSearchResultItem>
        id={tableId}
        className={classNames(classes.root, className)}
        columns={columns}
        data={data?.items || []}
        selectedItems={selectedSearchItems}
        resizable="relative"
        onRow={handleOnRow}
        onSelectionChange={onSelectionChange}
        onCheckEquality={(a, b) =>
          a.data.monthlyCloseId === b.data.monthlyCloseId
        }
        noItemsScreen={<FilterResultNoItemsScreen />}
        loading={
          isLoading && {
            type: 'noItems',
          }
        }
        rowsAreSelectable
      />
    );
  }
);

export default HRMonthlyClosePageTable;
