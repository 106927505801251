import React from 'react';
import classNames from 'classnames';
import { Dropdown, Menu, Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import {
  formatNumber,
  fullDateFormatFormatString,
  isTemporaryId,
} from '../../../util';
import { MENU_BUTTON_SIZE } from '../../../constants';
import { useDispatch } from 'react-redux';
import { setAbsenceDrawerState } from '../actions/drawer';
import {
  deleteAbsenceProposalMe,
  fetchAbsencesMe,
  revokeAbsenceProposal,
} from '../../absences/actions';
import VirtualTable2, {
  VColumn,
} from '../../../components/VirtualTable/VirtualTable2';
import Flex from '../../../components/Flex';
import { Classes } from '@prio365/prio365-react-library/lib/ThemeProvider/types';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    '& .ant-table-cell > a': {
      color: theme.old.typography.colors.base,
    },
    '& .ant-table-thead > tr > th': {
      fontSize: theme.old.typography.fontSize.small,
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  row: {
    cursor: 'pointer',
    '& > td:nth-child(2) > button': {
      visibility: 'hidden',
    },
    '&:hover > td:nth-child(2) > button': {
      visibility: 'visible',
    },
    '&::before': {
      content: '""',
      width: 4,
      height: '100%',
      marginRight: theme.old.spacing.defaultPadding,
    },
  },
  menuColum: {
    padding: '0!important',
    width: MENU_BUTTON_SIZE,
  },
  menuButton: {
    padding: '0!important',
    height: MENU_BUTTON_SIZE,
  },
  acceptButton: {
    '&.ant-btn:not(.ant-btn-primary) > .svg-inline--fa': {
      color: theme.old.palette.chromaticPalette.green,
    },
  },
  declineButton: {
    '&.ant-btn:not(.ant-btn-primary) > .svg-inline--fa': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  stateGreen: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.green,
    },
  },
  stateYellow: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.yellow,
    },
  },
  stateRed: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
  },
  stateGrey: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.grey,
    },
  },
  cellWithPaddingRight: {
    paddingRight: theme.old.spacing.defaultPadding,
  },
  formattedTimeRange: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  noItemScreen: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.muted,
    marginTop: theme.spacing.regular,
  },
}));

const mapAbsenceStateToClassName = (
  absenceProposal: AbsenceProposal,
  classes: Classes
) => {
  const { absenceType, absenceState } = absenceProposal;
  switch (absenceType) {
    case 'annualLeavePlanning':
      return classes.stateGrey;
    default:
      switch (absenceState) {
        case 'accepted':
          return classes.stateGreen;
        case 'declined':
          return classes.stateRed;
        case 'planned':
          return classes.stateGrey;
        case 'requested':
          return classes.stateYellow;
        case 'revokeAccepted':
          return classes.stateRed;
        case 'revokeDeclined':
          return classes.stateGreen;
        case 'revokeRequested':
          return classes.stateYellow;
        default:
          return classes.stateGrey;
      }
  }
};

interface AbsenceProposalTableProps {
  className?: string;
  tableId: string;
  onRowClick?: (entry: AbsenceProposal) => void;
  absenceProposals: AbsenceProposal[];
  hasMenuButton?: boolean;
}

export const AbsenceProposalTable: React.FC<AbsenceProposalTableProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();

  const { className, tableId, onRowClick, absenceProposals, hasMenuButton } =
    props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const onRevoke = async (entry: AbsenceProposal) => {
    Modal.confirm({
      icon: null,
      title: t('absences:revokeAbsence.confirmationDialog.title'),
      content: t('absences:revokeAbsence.confirmationDialog.content', {
        period:
          entry.from === entry.to
            ? fullDateFormatFormatString(entry.from)
            : `${fullDateFormatFormatString(entry.from)} ${t(
                'common:to'
              )} ${fullDateFormatFormatString(entry.to)}`,
        absenceType: t(`absences:types.${entry.absenceType}`),
      }),
      okText: t('absences:revokeAbsence.confirmationDialog.okText'),
      cancelText: t('absences:revokeAbsence.confirmationDialog.cancelText'),
      onOk() {
        dispatch(revokeAbsenceProposal(entry.absenceProposalId, entry));
        dispatch(fetchAbsencesMe());
      },
      onCancel() {},
    });
  };

  const onDelete = async (entry: AbsenceProposal) => {
    Modal.confirm({
      icon: null,
      title: t('absences:deleteAbsence.confirmationDialog.title'),
      content: t('absences:deleteAbsence.confirmationDialog.content', {
        period:
          entry.from === entry.to
            ? fullDateFormatFormatString(entry.from)
            : `${fullDateFormatFormatString(entry.from)} ${t(
                'common:to'
              )} ${fullDateFormatFormatString(entry.to)}`,
        absenceType: t(`absences:types.${entry.absenceType}`),
      }),
      okText: t('absences:deleteAbsence.confirmationDialog.okText'),
      cancelText: t('absences:deleteAbsence.confirmationDialog.cancelText'),
      onOk() {
        dispatch(deleteAbsenceProposalMe(entry.absenceProposalId, entry));
      },
      onCancel() {},
    });
  };

  const onEdit = async (entry: AbsenceProposal) => {
    onRowClick(entry);
    dispatch(
      setAbsenceDrawerState({
        selectedAbsenceProposal: entry,
        view: 'edit',
      })
    );
  };
  //#endregion

  //#region ------------------------------ Components
  const menu = (entry: AbsenceProposal) => {
    const disableEditButton = !(
      entry.absenceState === 'requested' || entry.absenceState === 'planned'
    );

    const disableDeleteButoon = !(
      entry.absenceState === 'requested' || entry.absenceState === 'planned'
    );
    return (
      <Menu
        onClick={(e) => {
          e.domEvent.stopPropagation();
        }}
      >
        <Menu.Item
          disabled={disableEditButton}
          onClick={(e) => {
            e.domEvent.preventDefault();
            onEdit(entry);
          }}
        >
          {t('absences:tableMenu.edit')}
        </Menu.Item>
        <Menu.Item
          disabled={!(entry.absenceState === 'accepted')}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            onRevoke(entry);
          }}
        >
          {t('absences:tableMenu.revoke')}
        </Menu.Item>
        <Menu.Item
          disabled={disableDeleteButoon}
          onClick={(e) => {
            e.domEvent.stopPropagation();
            onDelete(entry);
          }}
        >
          {t('absences:tableMenu.delete')}
        </Menu.Item>
      </Menu>
    );
  };
  //#endregion

  //#region ------------------------------ Columns
  const columns: VColumn<AbsenceProposal>[] = [
    {
      Cell: (cellProps) => {
        const record = cellProps?.row?.original;
        const formattedTimeRange =
          record.from === record.to
            ? fullDateFormatFormatString(record.from)
            : `${fullDateFormatFormatString(record.from)} ${t(
                'common:to'
              )} ${fullDateFormatFormatString(record.to)}`;
        return (
          <div
            title={formattedTimeRange}
            className={classes.formattedTimeRange}
          >
            {formattedTimeRange}
          </div>
        );
      },
      width: 50,
      id: 'date',
      accessor: 'from',
      className: classes.cellWithPaddingRight,
    },
    {
      Cell: (cellProps) => {
        const record = cellProps?.row?.original;
        return t(`absences:types.${record.absenceType}`);
      },
      width: 30,
      id: 'absenceType',
      accessor: 'absenceType',
      className: classes.cellWithPaddingRight,
    },
    {
      Cell: (cellProps) => {
        const record = cellProps?.row?.original;
        const absenceAmount =
          record.absenceType === 'annualLeave'
            ? `${formatNumber(record.absentWorkdays)} ${t('common:days')}`
            : '';
        return (
          <Flex.Row
            alignItems="center"
            childrenGap={theme.old.spacing.defaultPadding}
          >
            <Flex.Item flex={1}>{absenceAmount}</Flex.Item>
            {hasMenuButton && (
              <Dropdown
                overlay={menu(record)}
                trigger={['click']}
                placement="bottomRight"
                disabled={isTemporaryId(record.absenceProposalId)}
              >
                <Button
                  iconProp={['fal', 'ellipsis-v']}
                  type="default"
                  className={classes.menuButton}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </Dropdown>
            )}
          </Flex.Row>
        );
      },
      width: 20,
      id: 'absentWorkdays',
      accessor: 'absentWorkdays',
    },
  ];
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <VirtualTable2
      id={tableId}
      className={classNames(classes.root, className)}
      columns={columns}
      data={absenceProposals}
      onRow={{
        triggerFunctions: (record: AbsenceProposal) => ({
          onClick: onRowClick
            ? (event) => {
                event.stopPropagation();
                event.preventDefault();
                onRowClick(record);
              }
            : null,
        }),
      }}
      classNameTableRow={(record) =>
        classNames(classes.row, mapAbsenceStateToClassName(record, classes))
      }
      noHeaders
      noItemsScreen={
        <div className={classes.noItemScreen}>
          {t('hr:employeeAbsenceOverview.noData')}
        </div>
      }
    />
  );
};

export default AbsenceProposalTable;
