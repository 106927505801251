import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { Checkbox, Form, Layout } from 'antd';
import { Button, Input, useTheme } from '@prio365/prio365-react-library';
import { colon } from '../../../util/forms';
import { PrioTheme } from '../../../theme/types';
import {
  UpdateProjectPhase,
  DefaultProjectPhase,
  ProjectPhase,
} from '../../../models/ProjectPhase';
import {
  apiGetProjectPhaseByProjectAndId,
  apiUpdateProjectPhases,
} from '../api';
import { Content, Footer } from 'antd/lib/layout/layout';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import DebouncedInputNumber from '../../../components/DebouncedInputField/DebouncedInputNumber';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomSingleDatePicker from '../../../components/CustomSingleDatePicker';
import moment, { Moment } from 'moment';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  rootLayout: {
    margin: 0,
  },
  editContent: {
    backgroundColor: theme.colors.application.background.default,
  },
  form: {
    padding: `${theme.spacing.regular}px ${theme.spacing.regular * 2}px`,
    height: '100%',
    overflow: 'auto',
  },
  contentFooter: {
    marginTop: `${theme.spacing.regular}px`,
    padding: `${theme.spacing.regular}px ${theme.spacing.regular * 2}px`,
    backgroundColor: theme.colors.application.background.default,
    textAlign: 'right',
  },
  fullWidth: {
    width: '100%',
  },
}));

interface ProjectPhaseEditProps {
  className?: string;
  projectPhaseChanged: (phaseId: string) => void;
}

export const ProjectPhaseEdit: React.FC<ProjectPhaseEditProps> = (props) => {
  //#region ------------------------------ Defaults
  const { projectPhaseChanged } = props;
  const { t } = useTranslation();
  const { id, projectId } = useParams();
  const theme = useTheme<PrioTheme>();
  const [dataForm] = Form.useForm<ProjectPhase>();
  const classes = useStyles();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  // issaving
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Moment>();
  const [endDate, setEndDate] = useState<Moment>();

  const [isEmpty, setIsEmpty] = useState<boolean>(
    id === '0' || id === undefined
  );
  const formatter: (
    value: number,
    info: { userTyping: boolean; input: string }
  ) => string = (value, info) => {
    let _value = value;
    if (!info.userTyping) {
      _value = Math.round(value * 4) / 4;
    }
    return `${_value}`.replace('.', ',');
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers

  //handle save
  const handleFinishData = async (newData: ProjectPhase) => {
    let theProjectPhase: ProjectPhase;
    //save phase
    setIsSaving(true);
    if (isEmpty) {
      // do nothing
    } else {
      let updateData = { ...(newData as UpdateProjectPhase) };
      updateData.projectPhaseId = id;
      updateData.from =
        updateData.from === '' ? '0001-01-01T00:00:00' : updateData.from;
      updateData.to =
        updateData.to === '' ? '0001-01-01T00:00:00' : updateData.to;
      const { data } = await apiUpdateProjectPhases(projectId, [updateData]);
      theProjectPhase = data[0];
    }
    if (projectPhaseChanged) {
      projectPhaseChanged(theProjectPhase.projectPhaseId);
    }
    setIsSaving(false);
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const getProjectPhaseByIdAndProject = async (
      projectId: string,
      projectPhaseId: string
    ) => {
      const { data } = await apiGetProjectPhaseByProjectAndId(
        projectId,
        projectPhaseId
      );
      if (data.from === '' || data.from === '0001-01-01T00:00:00') {
        data.from = '';
        setStartDate(null);
      } else {
        data.from = moment(data.from, moment.ISO_8601);

        setStartDate(data.from);
      }
      if (data.to === '' || data.to === '0001-01-01T00:00:00') {
        setEndDate(null);
        data.to = '';
      } else {
        data.to = moment(data.to, moment.ISO_8601);
        setEndDate(data.to);
      }
      dataForm.setFieldsValue(data);
    };
    if (id === '0' || id === undefined) {
      setIsEmpty(true);
      dataForm.setFieldsValue(DefaultProjectPhase);
      //set default values
    } else {
      setIsEmpty(false);
      getProjectPhaseByIdAndProject(projectId, id);
    }
  }, [id, projectId, dataForm]);
  //#endregion

  return (
    <Layout className={classes.rootLayout}>
      <Content className={classes.editContent}>
        {isEmpty ? (
          <h3>{t('projects:projectPhase.notFound')}</h3>
        ) : (
          <Form<ProjectPhase>
            form={dataForm}
            onFinish={handleFinishData}
            layout="vertical"
            className={classes.form}
          >
            <Flex.Row flex={1}>
              <Flex.Column flex={2} childrenGap={theme.spacing.small}>
                <Flex.Row childrenGap={theme.spacing.small} flex={1}>
                  <h3>{t('projects:projectPhase.edit')}</h3>
                </Flex.Row>
                <Flex.Row childrenGap={theme.spacing.small} flex={1}>
                  <Form.Item
                    name="isFavorite"
                    colon={colon}
                    valuePropName="checked"
                  >
                    <Checkbox disabled={isSaving}>
                      {t('projects:projectPhase.form.labels.isFavorite')}
                    </Checkbox>
                  </Form.Item>
                  <Form.Item
                    name="isActive"
                    colon={colon}
                    valuePropName="checked"
                  >
                    <Checkbox disabled={isSaving}>
                      {t('projects:projectPhase.form.labels.isActive')}
                    </Checkbox>
                  </Form.Item>
                </Flex.Row>
                <Form.Item
                  name="name"
                  label={t('projects:projectPhase.form.labels.name')}
                  colon={colon}
                >
                  <Input disabled={false} />
                </Form.Item>
                <Form.Item
                  name="description"
                  label={t('projects:projectPhase.form.labels.description')}
                  colon={colon}
                >
                  <Input disabled={false} />
                </Form.Item>
                <Form.Item
                  name="budget"
                  label={t('projects:projectPhase.form.labels.budget')}
                  colon={colon}
                >
                  <DebouncedInputNumber
                    formatter={formatter}
                    decimalSeparator=","
                    className={classes.fullWidth}
                    min={0}
                    addonAfter={<FontAwesomeIcon icon={['fal', 'euro']} />}
                  />
                </Form.Item>
                <Flex.Row childrenGap={theme.spacing.small} flex={1}>
                  <Form.Item
                    name="from"
                    label={t('projects:projectPhase.form.labels.from')}
                    colon={colon}
                    className={classes.fullWidth}
                  >
                    <CustomSingleDatePicker
                      id="edit_ProjectPhase_From_id"
                      anchorDirection={'ANCHOR_RIGHT'}
                      onChange={(date) => {
                        setStartDate(moment(date, moment.ISO_8601));
                      }}
                      small={true}
                      regular={false}
                      twoMonths={false}
                      withFullScreenPortal={false}
                      daySize={30}
                      hideKeyboardShortcutsPanel={true}
                      blockDatesBefore={endDate}
                      showDefaultInputIcon={true}
                      inputIconPosition={'after'}
                    ></CustomSingleDatePicker>
                  </Form.Item>
                  <Form.Item
                    name="to"
                    label={t('projects:projectPhase.form.labels.to')}
                    colon={colon}
                    className={classes.fullWidth}
                  >
                    <CustomSingleDatePicker
                      id="edit_ProjectPhase_To_id"
                      anchorDirection={'ANCHOR_RIGHT'}
                      onChange={(date) => {
                        setEndDate(moment(date, moment.ISO_8601));
                      }}
                      blockDatesAfter={startDate}
                      small={true}
                      regular={false}
                      twoMonths={false}
                      withFullScreenPortal={false}
                      daySize={30}
                      hideKeyboardShortcutsPanel={true}
                      showDefaultInputIcon={true}
                      inputIconPosition={'after'}
                    ></CustomSingleDatePicker>
                  </Form.Item>
                </Flex.Row>
                <Form.Item
                  name="additionalInformation"
                  label={t(
                    'projects:projectPhase.form.labels.additionalInformation'
                  )}
                  colon={colon}
                >
                  <Input disabled={false} />
                </Form.Item>
                <Form.Item
                  name="additionalInformation2"
                  label={t(
                    'projects:projectPhase.form.labels.additionalInformation2'
                  )}
                  colon={colon}
                >
                  <Input disabled={false} />
                </Form.Item>
                <Form.Item
                  name="additionalInformation3"
                  label={t(
                    'projects:projectPhase.form.labels.additionalInformation3'
                  )}
                  colon={colon}
                >
                  <Input disabled={false} />
                </Form.Item>
                <Form.Item
                  name="additionalInformation4"
                  label={t(
                    'projects:projectPhase.form.labels.additionalInformation4'
                  )}
                  colon={colon}
                >
                  <Input disabled={false} />
                </Form.Item>
                <Form.Item
                  hidden={true}
                  name="projectPhaseId"
                  label={t(
                    'projects:projectPhase.form.labels.additionalInformation4'
                  )}
                  colon={colon}
                ></Form.Item>
                <Form.Item
                  hidden={true}
                  name="projectId"
                  label={t(
                    'projects:projectPhase.form.labels.additionalInformation4'
                  )}
                  colon={colon}
                ></Form.Item>
              </Flex.Column>
            </Flex.Row>
          </Form>
        )}
      </Content>
      <Footer className={classes.contentFooter}>
        <Button
          type={'primary'}
          htmlType="button"
          onClick={() => {
            dataForm.submit();
          }}
          disabled={isSaving}
        >
          {t('common:save')}
        </Button>
      </Footer>
    </Layout>
  );
};

export default ProjectPhaseEdit;

/* 
      <Flex.Row flex={1}>
       
      </Flex.Row>*/
