import React, { useRef } from 'react';
import classNames from 'classnames';
import {} from 'antd';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import ActionBar from '../../../../components/ActionBar';
import FilterContextProvider from '../../../../components/Filter/FilterContextProvider';
import {
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../../components/Filter/FilterBar';
import ProjectAdminSettingsPageTable, {
  ProjectAdminSettingsPageTableRef,
} from './ProjectAdminSettingsPageTable';

const useStyles = makePrioStyles((theme) => ({
  root: { padding: 24, display: 'flex', flexDirection: 'column' },
}));

interface ProjectAdminSettingsProps {
  className?: string;
}

export const ProjectAdminSettings: React.FC<ProjectAdminSettingsProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { className } = props;
  const classes = useStyles();
  const tableRef = useRef<ProjectAdminSettingsPageTableRef>(null);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <ActionBar></ActionBar>
      <FilterContextProvider searchType="projects">
        <FilterBar />
        <ProjectAdminSettingsPageTable
          className={FILTER_DATA_LIST_CLASS_PREFIX}
          ref={tableRef}
          tableId={'pas122'}
        />
      </FilterContextProvider>
    </div>
  );
};

export default ProjectAdminSettings;
