import { combineReducers, Reducer } from 'redux';
import { Contact, ExternalContact } from '../../../models/Contact';
import { distinct } from '../../../util';
import {
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_COMMIT,
  CREATE_CONTACT_ROLLBACK,
  FETCH_CONTACTS_ME_REQUEST,
  FETCH_CONTACTS_ME_ROLLBACK,
  FETCH_CONTACTS_ME_COMMIT,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_ROLLBACK,
  ARCHIVE_CONTACT_REQUEST,
  ARCHIVE_CONTACT_ROLLBACK,
  UNARCHIVE_CONTACT_REQUEST,
  UNARCHIVE_CONTACT_ROLLBACK,
  UPDATE_CONTACT_COMMIT,
  UPDATE_INTERNAL_CONTACT_ROLLBACK,
  UPDATE_INTERNAL_CONTACT_COMMIT,
  ARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST,
  ARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK,
  UNARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST,
  UNARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK,
  WS_UPDATE_OR_ADD_CONTACT,
} from '../actions';
import {
  CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST,
  CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK,
} from '../../projects/actions';
import { CLEAR_PRIO_CACHE, CLEAR_PRIO_CONTACTS } from '../../../actions';
import moment from 'moment';
import { ContactId } from '../../../models/Types';
import { sortContactsHelper } from '../utils';

export interface ContactsState {
  byId: ContactsByIdState;
  ids: string[];
  redirects: any;
  meta: ContactsMeta;
  offset: OffsetState;
}

export interface ContactsByIdState {
  [contactId: string]: Contact;
}

const byId: Reducer<ContactsByIdState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_CONTACTS_ME_COMMIT: {
      const {
        payload: { values },
        meta: { offset },
      } = action;
      return (values as Array<Contact>).reduce(
        function (map, item) {
          map[item.contactId] = item;
          return map;
        },
        offset ? state : {}
      );
    }

    case CREATE_CONTACT_REQUEST: {
      const {
        payload: { contact },
        meta: { temporaryId },
      } = action;
      return {
        ...state,
        [temporaryId]: {
          contactId: temporaryId,
          ...contact,
        },
      };
    }
    case UPDATE_INTERNAL_CONTACT_COMMIT: {
      const {
        payload,
        meta: { contactId },
      } = action;
      return {
        ...state,
        [contactId]: {
          ...payload,
        },
      };
    }
    case UPDATE_INTERNAL_CONTACT_ROLLBACK: {
      const {
        rollbackContact,
        meta: { contactId },
      } = action;
      return {
        ...state,
        [contactId]: {
          ...rollbackContact,
        },
      };
    }

    case CREATE_CONTACT_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      // eslint-disable-next-line
      const { [temporaryId]: contact_, ...newState } = state;
      return newState;
    }

    case CREATE_CONTACT_COMMIT: {
      const {
        payload,
        meta: { temporaryId },
      } = action;
      const currentContact = state[temporaryId];
      if (!currentContact) return state;
      const { [temporaryId]: _, ...newState } = state;
      return {
        ...newState,
        [payload.contactId]: {
          ...payload,
        },
      };
    }

    case UPDATE_CONTACT_REQUEST: {
      const {
        payload: { updateRequest },
        meta: { contactId },
      } = action;
      const currentContact = state[contactId];
      return {
        ...state,
        [contactId]: {
          ...currentContact,
          ...updateRequest,
        },
      };
    }

    case UPDATE_CONTACT_COMMIT: {
      const {
        payload,
        meta: { contactId },
      } = action;
      return {
        ...state,
        [contactId]: payload,
      };
    }

    case UPDATE_CONTACT_ROLLBACK: {
      const {
        meta: { contactId },
        rollbackContact,
      } = action;
      return {
        ...state,
        [contactId]: rollbackContact,
      };
    }

    case ARCHIVE_CONTACT_REQUEST: {
      const {
        meta: { contactId },
      } = action;
      const currentContact = state[contactId];
      if (!currentContact) return state;
      return {
        ...state,
        [contactId]: {
          ...currentContact,
          isArchived: true,
        },
      };
    }

    case ARCHIVE_CONTACT_ROLLBACK: {
      const {
        meta: { contactId },
      } = action;
      const currentContact = state[contactId];
      if (!currentContact) return state;
      return {
        ...state,
        [contactId]: {
          ...currentContact,
          isArchived: false,
        },
      };
    }

    case UNARCHIVE_CONTACT_REQUEST: {
      const {
        meta: { contactId },
      } = action;
      const currentContact = state[contactId];
      if (!currentContact) return state;
      return {
        ...state,
        [contactId]: {
          ...currentContact,
          isArchived: false,
        },
      };
    }

    case UNARCHIVE_CONTACT_ROLLBACK: {
      const {
        meta: { contactId },
      } = action;
      const currentContact = state[contactId];
      if (!currentContact) return state;
      return {
        ...state,
        [contactId]: {
          ...currentContact,
          isArchived: true,
        },
      };
    }

    case ARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST: {
      const {
        meta: { contactId },
      } = action;
      const currentContact = state[contactId];
      if (!currentContact) return state;
      return {
        ...state,
        [contactId]: {
          ...currentContact,
          isArchived: true,
        },
      };
    }

    case ARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK: {
      const {
        meta: { contactId },
      } = action;
      const currentContact = state[contactId];
      if (!currentContact) return state;
      return {
        ...state,
        [contactId]: {
          ...currentContact,
          isArchived: false,
        },
      };
    }

    case UNARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST: {
      const {
        meta: { contactId },
      } = action;
      const currentContact = state[contactId];
      if (!currentContact) return state;
      return {
        ...state,
        [contactId]: {
          ...currentContact,
          isArchived: false,
        },
      };
    }

    case UNARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK: {
      const {
        meta: { contactId },
      } = action;
      const currentContact = state[contactId];
      if (!currentContact) return state;
      return {
        ...state,
        [contactId]: {
          ...currentContact,
          isArchived: true,
        },
      };
    }
    case CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        meta: { contact },
      } = action;
      if (!contact) return state;
      return {
        ...state,
        [contact.contactId]: {
          ...contact,
        },
      };
    }

    case CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { contactId },
      } = action;
      const { [contactId]: contact_, ...newState } = state;
      return newState;
    }

    case WS_UPDATE_OR_ADD_CONTACT: {
      const { contact } = action;
      return {
        ...state,
        [contact.contactId]: contact,
      };
    }

    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      return {};
    }
    default:
      return state;
  }
};

interface OffsetState {
  offset: string | null;
  lastSync?: string;
}

const offset: Reducer<OffsetState, any> = (
  state = { offset: null },
  action
) => {
  switch (action.type) {
    case FETCH_CONTACTS_ME_REQUEST: {
      const {
        meta: { offset },
      } = action;
      const today = moment().toISOString().slice(0, 23);
      return {
        offset: offset ? state.offset : null,
        lastSync: offset ? state.lastSync : today,
      };
    }
    case FETCH_CONTACTS_ME_COMMIT: {
      const {
        payload: { offset },
      } = action;
      return {
        ...state,
        offset: (offset as string) ?? null,
      };
    }
    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      const today = moment().toISOString().slice(0, 23);
      return {
        lastSync: today,
        offset: null,
      };
    }
    default:
      return state;
  }
};

const ids: Reducer<Array<string>, any> = (state = [], action) => {
  switch (action.type) {
    case FETCH_CONTACTS_ME_COMMIT: {
      const {
        payload: { values },
        meta: { offset },
      } = action;
      return distinct([
        ...(offset ? state : []),
        ...(values as Array<Contact>).map((item) => item.contactId),
      ]);
    }

    case CREATE_CONTACT_REQUEST: {
      const {
        meta: { temporaryId },
      } = action;
      if (state.includes(temporaryId)) return state;
      return [...state, temporaryId];
    }

    case CREATE_CONTACT_ROLLBACK: {
      const {
        meta: { temporaryId },
      } = action;
      return state.filter((id) => id !== temporaryId);
    }

    case CREATE_CONTACT_COMMIT: {
      const {
        payload: { contactId },
        meta: { temporaryId },
      } = action;
      if (!state.includes(temporaryId)) return state;
      return [...state.filter((id) => id !== temporaryId), contactId];
    }

    case CREATE_EXTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        meta: { contact },
      } = action;
      if (!contact) {
        return state;
      }
      if (state.includes(contact.contactId)) {
        return state;
      }
      return [...state, contact.contactId];
    }

    case CREATE_EXTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { contactId },
      } = action;
      return state.filter((id) => id !== contactId);
    }

    case UPDATE_CONTACT_REQUEST: {
      const {
        meta: { contactId },
      } = action;
      if (state.includes(contactId)) {
        return state;
      }
      return [...state, contactId];
    }

    case UPDATE_CONTACT_ROLLBACK: {
      const {
        meta: { contactId },
      } = action;
      if (state.includes(contactId)) {
        return state;
      }
      return state.filter((id) => contactId !== id);
    }

    case WS_UPDATE_OR_ADD_CONTACT: {
      const {
        contact: { contactId },
      } = action;
      if (state.includes(contactId)) {
        return state;
      }
      return [...state, contactId];
    }

    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      return [];
    }

    default:
      return state;
  }
};

const redirects: Reducer<any, any> = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CONTACT_COMMIT: {
      const {
        payload: { contactId },
        meta: { temporaryId },
      } = action;
      return {
        ...state,
        [temporaryId]: contactId,
      };
    }

    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      return {};
    }

    default:
      return state;
  }
};

interface ContactsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<ContactsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_CONTACTS_ME_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_CONTACTS_ME_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_CONTACTS_ME_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage: 'contacts:errorMessages.fetchError',
      };
    }

    case CLEAR_PRIO_CONTACTS:
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<ContactsState>({
  byId,
  ids,
  redirects,
  meta,
  offset,
});

export const getOffset: (state: ContactsState) => string | null = (state) =>
  state.offset.offset;

export const getLastSync: (state: ContactsState) => string = (state) =>
  state.offset.lastSync;

export const getAllContacts: (
  state: ContactsState,
  includeArchived?: boolean
) => Array<Contact> = (state, includeArchived = false) =>
  (state.ids ?? [])
    .map((id) => state.byId[id])
    .filter(
      (contact: Contact) =>
        !!contact && (contact?.isArchived ?? false) === includeArchived
    )
    .sort((a: Contact, b: Contact) => {
      const lastNameCompare = a.lastName?.localeCompare(b.lastName);
      if (lastNameCompare !== 0) return lastNameCompare;
      return a.firstName?.localeCompare(b.firstName);
    });
export const getContact: (
  state: ContactsState,
  contactId: string
) => Contact = (state, contactId) => state.byId[contactId];
export const getRedirect: (
  state: ContactsState,
  temporaryId: string
) => string = (state, temporaryId) => state.redirects[temporaryId];

export const getContactsByIdState: (
  state: ContactsState
) => ContactsByIdState = (state) => state.byId;

export const getContactIdsState: (state: ContactsState) => ContactId[] = (
  state
) => state.ids;

export const getContactsByCompanyId: (
  state: ContactsState,
  companyId: string
) => Array<ExternalContact> = (state, companyId) =>
  (state.ids ?? [])
    .map((id) => state.byId[id])
    .filter((contact: ExternalContact) => contact.companyId === companyId)
    .sort(sortContactsHelper);

export const getContactsByOfficeIds: (
  state: ContactsState,
  officeIds: string[]
) => Array<Contact> = (state, officeIds) =>
  (state.ids ?? [])
    .map((id) => state.byId[id])
    .filter((contact: Contact) => officeIds.includes(contact.officeId))
    .sort(sortContactsHelper);

export const getIsFetching: (state: any) => boolean = (state) =>
  state.meta.isFetching;
export const getHasError: (state: any) => boolean = (state) =>
  state.meta.hasError;
export const getErrorMessage: (state: any) => string = (state) =>
  state.meta.errorMessage;
