import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { InternalContact, Contact } from '../../../../models/Contact';
import { Employee } from '../../../../models/Employee';
import { makePrioStyles } from '../../../../theme/utils';
import Flex from '../../../../components/Flex';
import { Center } from '../../../../components/Center';
import {
  getContact,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import useDatePickerLocale from '../../../../hooks/useDatePickerLocale';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { apiFetchEmployee, apiPatchEmployee } from '../../api';
import { HolidayEntitlementsTable } from './HolidayEntitlementsTable';
import { OffBoardingModal, OffBoardEmployee } from './OffBoardingModal';
import { apiOffBoardEmployee } from '../../../employee/api';
import EmployeeAbsencesOverview from '../EmployeeAbsencesOverview';
import { AbsenceOverview } from '../../../../models/AbsenceProposal';
import PrioSpinner from '../../../../components/PrioSpinner';
import { useParams } from 'react-router-dom';
import PersonnelFileHeader from './PersonnelFileHeader';
import PersonnelFileCoreDataForm from './PersonnelFileCoreDataForm';
import PersonnelFileContractDataForm from './PersonnelFileContractDataForm';
import PersonnelFileBankDataForm from './PersonnelFileBankDataForm';
import { OfficeId } from '../../../../models/Types';
import PersonnelFileCompanyDataForm from './PersonnelFileCompanyDataForm';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { apiFetchAbsenceProposalOverview } from '../../../absences/api';
import useAccessRights from '../../../users/hooks/useAccessRights';
import { Tabs } from '@prio365/prio365-react-library';
import equals from 'deep-equal';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    position: 'relative',
    maxWidth: '100%',
    height: '100%',
    overflow: 'hidden',
    color: theme.colors.application.typography.default,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#ffffffaa',
  },
  content: {
    height: '100%',
    backgroundColor: theme.old.palette.backgroundPalette.content,
    '& .prio-tabs-container': {
      flex: 0,
    },
    '& .prio-tabs-content': {
      flex: 1,
      overflow: 'hidden',
    },
  },
}));

const REFETCH_DELAY = 3000;

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

interface PersonnelFileProps {
  className?: string;
  noAvatar?: boolean;
  officeId?: OfficeId;
  pathPrefix?: string;
  onlyShowTabs?: string[];
}

export const PersonnelFile: React.FC<PersonnelFileProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className, noAvatar, pathPrefix, onlyShowTabs } = props;
  const { employeeId, officeId } = useParams();
  const { t } = useTranslation();
  const datePickerLocale: PickerLocale = useDatePickerLocale();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  const contact: InternalContact = useSelector<RootReducerState, Contact>(
    (state) => getContact(state, employeeId)
  ) as InternalContact;

  const { showUserCoreData, showUserAbsenceOverview } = useAccessRights(
    ['showUserCoreData', 'showUserAbsenceOverview'],
    { officeId: officeId || contact?.officeId }
  );

  const [employee, setEmployee] = useState<Employee>({ employeeId });
  const employeeRef = useRef<Employee | null>(employee);
  employeeRef.current = employee;
  const [employeeIsFetching, setEmployeeIsFetching] = useState<boolean>(false);

  const [absenceOverview, setAbsenceOverview] =
    useState<AbsenceOverview | null>();
  const [absenceOverviewIsFetching, setAbsenceOverviewIsFetching] =
    useState<boolean>(false);

  const [offBoardingModalOpen, setOffBoardingModalOpen] =
    useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const fetchEmployeeAbortControllerRef = useRef<AbortController | null>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchAbsenceOverview = useCallback(
    async (signal?: AbortSignal) => {
      setAbsenceOverviewIsFetching(true);
      const { result, data } = await apiFetchAbsenceProposalOverview(
        employeeId,
        signal,
        officeId
      );
      setAbsenceOverviewIsFetching(false);
      if (result.status >= 200 && result.status < 300) {
        setAbsenceOverview({
          ...data,
          absenceProposals: data.absenceProposals.map(
            ({
              principalId,
              substituteId,
              applicantId,
              notifyContactIds,
              ...rest
            }) => ({
              ...rest,
              principalId: principalId.toLowerCase(),
              substituteId: substituteId.toLowerCase(),
              applicantId: applicantId.toLowerCase(),
              notifyContactIds: notifyContactIds.map((id) => id.toLowerCase()),
            })
          ),
        });
      } else {
        notification.open({
          message: t('common:error'),
          description: t('hr:personnelFile.errorMessages.fetchAbsenceOverview'),
        });
      }
    },
    [employeeId, officeId, t]
  );

  const fetchEmployee = useCallback(
    async (args: {
      refetchOnEquality?: Employee;
      disableFetching?: boolean;
      signal: AbortSignal;
    }) => {
      const {
        disableFetching = false,
        refetchOnEquality = false,
        signal,
      } = args;
      if (signal.aborted) {
        return;
      }
      if (!disableFetching) {
        setEmployeeIsFetching(true);
      }
      try {
        const { data } = await apiFetchEmployee(employeeId, signal, officeId);
        if (data) {
          if (!!refetchOnEquality) {
            if (equals(data, refetchOnEquality)) {
              await delay(REFETCH_DELAY);
              if (signal.aborted) {
                return;
              }
              fetchEmployeeAbortControllerRef.current = new AbortController();
              await fetchAbsenceOverview(
                fetchEmployeeAbortControllerRef.current.signal
              );
              if (signal.aborted) {
                return;
              }
              fetchEmployeeAbortControllerRef.current = new AbortController();
              await fetchEmployee({
                refetchOnEquality,
                disableFetching,
                signal: fetchEmployeeAbortControllerRef.current.signal,
              });
            } else {
              setEmployee(data);
            }
          } else {
            setEmployee(data);
          }
        }
        if (!disableFetching) {
          setEmployeeIsFetching(false);
        }
      } catch {}
    },
    [employeeId, officeId, fetchAbsenceOverview]
  );

  const patchEmployee = async (patchData: Employee) => {
    setIsSaving(true);
    try {
      const { data } = await apiPatchEmployee(
        employeeId,
        patchData,
        employee?.rowVersion ?? '',
        officeId
      );
      if (data) {
        setEmployee(data);
      } else {
        notification.open({
          message: t('common:error'),
          description: t('hr:personnelFile.errorMessages.patchEmployee'),
        });
      }
      setIsSaving(false);
    } catch {
      notification.open({
        message: t('common:error'),
        description: t('hr:personnelFile.errorMessages.patchEmployee'),
      });
      setIsSaving(false);
    }
  };

  const offBoard = () => {
    setOffBoardingModalOpen(true);
  };
  const closeOffBoardingModal = () => {
    setOffBoardingModalOpen(false);
  };
  const offBoardEmployee = async (offBoardEmployee: OffBoardEmployee) => {
    const fd = new FormData();
    fd.append('leavingDate', offBoardEmployee.leavingDate.toISOString());
    fd.append('leavingReason', offBoardEmployee.leavingReason);
    fd.append(
      'lockAccountDate',
      offBoardEmployee.lockAccountDate.toISOString()
    );
    fd.append('forwardEmailsTo', offBoardEmployee.forwardEmailsTo);
    if (offBoardEmployee.attachment?.length > 0) {
      fd.append('attachment', offBoardEmployee.attachment[0]);
    }
    const { data } = await apiOffBoardEmployee(employeeId, fd);
    if (data) {
      return true;
    } else {
      notification.open({
        message: t('common:error'),
        description: t('hr:offBoarding.errorMessages.offBoardEmployeeError'),
      });
      return false;
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const controller = new AbortController();
    if (!showUserCoreData) return () => controller.abort();
    const signal = controller.signal;
    const fetchEmployee = async () => {
      try {
        const { data } = await apiFetchEmployee(employeeId, signal, officeId);
        if (data) {
          setEmployee(data);
        }
        const endingTime = Date.now();
        if (endingTime - startingTime < 150) {
          setTimeout(() => setEmployeeIsFetching(false), 200);
        } else setEmployeeIsFetching(false);
      } catch {}
    };
    setEmployeeIsFetching(true);
    const startingTime = Date.now();
    fetchEmployee();
    return () => controller.abort();
  }, [employeeId, officeId, showUserCoreData]);

  useEffect(() => {
    if (showUserAbsenceOverview) fetchAbsenceOverview();
  }, [employeeId, fetchAbsenceOverview, showUserAbsenceOverview]);
  //#endregion

  //#region ------------------------------ Components
  const renderTabs = () => {
    const tabs = [
      {
        label: t('hr:personnelFile.pivot.coreData'),
        key: 'coreData',
        content: (
          <PersonnelFileCoreDataForm
            employeeId={employeeId}
            employee={employee}
            isSaving={isSaving}
            datePickerLocale={datePickerLocale}
            patchEmployee={patchEmployee}
          />
        ),
      },
      {
        label: t('hr:personnelFile.pivot.companyData'),
        key: 'companyData',
        content: (
          <PersonnelFileCompanyDataForm
            employeeId={employeeId}
            employee={employee}
            isSaving={isSaving}
            datePickerLocale={datePickerLocale}
            patchEmployee={patchEmployee}
            officeId={officeId}
            contact={contact}
          />
        ),
      },
      {
        label: t('hr:personnelFile.pivot.contractData'),
        key: 'contractData',
        content: (
          <PersonnelFileContractDataForm
            employeeId={employeeId}
            employee={employee}
            isSaving={isSaving}
            datePickerLocale={datePickerLocale}
            patchEmployee={patchEmployee}
          />
        ),
      },
      {
        label: t('hr:personnelFile.pivot.holidayEntitlements'),
        key: 'holidayEntitlements',
        content: (
          <HolidayEntitlementsTable
            employee={employee}
            setEmployee={setEmployee}
            employeeId={employeeId}
            officeId={officeId}
            absenceOverview={absenceOverview}
            fetchAbsenceOverview={fetchAbsenceOverview}
            fetchEmployee={fetchEmployee}
          />
        ),
      },
      {
        label: t('hr:personnelFile.pivot.absenceOverview'),
        key: 'absenceOverview',
        content: (
          <EmployeeAbsencesOverview
            absenceOverview={absenceOverview}
            absenceOverviewIsFetching={absenceOverviewIsFetching}
            officeId={officeId}
            fetchAbsenceOverview={fetchAbsenceOverview}
          />
        ),
      },
      {
        label: t('hr:personnelFile.pivot.bankData'),
        key: 'bankData',
        content: (
          <PersonnelFileBankDataForm
            employeeId={employeeId}
            employee={employee}
            isSaving={isSaving}
            patchEmployee={patchEmployee}
          />
        ),
      },
    ];
    return tabs.filter(
      (tab) => !onlyShowTabs || onlyShowTabs.includes(tab.key)
    );
  };
  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classes.content}
        childrenGap={theme.old.spacing.unit(3)}
        padding={`${theme.old.spacing.unit(2)}px ${theme.old.spacing.unit(
          3
        )}px`}
      >
        <PersonnelFileHeader
          contact={contact}
          employee={employee}
          offBoard={offBoard}
          noAvatar={noAvatar}
          pathPrefix={pathPrefix}
        />
        <Tabs items={renderTabs()} />
      </Flex.Column>
      {employeeIsFetching && (
        <div className={classes.loadingOverlay}>
          <Center>
            <PrioSpinner size="large" />
          </Center>
        </div>
      )}
      <OffBoardingModal
        open={offBoardingModalOpen}
        onClose={closeOffBoardingModal}
        offBoardEmployee={offBoardEmployee}
      />
    </div>
  );
};

export default PersonnelFile;
