import { PRIO } from '../../../constants';
import {
  CreateContactRequest,
  UpdateContactRequest,
  Contact,
  CreateExternalContactBundle,
} from '../../../models/Contact';
import { apiUrl } from '../../../api';
import { ContactId, OfficeId } from '../../../models/Types';

/** Sync Action for Saga */
export const SYNC_CONTACTS_ME = PRIO + 'SYNC_CONTACTS_ME';

export const syncContactsMe = () => ({ type: SYNC_CONTACTS_ME });

/** Contact fetching */
export const FETCH_CONTACTS_ME_REQUEST = PRIO + 'FETCH_CONTACTS_REQUEST';
export const FETCH_CONTACTS_ME_COMMIT = PRIO + 'FETCH_CONTACTS_COMMIT';
export const FETCH_CONTACTS_ME_ROLLBACK = PRIO + 'FETCH_CONTACTS_ROLLBACK';

export const fetchContactsOffset = (offset?: string) => ({
  type: FETCH_CONTACTS_ME_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/contact/me?includeArchived=true${
          offset ? `&offset=${offset}` : ''
        }`,
        method: 'GET',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FETCH_CONTACTS_ME_COMMIT,
        meta: {
          offset,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: FETCH_CONTACTS_ME_ROLLBACK,
        snackbarErrorMessage: {
          label: 'contacts:errorMessages.fetchError',
          timeout: 6,
        },
      },
    },
    offset,
  },
});

/** Contact creation */
export const CREATE_CONTACT_REQUEST = PRIO + 'CREATE_CONTACT_REQUEST';
export const CREATE_CONTACT_COMMIT = PRIO + 'CREATE_CONTACT_COMMIT';
export const CREATE_CONTACT_ROLLBACK = PRIO + 'CREATE_CONTACT_ROLLBACK';

export const createContact = (contact: CreateContactRequest, temporaryId) => ({
  type: CREATE_CONTACT_REQUEST,
  requiresAuth: true,
  payload: { contact },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Contact/externalContact`,
        method: 'POST',
        json: contact,
      },
      // action to dispatch when effect succeeds:
      commit: { type: CREATE_CONTACT_COMMIT, meta: { temporaryId } },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: CREATE_CONTACT_ROLLBACK,
        meta: { temporaryId },
        snackbarErrorMessage: {
          label: 'contacts:errorMessages.createError',
          timeout: 6,
        },
      },
    },
    temporaryId,
  },
});

/**Contact bundle creation (Contact, Company, Project Assignment)*/
export const CREATE_CONTACT_BUNDLE_REQUEST =
  PRIO + 'CREATE_CONTACT_BUNDLE_REQUEST';
export const CREATE_CONTACT_BUNDLE_COMMIT =
  PRIO + 'CREATE_CONTACT_BUNDLE_COMMIT';
export const CREATE_CONTACT_BUNDLE_ROLLBACK =
  PRIO + 'CREATE_CONTACT_BUNDLE_ROLLBACK';

export const createContactBundle = (
  contactBundle: CreateExternalContactBundle,
  contactTemporaryId,
  companyTemporaryId
) => ({
  type: CREATE_CONTACT_BUNDLE_REQUEST,
  requiresAuth: true,
  payload: { contactBundle },
  meta: {
    offline: {
      // the network action to execute
      effect: {
        url: `${apiUrl}/contact/Contact/externalContactBundle`,
        method: 'POST',
        json: contactBundle,
      },
      // action to dispatch when action succeeds:
      commit: {
        type: CREATE_CONTACT_BUNDLE_COMMIT,
        meta: { contactTemporaryId, companyTemporaryId },
      },
      //action to dispatch if the network action fails permanently:
      rollback: {
        type: CREATE_CONTACT_BUNDLE_ROLLBACK,
        meta: { contactTemporaryId, companyTemporaryId },
        snackbarErrorMessage: {
          label: 'contacts:errorMessages:createError',
          timeout: 6,
        },
      },
    },
    contactTemporaryId,
    companyTemporaryId,
  },
});
/** Contact update */
export const UPDATE_CONTACT_REQUEST = PRIO + 'UPDATE_CONTACT_REQUEST';
export const UPDATE_CONTACT_COMMIT = PRIO + 'UPDATE_CONTACT_COMMIT';
export const UPDATE_CONTACT_ROLLBACK = PRIO + 'UPDATE_CONTACT_ROLLBACK';

export const updateContact = (
  updateRequest: UpdateContactRequest,
  contactId: ContactId,
  rowVersion: string,
  rollbackContact: Contact
) => ({
  type: UPDATE_CONTACT_REQUEST,
  requiresAuth: true,
  payload: { updateRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Contact/externalContact/${contactId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        json: { ...updateRequest, rowVersion },
      },
      // action to dispatch when effect succeeds:
      commit: { type: UPDATE_CONTACT_COMMIT, meta: { contactId } },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_CONTACT_ROLLBACK,
        meta: { contactId },
        rollbackContact,
        snackbarErrorMessage: {
          label: 'contacts:errorMessages.updateError',
          timeout: 6,
        },
      },
    },
    contactId,
  },
});

export const UPDATE_INTERNAL_CONTACT_REQUEST =
  PRIO + 'UPDATE_INTERNAL_CONTACT_REQUEST';
export const UPDATE_INTERNAL_CONTACT_COMMIT =
  PRIO + 'UPDATE_INTERNAL_CONTACT_COMMIT';
export const UPDATE_INTERNAL_CONTACT_ROLLBACK =
  PRIO + 'UPDATE_INTERNAL_CONTACT_ROLLBACK';

export const updateInternalContact = (
  updateRequest: UpdateContactRequest,
  contactId: ContactId,
  rowVersion: string,
  rollbackContact: Contact,
  officeId?: OfficeId
) => ({
  type: UPDATE_INTERNAL_CONTACT_REQUEST,
  requiresAuth: true,
  payload: { updateRequest },
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Contact${
          officeId ? `/office/${officeId}` : ``
        }/internalContact/${contactId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/merge-patch+json',
        },
        json: { ...updateRequest, rowVersion },
      },
      // action to dispatch when effect succeeds:
      commit: { type: UPDATE_INTERNAL_CONTACT_COMMIT, meta: { contactId } },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: UPDATE_INTERNAL_CONTACT_ROLLBACK,
        meta: { contactId },
        rollbackContact,
        snackbarErrorMessage: {
          label: 'contacts:errorMessages.updateError',
          timeout: 6,
        },
      },
    },
    contactId,
  },
});

/** Contact archivation */
export const ARCHIVE_CONTACT_REQUEST = PRIO + 'ARCHIVE_CONTACT_REQUEST';
export const ARCHIVE_CONTACT_COMMIT = PRIO + 'ARCHIVE_CONTACT_COMMIT';
export const ARCHIVE_CONTACT_ROLLBACK = PRIO + 'ARCHIVE_CONTACT_ROLLBACK';

export const archiveContact = (contactId: ContactId) => ({
  type: ARCHIVE_CONTACT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Contact/${contactId}/archive`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ARCHIVE_CONTACT_COMMIT,
        meta: { contactId },
      },

      // action to dispatch if network action fails permanently:
      rollback: {
        type: ARCHIVE_CONTACT_ROLLBACK,
        meta: { contactId },
        snackbarErrorMessage: {
          label: 'contacts:errorMessages.archiveError',
          timeout: 6,
        },
      },
    },
    contactId,
  },
});

/** Contact unarchivation */
export const UNARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST =
  PRIO + 'UNARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST';
export const UNARCHIVE_CONTACT_AS_OFFICE_ROLE_COMMIT =
  PRIO + 'UNARCHIVE_CONTACT_AS_OFFICE_ROLE_COMMIT';
export const UNARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK =
  PRIO + 'UNARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK';

export const unarchiveContactAsOfficeRole = (
  contactId: ContactId,
  officeId: OfficeId
) => ({
  type: UNARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/contact/Office/${officeId}/Contact/${contactId}/unarchive`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UNARCHIVE_CONTACT_AS_OFFICE_ROLE_COMMIT,
        meta: { contactId },
      },

      // action to dispatch if network action fails permanently:
      rollback: {
        type: UNARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK,
        meta: { contactId },
        snackbarErrorMessage: {
          label: 'contacts:errorMessages.unarchiveError',
          timeout: 6,
        },
      },
    },
    contactId,
  },
});

/** Contact archivation with Office role*/
export const ARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST =
  PRIO + 'ARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST';
export const ARCHIVE_CONTACT_AS_OFFICE_ROLE_COMMIT =
  PRIO + 'ARCHIVE_CONTACT_AS_OFFICE_ROLE_COMMIT';
export const ARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK =
  PRIO + 'ARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK';

export const archiveContactAsOfficeRole = (
  contactId: ContactId,
  officeId: OfficeId
) => ({
  type: ARCHIVE_CONTACT_AS_OFFICE_ROLE_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/contact/Office/${officeId}/Contact/${contactId}/archive`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ARCHIVE_CONTACT_AS_OFFICE_ROLE_COMMIT,
        meta: { contactId },
      },

      // action to dispatch if network action fails permanently:
      rollback: {
        type: ARCHIVE_CONTACT_AS_OFFICE_ROLE_ROLLBACK,
        meta: { contactId },
        snackbarErrorMessage: {
          label: 'contacts:errorMessages.archiveError',
          timeout: 6,
        },
      },
    },
    contactId,
  },
});

/** Contact unarchivation with Office role */
export const UNARCHIVE_CONTACT_REQUEST = PRIO + 'UNARCHIVE_CONTACT_REQUEST';
export const UNARCHIVE_CONTACT_COMMIT = PRIO + 'UNARCHIVE_CONTACT_COMMIT';
export const UNARCHIVE_CONTACT_ROLLBACK = PRIO + 'UNARCHIVE_CONTACT_ROLLBACK';

export const unarchiveContact = (contactId: ContactId) => ({
  type: UNARCHIVE_CONTACT_REQUEST,
  requiresAuth: true,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${apiUrl}/contact/Contact/${contactId}/unarchive`,
        method: 'POST',
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UNARCHIVE_CONTACT_COMMIT,
        meta: { contactId },
      },

      // action to dispatch if network action fails permanently:
      rollback: {
        type: UNARCHIVE_CONTACT_ROLLBACK,
        meta: { contactId },
        snackbarErrorMessage: {
          label: 'contacts:errorMessages.unarchiveError',
          timeout: 6,
        },
      },
    },
    contactId,
  },
});

/* Set last project location */
export interface LastContactLocation {
  type: string;
  contactLocation: string;
}

export const SET_LAST_CONTACT_LOCATION = PRIO + 'SET_LAST_CONTACT_LOCATION';

export const setLastContactLocation: (
  contactLocation: string
) => LastContactLocation = (contactLocation: string) => ({
  type: SET_LAST_CONTACT_LOCATION,
  contactLocation,
});

export const WS_UPDATE_OR_ADD_CONTACT = PRIO + 'WS_UPDATE_OR_ADD_CONTACT';

export const wsUpdateOrAddContact: (contact: Contact) => {
  type: string;
  contact: Contact;
} = (contact) => ({
  type: WS_UPDATE_OR_ADD_CONTACT,
  contact,
});
