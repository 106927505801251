import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import moment, { Moment } from 'moment';
import {
  Form,
  Input,
  Row,
  Col,
  Select,
  Divider,
  Alert,
  Checkbox,
  Typography,
  notification,
  FormInstance,
  Modal,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';

import { makePrioStyles } from '../../../theme/utils';

import {
  AbsenceProposalRequest,
  AbsenceProposal,
  AbsenceProposalFormModel,
  AbsenceOverview,
  AbsentWorkDaysResult,
} from '../../../models/AbsenceProposal';
import {
  AbsenceState,
  AbsenceType,
  ContactId,
  DateTimeString,
  MedicalCertificateId,
  OfficeId,
} from '../../../models/Types';
import { rowGutter, colon } from '../../../util/forms';
import ContactPicker from '../../contacts/components/ContactPicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from '../../../components/Flex';
import {
  formatNumber,
  compactDateWithWeekDayFormatString,
  fullDateFormatFormatString,
  debounceFunction,
  isTemporaryId,
} from '../../../util';
import {
  apiFetchAbsenceProposalOverview,
  apiFetchAbsentWorkDays,
  apiFetchSubstituteOnLeave,
} from '../api';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContact,
  getContactsByIdState,
  getUserMe,
  RootReducerState,
  getMonthlyCloseMeByMonth,
} from '../../../apps/main/rootReducer';
import { apiFetchPrincipals, apiFetchPrincipalsMe } from '../../employee/api';
import { TFunction } from 'i18next';
import { User } from '../../../models/User';
import { ContactsByIdState } from '../../contacts/reducers/contacts';
import { Contact } from '../../../models/Contact';
import CustomDateRangePicker from '../../../components/CustomDateRangePicker';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import useAccessRights from '../../users/hooks/useAccessRights';
import { ApiResult } from '../../../api';
import AbsenceInsightsChart from './absenceOverViewChart/AbsenceInsightsChart';
import { MonthlyClose } from '../../../models/TimeKeeping';
import { fetchMonthlyCloseMe } from '../../timeKeeping/actions';
import {
  deleteAbsenceProposalMe,
  fetchAbsencesMe,
  revokeAbsenceProposal,
} from '../actions';
import CollapseWithHeader from '../../../components/CollapseWithHeader';
import { MedicalCertificate } from '../../../models/MedicalCertificate';
import MedicalCertificateListItem from './MedicalCertificate/MedicalCertificateListItem';
import MedicalCertificateDrawer, {
  MedicalCertificateDrawerRef,
} from './MedicalCertificate/MedicalCertificateDrawer';
import { PrioFile } from '../../../components/Upload/UploadField';

const MIN_WIDTH_FORM_ITEM = 480;

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    overflow: 'hidden auto',
  },

  absenceInsightRow: {
    paddingTop: theme.old.spacing.baseSpacing,
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.old.spacing.baseSpacing * 2,
    gap: theme.old.spacing.baseSpacing,
  },
  overtimePanel: {
    width: '30%',
    backgroundColor: theme.old.palette.backgroundPalette.active.content,
    boxShadow: theme.old.palette.boxShadow.regular,
    padding: '3px 6px',
    margin: '0px 6px 3px 6px',
    height: '100%',
    '& .ant-form-item-label': {
      padding: '0px',
    },
  },
  absentWorkDays: {
    width: '100%',
    textAlign: 'center',
    padding: '4px',
  },
  overviewIcon: {
    marginRight: theme.old.spacing.unit(1),
  },
  halfDayCheckboxRow: {
    paddingTop: '15px',
  },
  halfDayCheckboxFirstWithAbsentWorkDays: {
    width: 'calc(45% * 0.8696 + 10px)',
    marginBottom: '15px',
  },
  halfDayCheckboxFirstWithoutAbsentWorkDays: {
    width: 'calc(45% + 14px)',
    marginBottom: '15px',
  },
  publicHolidays: {
    maxHeight: 69,
    overflowY: 'auto',
  },
  publicHolidaysCount: {
    fontWeight: theme.old.typography.fontWeight.bold,
  },
  publicHolidayDate: {
    width: 125,
  },
  divider: {
    margin: '4px 0px 28px 0px',
  },
  noMarginBottom: {
    marginBottom: '0px',
  },
  substituteWarning: {
    marginBottom: theme.old.spacing.unit(2),
  },
  pickerDisabled: {
    backgroundColor: 'rgb(0,0,0,0.05)',
  },
  errorField: {
    '& .DateRangePickerInput__withBorder': {
      borderColor: theme.old.palette.chromaticPalette.red,
    },
    borderColor: theme.old.palette.chromaticPalette.red,
  },
  statusAlert: {
    marginTop: `${theme.old.spacing.unit(1)}px`,
    marginBottom: `${theme.old.spacing.unit(3)}px`,
    '&.ant-alert.ant-alert-no-icon.ant-alert-info *': {
      color: 'black!important',
    },
  },
  statusAlertBackgroundColorGreen: {
    '&.ant-alert.ant-alert-no-icon.ant-alert-info': {
      backgroundColor: `${theme.old.palette.chromaticPalette.green}80`,
    },
  },
  statusAlertBackgroundColorYellow: {
    '&.ant-alert.ant-alert-no-icon.ant-alert-info': {
      backgroundColor: `${theme.old.palette.chromaticPalette.yellow}80`,
    },
  },
  statusAlertBackgroundColorRed: {
    '&.ant-alert.ant-alert-no-icon.ant-alert-info': {
      backgroundColor: `${theme.old.palette.chromaticPalette.red}80`,
    },
  },
  statusAlertBackgroundColorGrey: {
    '&.ant-alert.ant-alert-no-icon.ant-alert-info': {
      backgroundColor: `${theme.old.palette.chromaticPalette.grey}80`,
    },
  },
  fontBold: {
    fontWeight: 600,
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
}));

export type PrioFileByMedicalCertificateId = Record<
  MedicalCertificateId,
  PrioFile
>;

interface AbsenceProposalFormProps {
  className?: string;
  initialValues?: AbsenceProposalRequest | AbsenceProposal;
  editMode?: boolean;
  canSelectApplicant?: boolean;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  onFinish: (
    value: AbsenceProposalRequest | AbsenceProposal,
    medicalCertificates?: MedicalCertificate[],
    prioFilesById?: PrioFileByMedicalCertificateId
  ) => void;
  onCancel?: () => void;
  absenceOverview: AbsenceOverview | null;
  absenceState?: AbsenceState;
  isNewAbsenceProposal?: boolean;
  isUpdateAbsenceProposal?: boolean;
  setDrawerOpen?: (open: boolean) => void;
}

const fetchAbsentWorkDays = async (
  form: FormInstance<any>,
  setAbsentWorkDays: (data: AbsentWorkDaysResult) => void,
  t: TFunction,
  userMe: User,
  signal: AbortSignal,
  period?: Moment[],
  firstIsHalfDay?: boolean,
  lastIsHalfDay?: boolean,
  officeId?: OfficeId
) => {
  const from = period[0]?.format('YYYY-MM-DD');
  const to = period[1]?.format('YYYY-MM-DD');
  const internalContactId = form.getFieldValue('applicantId');
  const { result, data } = await apiFetchAbsentWorkDays(
    from,
    to,
    firstIsHalfDay,
    lastIsHalfDay,
    userMe.id !== internalContactId ? internalContactId : undefined,
    officeId
  );
  if (!signal.aborted) {
    if (result.status >= 200 && result.status < 300) {
      if (typeof data === 'object') {
        setAbsentWorkDays(data);
      }
    } else {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.fetchAbsentWorkdaysError'),
      });
    }
  }
};

const debouncedFetchAbsentWorkDays = debounceFunction(fetchAbsentWorkDays, 500);

const missingPrincipalSelect = (
  principalIds: string[],
  contactsByIdState: ContactsByIdState,
  form: FormInstance<AbsenceProposalFormModel>
) => {
  principalIds = principalIds ?? [];
  const principalId = form.getFieldsValue(['principalId'])?.principalId;
  if (principalIds.includes(principalId)) {
    return null;
  }
  return (
    <Select.Option
      value={principalId}
      key={principalId}
      label={
        contactsByIdState[principalId]?.firstName &&
        contactsByIdState[principalId]?.lastName
          ? `${contactsByIdState[principalId]?.firstName} ${contactsByIdState[principalId]?.lastName}`
          : ''
      }
    >
      {contactsByIdState[principalId]?.firstName &&
      contactsByIdState[principalId]?.lastName
        ? `${contactsByIdState[principalId]?.firstName} ${contactsByIdState[principalId]?.lastName}`
        : ''}
    </Select.Option>
  );
};

export const AbsenceProposalForm: React.FC<AbsenceProposalFormProps> =
  React.memo((props) => {
    const classes = useStyles();
    const theme = useTheme<PrioTheme>();
    const {
      className,
      initialValues,
      actionLabel,
      cancelLabel,
      onFinish,
      onCancel,
      editMode,
      canSelectApplicant,
      disableActionButton,
      disableForm,
      absenceOverview: absenceOverviewMe,
      absenceState,
      isNewAbsenceProposal,
      isUpdateAbsenceProposal,
      setDrawerOpen,
    } = props;

    //#region ------------------------------ Defaults
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const absentWorkdaysController = useRef<AbortController>(null);
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const medicalCertificateDrawerRef =
      useRef<MedicalCertificateDrawerRef>(null);

    const [absenceOverview, setAbsenceOverview] =
      useState<AbsenceOverview>(absenceOverviewMe);

    const monthlyCloseMeThisMonth = useSelector<RootReducerState, MonthlyClose>(
      (state) => getMonthlyCloseMeByMonth(state, moment().format('YYYY-MM'))
    );

    const currentOvertimeHours =
      monthlyCloseMeThisMonth?.accumulatedOvertimeHours ?? 0;

    const transformedInitialValue: AbsenceProposalFormModel = {
      ...initialValues,
      period: [
        initialValues?.from
          ? moment(initialValues.from, moment.ISO_8601)
          : moment().add('1', 'day').startOf('day'),
        initialValues?.to
          ? moment(initialValues.to, moment.ISO_8601)
          : moment().add('1', 'day').startOf('day'),
      ],
    };

    const {
      writeOtherUserAbsenceByOfficeRole,
      writeOtherUserAbsenceByGlobalRole,
    } = useAccessRights([
      'writeOtherUserAbsenceByOfficeRole',
      'writeOtherUserAbsenceByGlobalRole',
    ]);

    const userMe = useSelector(getUserMe);
    const contactsByIdState = useSelector(getContactsByIdState);

    const [substitute, setSubstitute] = useState<ContactId>(null);
    const contactSubstitute = useSelector<RootReducerState, Contact>((state) =>
      getContact(state, substitute)
    );

    const [absenceTypeOther, setAbsenceTypeOther] = useState<boolean>(
      initialValues?.absenceType === 'other'
    );

    const [absenceTypeDayOfIllness, setAbsenceTypeDayOfIllness] =
      useState<boolean>(initialValues?.absenceType === 'dayOfIllness');

    const [
      absenceTypeAnnualLeaveWithoutPlanning,
      setAbsenceTypeAnnualLeaveWithoutPlanning,
    ] = useState<boolean>(
      initialValues?.absenceType === 'annualLeave' &&
        (initialValues as AbsenceProposal)?.absenceState !== 'planned'
    );

    const [absentWorkDays, setAbsentWorkDays] = useState<AbsentWorkDaysResult>({
      absentWorkdays: (initialValues as AbsenceProposal)?.absentWorkdays ?? 1,
      publicHolidays: [],
    });

    const [isSingleDay, setIsSingleDay] = useState<boolean>(
      !!initialValues && initialValues.from === initialValues.to
    );

    const [isToday, setIsToday] = useState<boolean>(true);

    const [period, setPeriod] = useState<Moment[]>([
      initialValues?.from
        ? moment(initialValues.from, moment.ISO_8601)
        : moment().add('1', 'day').startOf('day'),
      initialValues?.to
        ? moment(initialValues.to, moment.ISO_8601)
        : moment().add('1', 'day').startOf('day'),
    ]);
    const [firstIsHalfDay, setFirstIsHalfDay] = useState<boolean>(
      initialValues?.firstIsHalfDay ?? false
    );
    const [lastIsHalfDay, setLastIsHalfDay] = useState<boolean>(
      initialValues?.lastIsHalfDay ?? false
    );

    const [periodError, setPeriodError] = useState<string>(null);

    const [currentApplicantId, setCurrentApplicantId] = useState<ContactId>(
      transformedInitialValue?.applicantId ?? null
    );

    const isUserMe = currentApplicantId === userMe.id;

    const officeId = contactsByIdState[currentApplicantId]?.officeId;

    const [principalIds, setPrincipalIds] = useState<ContactId[]>([]);
    const [substituteOnLeave, setSubstituteOnLeave] = useState<boolean>(false);
    const [startingDaySubstituteLeave, setStartingDaySubstituteLeave] =
      useState<DateTimeString>(null);

    const [endingDaySubstituteLeave, setEndingDaySubstituteLeave] =
      useState<DateTimeString>(null);

    const [disabled, setDisabled] = useState<boolean>(false);
    const [isFormValuesChanged, setIsFormValuesChanged] =
      useState<boolean>(false);
    const [isPeriodChanged, setIsPeriodChanged] = useState<boolean>(false);
    const [isSubstituteIdEmpty, setIsSubstituteIdEmpty] =
      useState<boolean>(true);

    const isSaveChangesButtonDisabled: boolean = useMemo(() => {
      return disableActionButton
        ? disableActionButton
        : false || disableForm || !(isFormValuesChanged || isPeriodChanged);
    }, [
      disableActionButton,
      disableForm,
      isFormValuesChanged,
      isPeriodChanged,
    ]);

    const [medicalCertificates, setMedicalCertificates] = useState<
      MedicalCertificate[]
    >([]);

    const [prioFilesById, setPrioFilesById] =
      useState<PrioFileByMedicalCertificateId>({});
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const validatePeriod = () => {
      if (period[0] && period[1]) {
        setPeriodError(null);
      } else {
        setPeriodError(t('absences:form.validation.invalidDateRange'));
      }
    };

    const handleSubstituteChange = useCallback(
      async (substitute: ContactId, from: Moment, to: Moment) => {
        if (from && to && substitute) {
          const { data } = await apiFetchSubstituteOnLeave(
            substitute,
            from.toISOString(true).split('T')[0],
            to.toISOString(true).split('T')[0]
          );
          setSubstituteOnLeave(false);
          if (data) {
            setSubstituteOnLeave(data.isAbsence);
            setStartingDaySubstituteLeave(
              moment(data.from).format('DD. MMM YYYY') ?? ''
            );
            setEndingDaySubstituteLeave(
              moment(data.to).format('DD. MMM YYYY') ?? ''
            );
          }
        }
      },
      []
    );

    const cancel = () => {
      form.resetFields();
      onCancel();
    };

    const handleFinish = (currentValue: AbsenceProposalFormModel) => {
      if (!onFinish) return;

      const { ...rest } = currentValue;
      const result: AbsenceProposalRequest = {
        ...rest,
        from: period[0].toISOString(true).split('T')[0],
        to: period[1].toISOString(true).split('T')[0],
        ...(writeOtherUserAbsenceByOfficeRole
          ? { officeId: contactsByIdState[currentValue.applicantId]?.officeId }
          : {}),
      };
      if (editMode) {
        onFinish(result);
      } else {
        onFinish(
          result,
          medicalCertificates.filter(({ medicalCertificateId }) =>
            isTemporaryId(medicalCertificateId)
          ),
          prioFilesById
        );
      }
    };

    const filter = (contact: Contact) => {
      if (contact.contactId === userMe.id) {
        return true;
      }
      return (Object.keys(userMe?.prioData?.officeRoles) ?? []).includes(
        contact.officeId
      );
    };

    const fetchAbsenceInfo = useCallback(
      async (value: ContactId, officeId?: OfficeId) => {
        if (value !== userMe.id) {
          const fetchAbsenceOverview = () =>
            new Promise<ApiResult<AbsenceOverview>>((resolve, reject) => {
              try {
                resolve(
                  apiFetchAbsenceProposalOverview(value, undefined, officeId)
                );
              } catch (error) {
                reject(error);
              }
            });

          const { result, data } = await fetchAbsenceOverview();
          if (result.status >= 200 && result.status < 300) {
            setAbsenceOverview(data);
          } else {
            notification.open({
              message: t('common:error'),
              description: t('absences:errorMessages.fetchOverviewError'),
            });
          }
        } else {
          setAbsenceOverview(absenceOverviewMe);
        }
      },
      [userMe.id, absenceOverviewMe, t]
    );

    const getStatusAlertBackgroundColor = (
      absenceProposal: AbsenceProposal
    ) => {
      switch (absenceProposal?.absenceState) {
        case 'accepted':
        case 'revokeAccepted':
          return classes.statusAlertBackgroundColorGreen;
        case 'declined':
        case 'revokeDeclined':
          return classes.statusAlertBackgroundColorRed;
        case 'requested':
        case 'revokeRequested':
          return classes.statusAlertBackgroundColorYellow;
        case 'planned':
          return classes.statusAlertBackgroundColorGrey;
        default:
          return '';
      }
    };

    const onDeleteAbsenceProposal = async (
      absenceProposal: AbsenceProposal
    ) => {
      Modal.confirm({
        icon: null,
        title: t('absences:deleteAbsence.confirmationDialog.title'),
        content: t('absences:deleteAbsence.confirmationDialog.content', {
          period:
            absenceProposal.from === absenceProposal.to
              ? fullDateFormatFormatString(absenceProposal.from)
              : `${fullDateFormatFormatString(absenceProposal.from)} ${t(
                  'common:to'
                )} ${fullDateFormatFormatString(absenceProposal.to)}`,
          absenceType: t(`absences:types.${absenceProposal.absenceType}`),
        }),
        okText: t('absences:deleteAbsence.confirmationDialog.okText'),
        cancelText: t('absences:deleteAbsence.confirmationDialog.cancelText'),
        onOk() {
          dispatch(
            deleteAbsenceProposalMe(
              absenceProposal.absenceProposalId,
              absenceProposal
            )
          );
          if (setDrawerOpen) {
            setDrawerOpen(false);
          }
        },
        onCancel() {},
      });
    };

    const handleOnDelete = () => {
      if (initialValues) {
        onDeleteAbsenceProposal(initialValues as AbsenceProposal);
      }
    };

    const onRevokeAbsenceProposal = async (
      absenceProposal: AbsenceProposal
    ) => {
      Modal.confirm({
        icon: null,
        title: t('absences:revokeAbsence.confirmationDialog.title'),
        content: t('absences:revokeAbsence.confirmationDialog.content', {
          period:
            absenceProposal.from === absenceProposal.to
              ? fullDateFormatFormatString(absenceProposal.from)
              : `${fullDateFormatFormatString(absenceProposal.from)} ${t(
                  'common:to'
                )} ${fullDateFormatFormatString(absenceProposal.to)}`,
          absenceType: t(`absences:types.${absenceProposal.absenceType}`),
        }),
        okText: t('absences:revokeAbsence.confirmationDialog.okText'),
        cancelText: t('absences:revokeAbsence.confirmationDialog.cancelText'),
        onOk() {
          dispatch(
            revokeAbsenceProposal(
              absenceProposal.absenceProposalId,
              absenceProposal
            )
          );
          dispatch(fetchAbsencesMe());
          if (setDrawerOpen) {
            setDrawerOpen(false);
          }
        },
        onCancel() {},
      });
    };

    const handleOnRevoke = () => {
      if (initialValues) {
        onRevokeAbsenceProposal(initialValues as AbsenceProposal);
      }
    };

    const handleOnMedicalCertificateDrawerClose = (
      medicalCertificate: MedicalCertificate,
      file: PrioFile
    ) => {
      if (medicalCertificate) {
        setMedicalCertificates((currentState) => {
          const index = currentState.findIndex(
            (mc) =>
              mc.medicalCertificateId ===
              medicalCertificate.medicalCertificateId
          );
          if (index >= 0) {
            currentState[index] = medicalCertificate;
          } else {
            currentState.push(medicalCertificate);
          }
          return [...currentState];
        });
      }
      if (
        !editMode &&
        isTemporaryId(medicalCertificate?.medicalCertificateId) &&
        file
      ) {
        setPrioFilesById((state) => ({
          ...state,
          [medicalCertificate.medicalCertificateId]: file,
        }));
      }
    };

    const handleOnEditMedicalCertificate = (
      medicalCertificate: MedicalCertificate
    ) => {
      if (medicalCertificateDrawerRef.current) {
        medicalCertificateDrawerRef.current.setMedicalCertificate(
          medicalCertificate
        );
        medicalCertificateDrawerRef.current.setVisible(true);
      }
    };

    const handleOnArchiveMedicalCertificate = (
      medicalCertificate: MedicalCertificate
    ) => {
      setMedicalCertificates((currentState) => {
        return currentState.filter(
          (mc) =>
            mc.medicalCertificateId !== medicalCertificate.medicalCertificateId
        );
      });

      if (medicalCertificateDrawerRef.current) {
        medicalCertificateDrawerRef.current.refetchMedicalCertificates();
      }
    };
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      form.resetFields();
      setPrioFilesById({});
      setMedicalCertificates([]);
    }, [initialValues, form]);

    useEffect(() => {
      if (period[0] !== null && period[1] !== null) {
        setIsToday(
          period[0].format('YYYY-MM-DD') ===
            moment().add('1', 'day').startOf('day').format('YYYY-MM-DD') &&
            period[1].format('YYYY-MM-DD') ===
              moment().add('1', 'day').startOf('day').format('YYYY-MM-DD')
        );
        if (absentWorkdaysController.current) {
          absentWorkdaysController.current.abort();
        }
        absentWorkdaysController.current = new AbortController();
        const signal = absentWorkdaysController.current.signal;

        debouncedFetchAbsentWorkDays(
          form,
          setAbsentWorkDays,
          t,
          userMe,
          signal,
          period,
          firstIsHalfDay,
          lastIsHalfDay,
          officeId
        );
      }
      return () => {
        if (absentWorkdaysController.current) {
          absentWorkdaysController.current.abort();
        }
      };
    }, [
      period,
      firstIsHalfDay,
      lastIsHalfDay,
      isSingleDay,
      form,
      t,
      userMe,
      officeId,
      currentApplicantId,
    ]);

    // reset when period change form multiple to single day and halfDays are selected
    useEffect(() => {
      if (
        isSingleDay &&
        isPeriodChanged &&
        (form.getFieldValue('firstIsHalfDay') === true ||
          form.getFieldValue('lastIsHalfDay') === true)
      ) {
        form.setFieldsValue({ firstIsHalfDay: false, lastIsHalfDay: false });
        setFirstIsHalfDay(false);
        setLastIsHalfDay(false);
      }
    }, [isSingleDay, form, isPeriodChanged]);

    useEffect(() => {
      const fetchPrincipals = async () => {
        const promise = new Promise<void>(async (resolve) => {
          const { data } = canSelectApplicant
            ? await apiFetchPrincipals(currentApplicantId)
            : await apiFetchPrincipalsMe();
          if (data) {
            setPrincipalIds(data);
          }
          resolve();
        });
        return promise.then(async () => {
          if (!initialValues) {
            form.setFieldsValue({
              principalId: contactsByIdState[currentApplicantId].managerId,
            });
          }
        });
      };
      if (contactsByIdState) {
        fetchPrincipals();
      }
    }, [
      currentApplicantId,
      canSelectApplicant,
      contactsByIdState,
      form,
      initialValues,
    ]);

    useEffect(() => {
      setDisabled(disableForm || editMode);
    }, [disableForm, editMode]);

    useEffect(() => {
      if (substitute && period) {
        handleSubstituteChange(substitute, period[0], period[1]);
      }
    }, [substitute, period, handleSubstituteChange]);

    useEffect(() => {
      dispatch(fetchMonthlyCloseMe());
    }, [dispatch]);

    useEffect(() => {
      if (
        period[0]?.format('YYYY-MM-DD') !== (initialValues?.from as string) ||
        period[1]?.format('YYYY-MM-DD') !== (initialValues?.to as string)
      ) {
        setIsPeriodChanged(true);
      } else {
        setIsPeriodChanged(false);
      }
    }, [period, initialValues, isPeriodChanged]);
    //#endregion

    return (
      <Form<AbsenceProposalFormModel>
        className={classNames(classes.root, className)}
        initialValues={transformedInitialValue}
        form={form}
        onFinish={handleFinish}
        layout="vertical"
        onValuesChange={(
          changedValues: AbsenceProposalFormModel,
          allValues: AbsenceProposalFormModel
        ) => {
          if (changedValues.absenceType) {
            setAbsenceTypeOther(changedValues.absenceType === 'other');
            setAbsenceTypeAnnualLeaveWithoutPlanning(
              changedValues.absenceType === 'annualLeave'
            );
            setAbsenceTypeDayOfIllness(
              changedValues.absenceType === 'dayOfIllness'
            );
          }
          if (changedValues.applicantId) {
            setCurrentApplicantId(changedValues.applicantId);
            fetchAbsenceInfo(
              changedValues.applicantId,
              writeOtherUserAbsenceByOfficeRole
                ? contactsByIdState[changedValues.applicantId]?.officeId
                : undefined
            );
          }
          if (changedValues.substituteId) {
            setSubstitute(changedValues.substituteId);
          }
          if (allValues.substituteId) {
            setIsSubstituteIdEmpty(false);
          } else {
            setIsSubstituteIdEmpty(true);
          }
          if (changedValues) {
            const propsToCheck = Object.keys(changedValues);
            let changeHappend = false;
            propsToCheck.forEach((prop) => {
              if (Array.isArray(changedValues[prop])) {
                if (
                  changedValues[prop]?.length !== initialValues[prop]?.length
                ) {
                  changeHappend = true;
                } else {
                  changeHappend = !(
                    initialValues[prop].every((element) =>
                      changedValues[prop].includes(element)
                    ) &&
                    changedValues[prop].every((element) =>
                      initialValues[prop].includes(element)
                    )
                  );
                }
              } else {
                if (changedValues?.['note'] === '') {
                  changeHappend =
                    initialValues?.['note'] === null ? false : true;
                } else {
                  changeHappend = changedValues[prop] !== initialValues[prop];
                }
              }
            });
            setIsFormValuesChanged(changeHappend);
            if (changedValues.firstIsHalfDay === true && isSingleDay) {
              changedValues.lastIsHalfDay = false;
              form.setFieldsValue({ lastIsHalfDay: false });
              setLastIsHalfDay(false);
            }
            if (changedValues.lastIsHalfDay === true && isSingleDay) {
              changedValues.firstIsHalfDay = false;
              form.setFieldsValue({ firstIsHalfDay: false });
              setFirstIsHalfDay(false);
            }
          }
        }}
      >
        <Flex.Item flex={1} className={classes.content}>
          {absenceOverviewMe && isNewAbsenceProposal && (
            <>
              <div className={classes.absenceInsightRow}>
                <AbsenceInsightsChart />
                <Form
                  layout="vertical"
                  className={classNames(classes.overtimePanel)}
                >
                  <Form.Item
                    name={'1'}
                    label={t(
                      'dashboard:holidayEntitlementAndOvertime.overtimeTotal'
                    )}
                    colon={colon}
                    style={{
                      color:
                        (currentOvertimeHours ?? 0) < 0
                          ? theme.old.palette.chromaticPalette.red
                          : (currentOvertimeHours ?? 0) > 0
                          ? theme.old.palette.chromaticPalette.green
                          : undefined,
                    }}
                  >
                    {`${currentOvertimeHours} h`}
                  </Form.Item>
                </Form>
              </div>
              <Divider className={classes.divider} />
            </>
          )}
          {isUpdateAbsenceProposal && absenceState !== 'planned' && (
            <Alert
              className={classNames(
                classes.statusAlert,
                getStatusAlertBackgroundColor(initialValues as AbsenceProposal)
              )}
              message={
                <Flex.Row>
                  {absenceState?.includes('revoke') ? (
                    <>
                      <span>
                        {t('absences:form.statusAlertForRevoke.part1')}&nbsp;
                      </span>
                      <span className={classes.fontBold}>
                        {t('absences:form.statusAlertForRevoke.part2')}&nbsp;
                      </span>
                      <span>
                        {t('absences:form.statusAlertForRevoke.part3')}&nbsp;
                      </span>
                      <span className={classes.fontBold}>
                        {t(
                          `absences:states.${absenceState
                            .split('revoke')[1]
                            .toLowerCase()}`
                        )}
                      </span>
                      <span>{'.'}</span>
                    </>
                  ) : (
                    <>
                      <span>{t('absences:form.statusAlert')}&nbsp;</span>
                      <span className={classes.fontBold}>
                        {t(`absences:states.${absenceState}`)}
                      </span>
                      <span>{'.'}</span>
                    </>
                  )}
                </Flex.Row>
              }
            />
          )}
          <Flex.Row flexWrap="wrap" columnGap={theme.old.spacing.baseSpacing}>
            <Flex.Item flex={1} minWidth={MIN_WIDTH_FORM_ITEM}>
              <Form.Item
                name="absenceType"
                colon={colon}
                label={t('absences:form.labels.absenceType')}
              >
                <Select<AbsenceType>
                  disabled={disabled}
                  className={disabled ? classes.pickerDisabled : ''}
                >
                  <Select.Option value="annualLeave">
                    {t('absences:form.absenceTypes.annualLeave')}
                  </Select.Option>
                  <Select.Option value="annualLeavePlanning">
                    {t('absences:form.absenceTypes.annualLeavePlanning')}
                  </Select.Option>
                  <Select.Option value="overtimeCompensation">
                    {t('absences:form.absenceTypes.overtimeCompensation')}
                  </Select.Option>
                  <Select.Option value="paidSpecialLeave">
                    {t('absences:form.absenceTypes.paidSpecialLeave')}
                  </Select.Option>
                  <Select.Option value="dayOfIllness">
                    {t('absences:form.absenceTypes.dayOfIllness')}
                  </Select.Option>
                  <Select.Option value="school">
                    {t('absences:form.absenceTypes.school')}
                  </Select.Option>
                  <Select.Option value="training">
                    {t('absences:form.absenceTypes.training')}
                  </Select.Option>
                  <Select.Option value="parentalLeave">
                    {t('absences:form.absenceTypes.parentalLeave')}
                  </Select.Option>
                  <Select.Option value="maternityLeave">
                    {t('absences:form.absenceTypes.maternityLeave')}
                  </Select.Option>
                  {/*
                  <Select.Option value="Other">
                    {t('absences:form.absenceTypes.other')}
                  </Select.Option>
                                    <Select.Option value="UnpaidLeave">
                    {t('absences:form.absenceTypes.unpaidLeave')}
                  </Select.Option>
            */}
                </Select>
              </Form.Item>
            </Flex.Item>
            {absenceTypeOther && (
              <Flex.Item flex={1} minWidth={MIN_WIDTH_FORM_ITEM}>
                <Form.Item
                  name="reason"
                  colon={colon}
                  label={t('absences:form.labels.absenceType')}
                >
                  <Input disabled={disabled} />
                </Form.Item>
              </Flex.Item>
            )}
            <Flex.Item flex={1} minWidth={MIN_WIDTH_FORM_ITEM}>
              <Form.Item
                name="applicantId"
                label={t('absences:form.labels.applicantId')}
                rules={[
                  {
                    required: true,
                    message: t('absences:form.validation.missingApplicantId'),
                  },
                ]}
              >
                <ContactPicker
                  contactType="InternalContact"
                  disabled={!canSelectApplicant || disableForm}
                  filter={
                    !writeOtherUserAbsenceByGlobalRole &&
                    writeOtherUserAbsenceByOfficeRole
                      ? filter
                      : undefined
                  }
                />
              </Form.Item>
            </Flex.Item>
          </Flex.Row>
          <Divider className={classes.divider} />

          <Flex.Row childrenGap={theme.old.spacing.unit(1)}>
            <Flex.Item
              flex={20}
              className={classNames({ [classes.errorField]: periodError })}
            >
              <Form.Item
                label={t('absences:form.labels.period')}
                colon={colon}
                noStyle
              >
                <CustomDateRangePicker
                  disabled={disableForm}
                  startDate={period[0]} // momentPropTypes.momentObj or null,
                  startDateId="absenceProposal_startDateId" // PropTypes.string.isRequired,
                  startDatePlaceholderText={moment()
                    .add('1', 'day')
                    .startOf('day')
                    .format('DD.MM.YYYY')}
                  endDate={period[1]} // momentPropTypes.momentObj or null,
                  endDateId="absenceProposal_endDateId" // PropTypes.string.isRequired,
                  endDatePlaceholderText={moment()
                    .add('1', 'day')
                    .startOf('day')
                    .format('DD.MM.YYYY')}
                  onDatesChange={({ startDate, endDate }) => {
                    if (startDate === null && endDate === null) {
                      setPeriod([
                        moment().add('1', 'day').startOf('day'),
                        moment().add('1', 'day').startOf('day'),
                      ]);
                      setIsSingleDay(true);
                    } else if (startDate !== null) {
                      setPeriod([startDate, endDate]);
                      setIsSingleDay(startDate.isSame(endDate, 'day'));
                    }
                  }} // PropTypes.func.isRequired,
                  anchorDirection={'ANCHOR_RIGHT'}
                  small={true}
                  regular={false}
                  withFullScreenPortal={false}
                  daySize={30}
                  hideKeyboardShortcutsPanel={true}
                  minimumNights={0}
                  showClearDates={isToday ? false : true}
                  isOutsideRange={false}
                />

                <Typography.Text type="danger">
                  {periodError ?? ' '}
                </Typography.Text>
              </Form.Item>
            </Flex.Item>
            {absenceTypeAnnualLeaveWithoutPlanning && (
              <Flex.Item flex={3}>
                <Input
                  value={formatNumber(absentWorkDays?.absentWorkdays)}
                  disabled
                  className={classes.absentWorkDays}
                />
              </Flex.Item>
            )}
          </Flex.Row>
          <Row
            gutter={theme.old.spacing.unit(rowGutter)}
            className={classes.halfDayCheckboxRow}
          >
            <Col span={24}>
              <Flex.Row>
                <Form.Item
                  name="firstIsHalfDay"
                  colon={colon}
                  valuePropName="checked"
                  className={
                    absenceTypeAnnualLeaveWithoutPlanning
                      ? classes.halfDayCheckboxFirstWithAbsentWorkDays
                      : classes.halfDayCheckboxFirstWithoutAbsentWorkDays
                  }
                >
                  <Checkbox
                    disabled={disableForm}
                    onChange={() => setFirstIsHalfDay(!firstIsHalfDay)}
                  >
                    {t(
                      isSingleDay
                        ? 'absences:form.labels.am'
                        : 'absences:form.labels.firstIsHalfDay'
                    )}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  name="lastIsHalfDay"
                  colon={colon}
                  className={classes.noMarginBottom}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={disableForm}
                    onChange={() => setLastIsHalfDay(!lastIsHalfDay)}
                  >
                    {t(
                      isSingleDay
                        ? 'absences:form.labels.pm'
                        : 'absences:form.labels.lastIsHalfDay'
                    )}
                  </Checkbox>
                </Form.Item>
              </Flex.Row>
            </Col>
          </Row>

          {absentWorkDays?.publicHolidays.length > 0 && (
            <>
              <div className={classes.publicHolidaysCount}>
                {t('absences:form.publicHolidays', {
                  count: absentWorkDays?.publicHolidays.length,
                })}
              </div>
              <Flex.Column className={classes.publicHolidays}>
                {absentWorkDays.publicHolidays
                  .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                  .map((publicHoliday) => (
                    <Flex.Row key={publicHoliday.date}>
                      <Flex.Item className={classes.publicHolidayDate}>
                        {compactDateWithWeekDayFormatString(publicHoliday.date)}
                      </Flex.Item>
                      <Flex.Item flex={1}>{publicHoliday.name}</Flex.Item>
                    </Flex.Row>
                  ))}
              </Flex.Column>
            </>
          )}
          <Divider
            style={
              absentWorkDays?.publicHolidays.length > 0
                ? { marginTop: '16px' }
                : { marginTop: '0px' }
            }
          />
          <Flex.Row flexWrap="wrap" columnGap={theme.old.spacing.baseSpacing}>
            <Flex.Item flex={1} minWidth={MIN_WIDTH_FORM_ITEM}>
              <Form.Item
                name="principalId"
                label={t('absences:form.labels.principalId')}
                rules={[
                  {
                    required: true,
                    message: t('absences:form.validation.missingPrincipalId'),
                  },
                ]}
              >
                <Select<ContactId>
                  disabled={disabled}
                  showSearch
                  optionFilterProp="label"
                  className={classNames({ [classes.pickerDisabled]: disabled })}
                >
                  {missingPrincipalSelect(
                    principalIds,
                    contactsByIdState,
                    form
                  )}
                  {principalIds.map((principalId) => {
                    if (
                      !contactsByIdState[principalId]?.firstName ||
                      !contactsByIdState[principalId]?.lastName
                    )
                      return '';
                    return (
                      <Select.Option
                        value={principalId}
                        key={principalId}
                        label={
                          contactsByIdState[principalId]?.firstName &&
                          contactsByIdState[principalId]?.lastName
                            ? `${contactsByIdState[principalId]?.firstName} ${contactsByIdState[principalId]?.lastName}`
                            : ''
                        }
                      >
                        {contactsByIdState[principalId]?.firstName &&
                        contactsByIdState[principalId]?.lastName
                          ? `${contactsByIdState[principalId]?.firstName} ${contactsByIdState[principalId]?.lastName}`
                          : ''}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Flex.Item>
            <Flex.Item flex={1} minWidth={MIN_WIDTH_FORM_ITEM}>
              <Form.Item
                name="substituteId"
                label={t('absences:form.labels.substituteId')}
                rules={[
                  {
                    required: true,
                    message: t('absences:form.validation.missingSubstituteId'),
                  },
                ]}
              >
                <ContactPicker
                  contactType="InternalContact"
                  disabled={disableForm}
                  excludedContactIds={[currentApplicantId]}
                />
              </Form.Item>
            </Flex.Item>
          </Flex.Row>
          {substituteOnLeave && contactSubstitute && (
            <Row gutter={theme.old.spacing.unit(rowGutter)}>
              <Col span={24}>
                {absenceOverview && (
                  <Alert
                    type={'warning'}
                    className={classes.substituteWarning}
                    message={
                      <Flex.Row alignItems="center">
                        <FontAwesomeIcon
                          icon={['fal', 'exclamation-circle']}
                          className={classes.overviewIcon}
                        />
                        <Flex.Item flex={1}>
                          <Typography>
                            {t('absences:form.warning.substituteOnLeave', {
                              contactSubstitute: `${contactSubstitute.firstName} ${contactSubstitute.lastName}`,
                              startingDaySubstituteLeave,
                              endingDaySubstituteLeave,
                            })}
                          </Typography>
                        </Flex.Item>
                      </Flex.Row>
                    }
                  />
                )}
              </Col>
            </Row>
          )}
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name="notifyContactIds"
                label={t('absences:form.labels.notifyContactIds')}
              >
                <ContactPicker
                  multiple
                  contactType="InternalContact"
                  disabled={disableForm}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={theme.old.spacing.unit(rowGutter)}>
            <Col span={24}>
              <Form.Item
                name="note"
                label={t('absences:form.labels.notes')}
                colon={colon}
                style={{ marginBottom: 0 }}
              >
                <Input.TextArea disabled={disableForm} rows={3} />
              </Form.Item>
            </Col>
          </Row>
          {absenceTypeDayOfIllness && medicalCertificates.length > 0 && (
            <CollapseWithHeader
              label={t('absences:form.labels.medicalCertificate', {
                amount: medicalCertificates.length,
              })}
              enableContentBox={false}
              defaultCollapsed={false}
            >
              <Flex.Column childrenGap={theme.old.spacing.baseSpacing}>
                {medicalCertificates.map((medicalCertificate) => (
                  <MedicalCertificateListItem
                    medicalCertificate={medicalCertificate}
                    onEdit={handleOnEditMedicalCertificate}
                    onArchive={handleOnArchiveMedicalCertificate}
                    officeId={
                      !writeOtherUserAbsenceByGlobalRole &&
                      writeOtherUserAbsenceByOfficeRole
                        ? officeId
                        : undefined
                    }
                  />
                ))}
              </Flex.Column>
            </CollapseWithHeader>
          )}
        </Flex.Item>
        <Flex.Row justifyContent="end" marginTop={theme.old.spacing.unit(3)}>
          <Flex.Row flex={1} childrenGap={theme.old.spacing.unit(1)}>
            {(absenceState === 'accepted' ||
              absenceState === 'revokeAccepted') && (
              <Button
                type="default"
                className={classes.danger}
                disabled={isFormValuesChanged || isPeriodChanged}
                onClick={handleOnRevoke}
              >
                {t('absences:tableMenu.revoke')}
              </Button>
            )}
            {(absenceState === 'planned' || absenceState === 'requested') && (
              <Button
                type="default"
                className={classes.danger}
                disabled={isFormValuesChanged || isPeriodChanged}
                onClick={handleOnDelete}
              >
                {t('absences:tableMenu.delete')}
              </Button>
            )}
            {isUserMe && absenceTypeDayOfIllness && (
              <Button
                onClick={() => {
                  medicalCertificateDrawerRef.current?.setVisible(true);
                }}
                type="default"
                iconProp={['fal', 'file-medical']}
              >
                {t('absences:medicalCertificate.addMedicalCertificate')}
              </Button>
            )}
          </Flex.Row>
          {onCancel && (
            <Flex.Item>
              <Button
                type="default"
                onClick={cancel}
                style={{ marginRight: '8px' }}
              >
                {cancelLabel}
              </Button>
            </Flex.Item>
          )}

          {!!actionLabel && (
            <Flex.Item>
              <Button
                htmlType="submit"
                disabled={isSaveChangesButtonDisabled || isSubstituteIdEmpty}
                onClick={validatePeriod}
              >
                {actionLabel}
              </Button>
            </Flex.Item>
          )}
        </Flex.Row>
        {absenceTypeDayOfIllness && (
          <MedicalCertificateDrawer
            ref={medicalCertificateDrawerRef}
            from={period[0] && period[0].toISOString(true).split('T')[0]}
            to={period[1] && period[1].toISOString(true).split('T')[0]}
            absenceProposalId={
              (initialValues as AbsenceProposal)?.absenceProposalId
            }
            onMedicalCertificatedFetch={setMedicalCertificates}
            officeId={
              !writeOtherUserAbsenceByGlobalRole &&
              writeOtherUserAbsenceByOfficeRole
                ? officeId
                : undefined
            }
            isUserMe={initialValues.applicantId === userMe.id}
            onClose={handleOnMedicalCertificateDrawerClose}
            isEditMode={!!editMode}
          />
        )}
      </Form>
    );
  });

export default AbsenceProposalForm;
