import React from 'react';
import { Notification } from '../../../models/Notification';
import { makePrioStyles } from '../../../theme/utils';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import Flex from '../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Input,
  Modal,
  Typography,
  notification as antNotification,
  Divider,
} from 'antd';
import { ContactId } from '../../../models/Types';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import {
  compactDateWithWeekDayFormatString,
  fullDateFormatFormatString,
} from '../../../util';
import { Link } from 'react-router-dom';
import { User } from '../../../models/User';
import { useDispatch, useSelector } from 'react-redux';
import { getContact, RootReducerState } from '../../../apps/main/rootReducer';
import { Contact } from '../../../models/Contact';
import { markReadNotifications, markReadNotificationsLocal } from '../actions';
import {
  apiAcceptAbsenceProposal,
  apiAcceptOfficeAbsenceProposal,
  apiDeclineAbsenceProposal,
  apiDeclineOfficeAbsenceProposal,
} from '../../absences/api';
import useAccessRights from '../../users/hooks/useAccessRights';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { getIconForAbsenceType } from '../../../util/icon';

const useStyles = makePrioStyles((theme) => ({
  root: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.unit(2),
  },
  secondaryText: {
    color: theme.old.typography.colors.muted,
    fontSize: theme.old.typography.fontSize.label,
  },
  decline: {
    '&.ant-btn:not(.ant-btn-primary) > .svg-inline--fa': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  accept: {
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.green,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '& > .prio-button-icon': {
        color: theme.old.palette.chromaticPalette.green,
      },
    },
  },
  textAfterIcon: {
    // marginLeft: theme.old.spacing.defaultPadding,
  },
  iconBeforeText: {
    margin: 'auto 0',
    color: theme.old.palette.chromaticPalette.grey,
  },
}));

interface AbsenceNotificationItemProps {
  notification: Notification;
  onClose?: VoidFunction;
  userMe?: User;
}

export const AbsenceNotificationItem: React.FC<AbsenceNotificationItemProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const { notification, onClose, userMe } = props;

  const dispatch = useDispatch();

  const proposal = notification.data as AbsenceProposal;

  const contact = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, getContactId(proposal, userMe))
  );

  const contactSubstitude = useSelector<RootReducerState, Contact>((state) =>
    getContact(state, proposal.substituteId)
  );

  const { showGlobalInHrModule, showOfficesInHrModule } = useAccessRights(
    ['showGlobalInHrModule', 'showOfficesInHrModule'],
    proposal?.officeId ? { officeId: proposal?.officeId } : undefined
  );

  const parsedProposal = getParsedProposal(
    proposal,
    contact,
    userMe,
    t,
    showGlobalInHrModule,
    showOfficesInHrModule
  );

  const showModalDecline = () => {
    Modal.confirm({
      icon: null,
      title: (
        <>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', getIconForAbsenceType(proposal.absenceType)]}
              color={theme.old.palette.primaryColor}
            />
            <span className={classes.textAfterIcon}>
              {t(
                `absences:notification.modal.decline.${proposal.absenceState}.${proposal.absenceType}`
              )}
            </span>
          </Flex.Row>
        </>
      ),
      content: (
        <>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <Divider></Divider>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', 'user']}
              className={classes.iconBeforeText}
            />
            <span className={classes.textAfterIcon}>
              {contact.firstName} {contact.lastName}
            </span>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', 'arrow-from-left']}
              className={classes.iconBeforeText}
            />
            <span className={classes.textAfterIcon}>
              {compactDateWithWeekDayFormatString(proposal.from)}
            </span>
            <span className={classes.textAfterIcon}> - </span>
            <span className={classes.textAfterIcon}>
              {compactDateWithWeekDayFormatString(proposal.to)}
            </span>
          </Flex.Row>
          {proposal.absenceType === 'annualLeave' && (
            <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
              <FontAwesomeIcon
                icon={['fal', 'calendar-day']}
                className={classes.iconBeforeText}
              />
              <span className={classes.textAfterIcon}>
                {proposal.absentWorkdays}{' '}
                {t(`absences:notification.modal.accept.days`)}
              </span>
            </Flex.Row>
          )}
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', 'people-arrows']}
              className={classes.iconBeforeText}
            />
            <span className={classes.textAfterIcon}>
              {contactSubstitude.firstName} {contactSubstitude.lastName}
            </span>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <Divider></Divider>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', 'info-circle']}
              className={classes.iconBeforeText}
            />
            <span className={classes.textAfterIcon}>
              {t(
                `absences:notification.modal.declineContent.${proposal.absenceState}.${proposal.absenceType}`,
                {
                  name: contact.firstName + ' ' + contact.lastName,
                }
              )}
            </span>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <Divider></Divider>
          </Flex.Row>
          <Flex.Row>
            <Input.TextArea
              placeholder={t('absences:notification.modal.content')}
            />
          </Flex.Row>
        </>
      ),
      okText: t('absences:revokeAbsence.confirmationDialog.okText'),
      cancelText: t('absences:revokeAbsence.confirmationDialog.cancelText'),
      onOk() {
        const promise = new Promise<void>(async (resolve) => {
          if (showOfficesInHrModule || showGlobalInHrModule) {
            const { result } =
              proposal.officeId && showOfficesInHrModule
                ? await apiDeclineOfficeAbsenceProposal(
                    proposal.absenceProposalId,
                    proposal.officeId
                  )
                : await apiDeclineAbsenceProposal(proposal.absenceProposalId);
            if (result.status >= 200 && result.status < 300) {
            } else {
              antNotification.open({
                message: t('common:error'),
                description: t(
                  'absences:errorMessages.declineAbsenceProposalError'
                ),
              });
            }
          }
          dispatch(
            markReadNotificationsLocal(
              notification.notificationId,
              notification
            )
          );
          resolve();
        });
        return promise;
      },
      onCancel() {},
    });
  };

  const showModalAccept = async () => {
    Modal.confirm({
      icon: null,
      title: (
        <>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', getIconForAbsenceType(proposal.absenceType)]}
              color={theme.old.palette.primaryColor}
            />
            <span className={classes.textAfterIcon}>
              {t(
                `absences:notification.modal.accept.${proposal.absenceState}.${proposal.absenceType}`
              )}
            </span>
          </Flex.Row>
        </>
      ),
      content: (
        <>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <Divider></Divider>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', 'user']}
              className={classes.iconBeforeText}
            />
            <span className={classes.textAfterIcon}>
              {contact.firstName} {contact.lastName}
            </span>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', 'arrow-from-left']}
              className={classes.iconBeforeText}
            />
            <span className={classes.textAfterIcon}>
              {compactDateWithWeekDayFormatString(proposal.from)}
            </span>
            <span className={classes.textAfterIcon}> - </span>
            <span className={classes.textAfterIcon}>
              {compactDateWithWeekDayFormatString(proposal.to)}
            </span>
          </Flex.Row>
          {proposal.absenceType === 'annualLeave' && (
            <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
              <FontAwesomeIcon
                icon={['fal', 'calendar-day']}
                className={classes.iconBeforeText}
              />
              <span className={classes.textAfterIcon}>
                {proposal.absentWorkdays}{' '}
                {t(`absences:notification.modal.accept.days`)}
              </span>
            </Flex.Row>
          )}
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', 'people-arrows']}
              className={classes.iconBeforeText}
            />
            <span className={classes.textAfterIcon}>
              {contactSubstitude.firstName} {contactSubstitude.lastName}
            </span>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <Divider></Divider>
          </Flex.Row>
          <Flex.Row childrenGap={theme.old.spacing.unit(2)}>
            <FontAwesomeIcon
              icon={['fal', 'info-circle']}
              className={classes.iconBeforeText}
            />
            <span className={classes.textAfterIcon}>
              {t(
                `absences:notification.modal.acceptContent.${proposal.absenceState}.${proposal.absenceType}`,
                {
                  name: contact.firstName + ' ' + contact.lastName,
                }
              )}
            </span>
          </Flex.Row>
        </>
      ),
      okText: t('absences:notification.modal.okText'),
      cancelText: t('absences:notification.modal.cancelText'),
      onOk() {
        const promise = new Promise<void>(async (resolve) => {
          if (showOfficesInHrModule || showGlobalInHrModule) {
            const { result } = showOfficesInHrModule
              ? await apiAcceptOfficeAbsenceProposal(
                  proposal.absenceProposalId,
                  proposal.officeId
                )
              : await apiAcceptAbsenceProposal(proposal.absenceProposalId);
            if (result.status >= 200 && result.status < 300) {
            } else {
              antNotification.open({
                message: t('common:error'),
                description: t(
                  'absences:errorMessages.acceptAbsenceProposalError'
                ),
              });
            }
          }
          dispatch(
            markReadNotificationsLocal(
              notification.notificationId,
              notification
            )
          );
          resolve();
        });
        return promise;
      },
      onCancel() {},
    });
  };

  const render = () => (
    <Flex.Row
      className={classes.root}
      alignItems="center"
      childrenGap={theme.old.spacing.unit(2)}
    >
      {proposal.applicantId === userMe.id ? (
        <FontAwesomeIcon
          icon={['fal', getIconForAbsenceType(proposal.absenceType)]}
          color={theme.old.palette.primaryColor}
        />
      ) : proposal.principalId === userMe.id ? (
        <FontAwesomeIcon
          icon={['fal', getIconForAbsenceType(proposal.absenceType)]}
          color={theme.old.palette.primaryColor}
        />
      ) : (
        <FontAwesomeIcon
          icon={['fal', 'sign-out']}
          color={theme.old.palette.primaryColor}
        />
      )}

      <Flex.Column>
        <Typography.Text ellipsis>{parsedProposal?.name ?? ''}</Typography.Text>
        {parsedProposal?.description && (
          <Typography.Text className={classes.secondaryText}>
            {parsedProposal.description}
          </Typography.Text>
        )}
      </Flex.Column>
      {getDetails(proposal, t)}
      <Flex.Row>
        {parsedProposal?.actions.includes('decline') && (
          <Button
            type="link"
            className={classes.decline}
            icon={<FontAwesomeIcon icon={['fal', 'times']} />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              showModalDecline();
            }}
          />
        )}
        {parsedProposal?.actions.includes('accept') && (
          <Button
            type="link"
            className={classes.accept}
            icon={<FontAwesomeIcon icon={['fal', 'check']} />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              showModalAccept();
            }}
          />
        )}
        {parsedProposal?.actions.includes('read') && (
          <Button
            type="link"
            className={classes.accept}
            icon={<FontAwesomeIcon icon={['fal', 'check']} />}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch(
                markReadNotifications(notification.notificationId, {
                  ...notification,
                  isRead: true,
                })
              );
            }}
          />
        )}
      </Flex.Row>
    </Flex.Row>
  );

  if (parsedProposal?.link) {
    return (
      <Link to={parsedProposal.link} onClick={onClose}>
        {render()}
      </Link>
    );
  }
  return render();
};

const getContactId: (proposal: AbsenceProposal, userMe: User) => ContactId = (
  proposal,
  userMe
) => {
  switch (proposal.absenceState) {
    case 'revokeRequested':
    case 'requested': {
      return proposal.applicantId;
    }
    case 'declined': {
      return proposal.principalId;
    }
    case 'revokeAccepted':
    case 'accepted': {
      if (userMe.id === proposal.applicantId) {
        return proposal.approvedBy ?? proposal.principalId;
      } else {
        return proposal.applicantId;
      }
    }
    default: {
      return proposal.approvedBy ?? proposal.principalId;
    }
  }
};
interface AbsenceNotification {
  name: string;
  description?: string;
  link?: string;
  actions: Array<'accept' | 'decline' | 'read'>;
}
const getParsedProposal: (
  proposal: AbsenceProposal,
  contact: Contact,
  userMe: User,
  t: TFunction,
  gloablAcces: boolean,
  officeAccess: boolean
) => AbsenceNotification = (
  proposal,
  contact,
  userMe,
  t,
  gloablAcces,
  officeAccess
) => {
  const type: string = t(`absences:types.${proposal.absenceType}`);
  const name: string = contact
    ? `${contact.firstName} ${contact.lastName}`
    : t('common:unknownContact');

  switch (proposal.absenceState) {
    case 'revokeRequested':
    case 'requested':
    case 'revokeAccepted':
    case 'declined':
    case 'accepted': {
      if (
        userMe.id === proposal.applicantId &&
        userMe.id !== proposal.principalId
      ) {
        return {
          name: t(
            `absences:notification.principalOrMe.${proposal.absenceType}.${proposal.absenceState}`,
            {
              type: type,
            }
          ),
          description:
            proposal.absenceState === 'accepted'
              ? t('absences:notification.typesActions.acceptedBy', {
                  name: name,
                })
              : proposal.absenceState === 'declined'
              ? t('absences:notification.declined.declinedBy', {
                  name: name,
                })
              : undefined,
          actions: ['read'],
        };
      } else if (
        userMe.id === proposal.applicantId &&
        userMe.id === proposal.principalId
      ) {
        return {
          name: t(
            `absences:notification.principalOrMe.${proposal.absenceType}.${proposal.absenceState}`
          ),
          description: name,
          actions:
            proposal.absenceState === 'requested' ||
            proposal.absenceState === 'revokeRequested'
              ? ['accept', 'decline']
              : ['read'],
        };
      } else if (
        userMe.id === proposal.substituteId &&
        userMe.id !== proposal.principalId
      ) {
        return {
          name: t(
            `absences:notification.substitude.${proposal.absenceType}.${proposal.absenceState}`
          ),
          description: name,
          actions: ['read'],
        };
      } else if (
        userMe.id === proposal.substituteId &&
        userMe.id === proposal.principalId
      ) {
        return {
          name:
            proposal.absenceState === 'requested' ||
            proposal.absenceState === 'revokeRequested'
              ? t(
                  `absences:notification.principalOrMe.${proposal.absenceType}.${proposal.absenceState}`
                )
              : t(
                  `absences:notification.substitude.${proposal.absenceType}.${proposal.absenceState}`
                ),
          description: name,
          actions:
            proposal.absenceState === 'requested' ||
            proposal.absenceState === 'revokeRequested'
              ? ['accept', 'decline']
              : ['read'],
        };
      } else if (userMe.id === proposal.principalId) {
        return {
          name: t(
            `absences:notification.principalOrMe.${proposal.absenceType}.${proposal.absenceState}`
          ),
          description: name,
          actions:
            proposal.absenceState === 'requested' ||
            proposal.absenceState === 'revokeRequested'
              ? ['accept', 'decline']
              : ['read'],
        };
      } else {
        return {
          name: t(
            `absences:notification.other.${proposal.absenceType}.${proposal.absenceState}`
          ),
          description: name,
          actions: ['read'],
        };
      }
    }
    default:
      return null;
  }
};

const getDetails: (
  proposal: AbsenceProposal,
  t: TFunction
) => React.ReactNode = (proposal, t) => {
  return (
    <>
      <Flex.Item flex={1}></Flex.Item>
      <Flex.Item flex={3}>
        {proposal.from === proposal.to ? (
          fullDateFormatFormatString(proposal.from)
        ) : (
          <>
            {fullDateFormatFormatString(proposal.from)} {t('common:to')}{' '}
            {fullDateFormatFormatString(proposal.to)}
          </>
        )}
      </Flex.Item>
    </>
  );
};

export default AbsenceNotificationItem;
