import React, { forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {} from 'antd';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { VirtualTable } from '@prio365/prio365-react-library';
import useFilterContext, {
  useFilterContextDataConvertion,
} from '../../../../components/Filter/hooks/useFilterContext';
import { Project } from '../../../../models/Project';
import FilterResultNoItemsScreen from '../../../../components/Filter/FilterResultNoItemsScreen';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import useOfficesContext from '../../../companies/hooks/useOfficesContext';
import CopyableTextTile from '../../../../components/CopyableTextTile';

const useStyles = makePrioStyles((theme) => ({
  ellipsis: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  cell: {
    paddingLeft: 10,
    paddingRight: 10,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

export interface ProjectAdminSettingsPageTableRef {
  fetchHolidays: () => void;
}

interface ProjectAdminSettingsPageTableProps {
  className?: string;
  tableId: string;
}

export const ProjectAdminSettingsPageTable = forwardRef(
  (
    props: ProjectAdminSettingsPageTableProps,
    ref: React.Ref<ProjectAdminSettingsPageTableRef>
  ) => {
    //#region ------------------------------ Defaults
    const { tableId } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { data, isLoading } = useFilterContext<Project>();
    const projects = useFilterContextDataConvertion(data, true);
    const { getOfficeById } = useOfficesContext();
    //#endregion

    //#region ------------------------------ Methods / Handlers
    //#endregion

    //#region ------------------------------ Effects
    //#endregion
    const pxLengthGuide = 300;

    //use effect data change
    useEffect(() => {
      console.log(projects);
    }, [projects]);

    const columns: Column<Project>[] = [
      {
        sortingFn: (a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
        title: t('settings:projectTable.name'),
        id: 'name',
        accessor: 'name',
        Cell: (cellProps) => (
          <p className={classes.ellipsis}>{cellProps.value}</p>
        ),
        width: 180,
        className: classes.cell,
      },
      {
        sortingFn: (a, b) =>
          a.shortName.toLowerCase().localeCompare(b.shortName.toLowerCase()),
        title: t('settings:projectTable.shortname'),
        id: 'shortname',
        accessor: 'shortName',
        Cell: (cellProps) => (
          <p className={classes.ellipsis}>{cellProps.value}</p>
        ),
        width: 150,
        className: classes.cell,
      },
      {
        sortingFn: (a, b) =>
          a.number.toLowerCase().localeCompare(b.number.toLowerCase()),
        title: t('settings:projectTable.number'),
        id: 'number',
        accessor: 'number',
        Cell: (cellProps) => (
          <p className={classes.ellipsis}>{cellProps.value}</p>
        ),
        width: 150,
        className: classes.cell,
      },
      {
        sortingFn: (a, b) =>
          a.projectStatus
            .toLocaleString()
            .localeCompare(b.projectStatus.toLocaleString()),
        title: t('settings:projectTable.projectStatus'),
        id: 'projectStatus',
        accessor: 'projectStatus',
        Cell: (cellProps) => (
          <p className={classes.ellipsis}>{cellProps.value}</p>
        ),
        width: 100,
        className: classes.cell,
      },
      {
        sortingFn: (a, b) =>
          getOfficeById(a.internalOfficeId)?.name?.localeCompare(
            getOfficeById(b.internalOfficeId)?.name
          ),
        title: t('settings:projectTable.internalOfficeId'),
        id: 'internalOfficeId',
        accessor: 'internalOfficeId',
        Cell: ({ originalData: { internalOfficeId } }) => (
          <div className={classes.ellipsis}>
            {getOfficeById(internalOfficeId)?.name}
          </div>
        ),
        width: 150,
        className: classes.cell,
      },
      {
        sortingFn: (a, b) =>
          a.projectId.toLowerCase().localeCompare(b.projectId.toLowerCase()),
        title: t('settings:projectTable.projectId'),
        id: 'projectId',
        accessor: 'projectId',
        Cell: (cellProps) => (
          <p className={classes.ellipsis} title={cellProps.value}>
            <CopyableTextTile value={cellProps.value} />
          </p>
        ),
        width: pxLengthGuide,
      },
      {
        sortingFn: (a, b) =>
          a.groupId.toLowerCase().localeCompare(b.groupId.toLowerCase()),
        title: t('settings:projectTable.groupId'),
        id: 'groupId',
        accessor: 'groupId',
        Cell: (cellProps) => (
          <p className={classes.ellipsis}>
            <CopyableTextTile value={cellProps.value} />
          </p>
        ),
        width: pxLengthGuide,
      },
      //sharedMailboxUserId
      {
        sortingFn: (a, b) =>
          a.sharedMailboxUserId
            .toLocaleString()
            .localeCompare(b.sharedMailboxUserId.toLocaleString()),
        title: t('settings:projectTable.sharedMailboxUserId'),
        id: 'sharedMailboxUserId',
        accessor: 'sharedMailboxUserId',
        Cell: (cellProps) => (
          <p className={classes.ellipsis}>
            <CopyableTextTile value={cellProps.value} />
          </p>
        ),
        width: pxLengthGuide,
      },
      {
        sortingFn: (a, b) =>
          a.masterPlanId
            .toLocaleString()
            .localeCompare(b.masterPlanId.toLocaleString()),
        title: t('settings:projectTable.masterPlanId'),
        id: 'masterPlanId',
        accessor: 'masterPlanId',
        Cell: (cellProps) => (
          <p className={classes.ellipsis}>
            <CopyableTextTile value={cellProps.value} />
          </p>
        ),
        width: pxLengthGuide,
      },
    ];

    return (
      <VirtualTable<Project>
        id={tableId}
        columns={columns}
        data={projects}
        // selectedItems={selectedHolidays}
        resizable="absolute"
        // onSelectionChange={onSelectionChange}
        onCheckEquality={(a, b) => a.projectId === b.projectId}
        rowsAreSelectable
        noItemsScreen={<FilterResultNoItemsScreen />}
        loading={
          isLoading && {
            type: 'noItems',
          }
        }
      />
    );
  }
);

export default ProjectAdminSettingsPageTable;
