import React, { useEffect, useRef, useState } from 'react';
import {
  AbsenceProposal,
  AbsentWorkDaysResult,
} from '../../../models/AbsenceProposal';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { Divider, Modal, notification, Typography } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { InternalOffice } from '../../../models/Office';
import {
  compactDateWithWeekDayFormatString,
  compactDateFormatString,
} from '../../../util';
import {
  getContactsByIdState,
  getUserMe,
} from '../../../apps/main/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
  AbsenceProposalId,
  AbsenceState,
  ContactId,
  OfficeId,
} from '../../../models/Types';
import {
  apiAcceptAbsenceProposal,
  apiAcceptOfficeAbsenceProposal,
  apiDeclineAbsenceProposal,
  apiDeclineOfficeAbsenceProposal,
  apiFetchAbsentWorkDays,
} from '../api';
import i18n from '../../../i18n';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { debounceAbsencesMe } from '../actions';
import { TFunction } from 'i18next';
import CollapseWithHeader from '../../../components/CollapseWithHeader';
import MedicalCertificateListItem from './MedicalCertificate/MedicalCertificateListItem';
import { MedicalCertificate } from '../../../models/MedicalCertificate';
import MedicalCertificateDrawer, {
  MedicalCertificateDrawerRef,
} from './MedicalCertificate/MedicalCertificateDrawer';

const useStyles = makePrioStyles((theme) => ({
  root: { height: '100%', overflow: 'hidden' },
  publicHolidays: {
    maxHeight: '117px',
    overflowY: 'auto',
  },
  divider: {
    marginBottom: '4px',
  },
  revokeButton: {
    color: theme.old.palette.chromaticPalette.red,
    background: 'transparent',
    '&:hover': {
      background: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
    },
  },
  form: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  label: {
    color: theme.old.typography.colors.muted,
    fontSize: '14px',
  },
  notes: {
    width: '100%',
    height: '150px',
    border: 'solid lightgrey 1px',
    padding: theme.old.spacing.unit(1),
  },
}));

const translateAccept = (state: AbsenceState) => {
  switch (state) {
    case 'requested': {
      return i18n.t('absences:actions.accept');
    }
    case 'declined': {
      return i18n.t('absences:actions.acceptDeclined');
    }
    case 'revokeRequested': {
      return i18n.t('absences:actions.acceptRevoke');
    }
    default: {
      return null;
    }
  }
};

const translateDecline = (state: AbsenceState) => {
  switch (state) {
    case 'requested': {
      return i18n.t('absences:actions.decline');
    }
    case 'accepted': {
      return i18n.t('absences:actions.declineAccepted');
    }
    case 'revokeRequested': {
      return i18n.t('absences:actions.declineRevoke');
    }
    default: {
      return null;
    }
  }
};

interface AbsenceProposalDetailsProps {
  absenceProposal: AbsenceProposal;
  officesById: { [officeId: string]: InternalOffice };
  setSubDrawerOpen?: (open: boolean) => void;
  setSelectedAbsenceProposal: (value: AbsenceProposal) => void;
  setOpen: (value: boolean) => void;
  officeId?: OfficeId;
  updateAbsenceTable: (absenceProposal: AbsenceProposal) => void;
}

const fetchAbsentWorkDays = async (
  absenceProposal: AbsenceProposal,
  setAbsentWorkDays: (data: AbsentWorkDaysResult) => void,
  t: TFunction,
  userMeId: ContactId,
  officeId?: OfficeId
) => {
  const from = absenceProposal.from;
  const to = absenceProposal.to;
  const internalContactId = absenceProposal.applicantId;
  const firstIsHalfDay = absenceProposal.firstIsHalfDay;
  const lastIsHalfDay = absenceProposal.lastIsHalfDay;
  const { result, data } = await apiFetchAbsentWorkDays(
    from,
    to,
    firstIsHalfDay,
    lastIsHalfDay,
    userMeId !== internalContactId ? internalContactId : undefined,
    officeId
  );

  if (result.status >= 200 && result.status < 300) {
    if (typeof data === 'object') {
      setAbsentWorkDays(data);
    }
  } else {
    notification.open({
      message: t('common:error'),
      description: t('absences:errorMessages.fetchAbsentWorkdaysError'),
    });
  }
};

export const AbsenceProposalDetails: React.FC<AbsenceProposalDetailsProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();

  const {
    absenceProposal,
    officesById,
    setSubDrawerOpen,
    setOpen,
    officeId,
    updateAbsenceTable,
  } = props;
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const contactsByIdState = useSelector(getContactsByIdState);
  const { id: userMeId } = useSelector(getUserMe);

  const isUserMe =
    userMeId?.toLowerCase() === absenceProposal?.applicantId.toLowerCase();

  const medicalCertificateDrawerRef = useRef<MedicalCertificateDrawerRef>(null);

  const [showStateUpdateModal, setShowStateUpdateModal] =
    useState<boolean>(false);

  const isInitialAction =
    absenceProposal.absenceState === 'requested' ||
    absenceProposal.absenceState === 'revokeRequested';

  const [absentWorkDays, setAbsentWorkDays] = useState<AbsentWorkDaysResult>({
    absentWorkdays: (absenceProposal as AbsenceProposal)?.absentWorkdays ?? 1,
    publicHolidays: [],
  });

  const [medicalCertificates, setMedicalCertificates] = useState<
    MedicalCertificate[]
  >([]);

  const getCorrespondingState = (value: AbsenceState) => {
    if (value === 'requested') {
      return 'requested';
    }

    if (value === 'declined') {
      return 'accepted';
    }

    if (value === 'accepted') {
      return 'declined';
    }

    if (value === 'revokeAccepted') {
      return 'revokeDeclined';
    }

    if (value === 'revokeDeclined') {
      return 'revokeAccepted';
    }

    if (value === 'planned') {
      return 'planned';
    }

    return null;
  };

  const absenceStateColor = (value: string) => {
    switch (value) {
      case 'accepted':
        return theme.old.palette.chromaticPalette.green;
      case 'declined':
        return theme.old.palette.chromaticPalette.red;
      case 'planned':
        return theme.old.palette.chromaticPalette.grey;
      case 'requested':
        return theme.old.palette.chromaticPalette.yellow;
      case 'revokeAccepted':
        return theme.old.palette.chromaticPalette.red;
      case 'revokeDeclined':
        return theme.old.palette.chromaticPalette.green;
      case 'revokeRequested':
        return theme.old.palette.chromaticPalette.grey;
    }
    return 'black';
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const PrintFromTo = (
    firstIsHalfDay: boolean,
    lastIsHafDay: boolean,
    from: string,
    to: string
  ) => {
    const startDayEqualEndDay = from === to;
    const MultipleDaysWithoutHalfDays =
      !(firstIsHalfDay || lastIsHafDay) && !startDayEqualEndDay;
    const MultipleDaysWithHalfDays =
      (firstIsHalfDay || lastIsHafDay) && !startDayEqualEndDay;

    if (MultipleDaysWithoutHalfDays) {
      return (
        <div>
          {compactDateWithWeekDayFormatString(absenceProposal.from)} -{' '}
          {compactDateWithWeekDayFormatString(absenceProposal.to)}
        </div>
      );
    }

    if (MultipleDaysWithHalfDays) {
      return (
        <>
          <div>
            {`${compactDateWithWeekDayFormatString(absenceProposal.from)} 
            ${
              firstIsHalfDay && !startDayEqualEndDay
                ? ` (${t('absences:details.halfDayWord')})`
                : ''
            } -`}
          </div>
          <div>
            {`${compactDateWithWeekDayFormatString(absenceProposal.to)} 
            ${
              lastIsHafDay && !startDayEqualEndDay
                ? ` (${t('absences:details.halfDayWord')})`
                : ''
            }`}
          </div>
        </>
      );
    }

    return (
      <div>
        {`${compactDateWithWeekDayFormatString(absenceProposal.to)} ${
          firstIsHalfDay && !lastIsHafDay
            ? ` (${t('absences:details.firstHalfOfDay')})`
            : ''
        }
        ${
          lastIsHafDay && !firstIsHalfDay
            ? ` (${t('absences:details.secondHalfOfDay')})`
            : ''
        }`}
      </div>
    );
  };

  const declineProposal = async (absenceProposalId: AbsenceProposalId) => {
    const { result } = officeId
      ? await apiDeclineOfficeAbsenceProposal(absenceProposalId, officeId)
      : await apiDeclineAbsenceProposal(absenceProposalId);
    if (result.status >= 200 && result.status < 300) {
      updateAbsenceTable({
        ...absenceProposal,
        absenceState: absenceProposal.absenceState.includes('revoke')
          ? 'revokeDeclined'
          : 'declined',
      });
      if (
        userMeId?.toLowerCase() === absenceProposal?.applicantId.toLowerCase()
      ) {
        dispatch(debounceAbsencesMe());
      }
      handleClose();
    } else {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.declineAbsenceProposalError'),
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const acceptProposal = async (absenceProposalId: AbsenceProposalId) => {
    const { result } = officeId
      ? await apiAcceptOfficeAbsenceProposal(absenceProposalId, officeId)
      : await apiAcceptAbsenceProposal(absenceProposalId);
    if (result.status >= 200 && result.status < 300) {
      updateAbsenceTable({
        ...absenceProposal,
        absenceState: absenceProposal.absenceState.includes('revoke')
          ? 'revokeAccepted'
          : 'accepted',
      });
      if (
        userMeId?.toLowerCase() === absenceProposal?.applicantId.toLowerCase()
      ) {
        dispatch(debounceAbsencesMe());
      }
      handleClose();
    } else {
      notification.open({
        message: t('common:error'),
        description: t('absences:errorMessages.acceptAbsenceProposalError'),
      });
    }
  };

  const declineSelectedProposal = () => {
    if (!absenceProposal) return;
    declineProposal(absenceProposal.absenceProposalId);
  };
  const acceptSelectedProposal = () => {
    if (!absenceProposal) return;
    acceptProposal(absenceProposal.absenceProposalId);
  };

  const handleOnModalOk = () => {
    if (
      absenceProposal.absenceState === 'accepted' ||
      absenceProposal.absenceState === 'revokeAccepted'
    ) {
      declineSelectedProposal();
    }

    if (
      absenceProposal.absenceState === 'declined' ||
      absenceProposal.absenceState === 'revokeDeclined'
    ) {
      acceptSelectedProposal();
    }
  };

  const handleOnModalClose = () => {
    setShowStateUpdateModal(false);
  };

  const updateMedicalCertificates = (
    medicalCertificate: MedicalCertificate
  ) => {
    setMedicalCertificates((currentState) => {
      const index = currentState.findIndex(
        (mc) =>
          mc.medicalCertificateId === medicalCertificate.medicalCertificateId
      );
      if (index >= 0) {
        currentState[index] = medicalCertificate;
      } else {
        currentState.push(medicalCertificate);
      }
      return currentState;
    });
  };

  const handleOnMedicalCertificateDrawerClose = (
    medicalCertificate: MedicalCertificate
  ) => {
    if (medicalCertificate) {
      updateMedicalCertificates(medicalCertificate);
    }
  };

  const handleOnEditMedicalCertificate = (
    medicalCertificate: MedicalCertificate
  ) => {
    if (medicalCertificateDrawerRef.current) {
      medicalCertificateDrawerRef.current.setMedicalCertificate(
        medicalCertificate
      );
      medicalCertificateDrawerRef.current.setVisible(true);
    }
  };

  const handleOnArchiveMedicalCertificate = (
    medicalCertificate: MedicalCertificate
  ) => {
    setMedicalCertificates((currentState) => {
      return currentState.filter(
        (mc) =>
          mc.medicalCertificateId !== medicalCertificate.medicalCertificateId
      );
    });
    if (medicalCertificateDrawerRef.current) {
      medicalCertificateDrawerRef.current.refetchMedicalCertificates();
    }
  };

  const handleOnApproveMedicalCertificate = (
    medicalCertificate: MedicalCertificate
  ) => {
    updateMedicalCertificates(medicalCertificate);

    if (medicalCertificateDrawerRef.current) {
      medicalCertificateDrawerRef.current.refetchMedicalCertificates();
    }
  };

  const handleOnRejectMedicalCertificate = (
    medicalCertificate: MedicalCertificate
  ) => {
    updateMedicalCertificates(medicalCertificate);

    if (medicalCertificateDrawerRef.current) {
      medicalCertificateDrawerRef.current.refetchMedicalCertificates();
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    fetchAbsentWorkDays(
      absenceProposal,
      setAbsentWorkDays,
      t,
      userMeId,
      officeId
    );
  }, [absenceProposal, t, contactsByIdState, userMeId, officeId]);
  //#endregion

  return (
    <Flex.Column
      childrenGap={theme.old.spacing.unit(2)}
      className={classes.root}
    >
      <Typography.Title level={2}>
        {t('absences:details.title')}
      </Typography.Title>
      <Flex.Column
        flex={1}
        childrenGap={theme.old.spacing.unit(2)}
        overflow="auto"
      >
        <Flex.Row>
          <Flex.Column flex={1}>
            <Typography.Text className={classes.label}>
              {t('absences:details.applicantId')}
            </Typography.Text>
            <div>
              {contactsByIdState[absenceProposal.applicantId]?.firstName}{' '}
              {contactsByIdState[absenceProposal.applicantId]?.lastName}
            </div>
            {absenceProposal.officeId && (
              <div>{officesById[absenceProposal.officeId]?.name}</div>
            )}
          </Flex.Column>
          <Flex.Column flex={1}>
            <Typography.Text className={classes.label}>
              {t('absences:details.createdAt')}
            </Typography.Text>
            <div>{compactDateFormatString(absenceProposal.createdAt)}</div>
          </Flex.Column>
        </Flex.Row>
        <Flex.Row>
          <Flex.Column flex={1}>
            <Typography.Text className={classes.label}>
              {t('absences:details.absenceType')}
            </Typography.Text>
            <div>{t(`absences:types.${absenceProposal.absenceType}`)}</div>
            {absenceProposal.absenceType === 'other' && (
              <div>{absenceProposal.reason}</div>
            )}
          </Flex.Column>
          <Flex.Column flex={1}>
            <Typography.Text className={classes.label}>
              {t('absences:details.absenceState')}
            </Typography.Text>
            <div
              style={{
                color: absenceStateColor(absenceProposal.absenceState),
                fontWeight: '500',
              }}
            >
              {t(`absences:states.${absenceProposal.absenceState}`)}
            </div>
          </Flex.Column>
        </Flex.Row>
        <Divider className={classes.divider} />
        <Flex.Row>
          <Flex.Column flex={1}>
            <Typography.Text className={classes.label}>
              {t('absences:details.period')}
            </Typography.Text>
            {PrintFromTo(
              absenceProposal.firstIsHalfDay,
              absenceProposal.lastIsHalfDay,
              absenceProposal.to,
              absenceProposal.from
            )}
          </Flex.Column>
          {absenceProposal.absenceType === 'annualLeave' && (
            <Flex.Column flex={1}>
              <Typography.Text className={classes.label}>
                {t('absences:details.days')}
              </Typography.Text>
              <div>{absenceProposal.absentWorkdays}</div>
            </Flex.Column>
          )}
        </Flex.Row>
        <Flex.Column>
          {absentWorkDays?.publicHolidays.length > 0 && (
            <>
              <Typography.Text className={classes.label}>
                {t('absences:details.publicHolidays', {
                  count: absentWorkDays?.publicHolidays.length,
                })}
              </Typography.Text>
              <Flex.Column className={classes.publicHolidays}>
                {absentWorkDays.publicHolidays
                  .sort((a, b) => Date.parse(a.date) - Date.parse(b.date))
                  .map((publicHoliday) => (
                    <Flex.Row key={publicHoliday.date}>
                      <Flex.Column flex={1}>
                        {compactDateWithWeekDayFormatString(publicHoliday.date)}
                      </Flex.Column>
                      <Flex.Column flex={1}>{publicHoliday.name}</Flex.Column>
                    </Flex.Row>
                  ))}
              </Flex.Column>
            </>
          )}
        </Flex.Column>
        <Divider className={classes.divider} />
        {contactsByIdState[absenceProposal.principalId] && (
          <Flex.Row>
            <Flex.Column>
              <Typography.Text className={classes.label}>
                {t('absences:details.principalId')}
              </Typography.Text>
              <div>
                {contactsByIdState[absenceProposal.principalId].firstName}{' '}
                {contactsByIdState[absenceProposal.principalId].lastName}
              </div>
            </Flex.Column>
          </Flex.Row>
        )}
        {absenceProposal?.notifyContactIds?.length > 0 && (
          <Flex.Row>
            <Flex.Column>
              <Typography.Text className={classes.label}>
                {t('absences:details.notifyContactIds')}
              </Typography.Text>
              {absenceProposal.notifyContactIds.map((contactId) => (
                <div>
                  {contactsByIdState[contactId.toLowerCase()]?.firstName}{' '}
                  {contactsByIdState[contactId.toLowerCase()]?.lastName}
                </div>
              ))}
            </Flex.Column>
          </Flex.Row>
        )}
        <Flex.Row>
          <Flex.Column>
            <Typography.Text className={classes.label}>
              {t('absences:details.substituteId')}
            </Typography.Text>
            <div>
              {
                contactsByIdState[absenceProposal.substituteId.toLowerCase()]
                  ?.firstName
              }
              {!contactsByIdState[absenceProposal.substituteId.toLowerCase()]
                ? t('common:unknownContact')
                : ' '}
              {
                contactsByIdState[absenceProposal.substituteId.toLowerCase()]
                  ?.lastName
              }
            </div>
          </Flex.Column>
        </Flex.Row>
        {absenceProposal.absenceType === 'dayOfIllness' &&
          medicalCertificates.length > 0 && (
            <CollapseWithHeader
              label={t('absences:form.labels.medicalCertificate', {
                amount: medicalCertificates.length,
              })}
              enableContentBox={false}
              defaultCollapsed={false}
            >
              <Flex.Column childrenGap={theme.old.spacing.baseSpacing}>
                {medicalCertificates.map((medicalCertificate) => (
                  <MedicalCertificateListItem
                    medicalCertificate={medicalCertificate}
                    officeId={officeId}
                    isUserMe={false}
                    onEdit={handleOnEditMedicalCertificate}
                    onArchive={handleOnArchiveMedicalCertificate}
                    onApprove={handleOnApproveMedicalCertificate}
                    onReject={handleOnRejectMedicalCertificate}
                    enableStateEdit
                  />
                ))}
              </Flex.Column>
            </CollapseWithHeader>
          )}
        {absenceProposal.note && (
          <>
            {' '}
            <Divider className={classes.divider} />
            <Flex.Row flex={1}>
              <Flex.Column flex={1}>
                <Typography.Text className={classes.label}>
                  {t('absences:details.notes')}
                </Typography.Text>
                <div>{absenceProposal.note}</div>
              </Flex.Column>
            </Flex.Row>
          </>
        )}
      </Flex.Column>
      <Flex.Row marginTop={theme.old.spacing.unit(3)}>
        <Flex.Row flex={1} childrenGap={theme.old.spacing.baseSpacing}>
          <Button type="default" onClick={() => setSubDrawerOpen(true)}>
            {t('absences:actions.edit')}
          </Button>
          {isUserMe && absenceProposal.absenceType === 'dayOfIllness' && (
            <Button
              iconProp={['fal', 'file-medical']}
              onClick={() => {
                medicalCertificateDrawerRef.current?.setVisible(true);
              }}
              type="default"
            >
              {t('absences:medicalCertificate.addMedicalCertificate')}
            </Button>
          )}
        </Flex.Row>
        {absenceProposal?.absenceState &&
          (isInitialAction ? (
            <>
              <Flex.Item>
                {translateDecline(absenceProposal?.absenceState) && (
                  <Button
                    type="default"
                    onClick={declineSelectedProposal}
                    className={classes.revokeButton}
                    style={{ marginRight: theme.old.spacing.unit(1) }}
                  >
                    {translateDecline(absenceProposal?.absenceState)}
                  </Button>
                )}
              </Flex.Item>
              <Flex.Item>
                {translateAccept(absenceProposal?.absenceState) && (
                  <Button type="primary" onClick={acceptSelectedProposal}>
                    {translateAccept(absenceProposal?.absenceState)}
                  </Button>
                )}
              </Flex.Item>
            </>
          ) : (
            absenceProposal?.absenceState !== 'planned' && (
              <Flex.Item>
                <Button
                  type="primary"
                  onClick={() => setShowStateUpdateModal(true)}
                >
                  {t('absences:changeAbsenceState.title')}
                </Button>
              </Flex.Item>
            )
          ))}
      </Flex.Row>
      {absenceProposal.absenceType === 'dayOfIllness' && (
        <MedicalCertificateDrawer
          ref={medicalCertificateDrawerRef}
          from={absenceProposal.from}
          to={absenceProposal.to}
          absenceProposalId={absenceProposal.absenceProposalId}
          onMedicalCertificatedFetch={setMedicalCertificates}
          officeId={officeId ?? undefined}
          isUserMe={false}
          onClose={handleOnMedicalCertificateDrawerClose}
          isEditMode
        />
      )}
      <Modal
        title={t('absences:changeAbsenceState.title')}
        okText={t('absences:changeAbsenceState.okText')}
        cancelText={t('absences:changeAbsenceState.cancelText')}
        onOk={handleOnModalOk}
        onCancel={handleOnModalClose}
        visible={showStateUpdateModal}
      >
        <Flex.Row>
          <div
            style={{ fontWeight: 500, marginBottom: theme.old.spacing.unit(1) }}
          >
            {t('absences:changeAbsenceState.heading')}
          </div>
        </Flex.Row>
        <Flex.Row>
          <Flex.Column flex={1}>
            <div>{t('absences:changeAbsenceState.currentState')}</div>
            <div>{t(`absences:states.${absenceProposal.absenceState}`)}</div>
          </Flex.Column>
          <Flex.Column flex={1}>
            <div>{t('absences:changeAbsenceState.newState')}</div>
            <div>
              {t(
                `absences:states.${getCorrespondingState(
                  absenceProposal.absenceState
                )}`
              )}
            </div>
          </Flex.Column>
        </Flex.Row>
      </Modal>
    </Flex.Column>
  );
};

export default AbsenceProposalDetails;
