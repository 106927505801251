import React from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { AbsenceOverview } from '../../../../models/AbsenceProposal';
import Flex from '../../../../components/Flex';
import { useTranslation } from 'react-i18next';
import ChartIcon from './ChartIcon';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: theme.spacing.small,
    background: `${theme.colors.application.background.light}40`,
    borderRadius: theme.borderRadius.regular,
    border: `1px solid ${theme.colors.application.border}`,
  },
  customDivider: {
    height: '100%',
    width: 0,
    borderLeft: `1px solid ${theme.colors.application.border}`,
    margin: '0px 16px',
  },
  label: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.application.typography.muted,
  },
}));

interface AbsenceOverviewChartMinimalProps {
  className?: string;
  absenceOverview: AbsenceOverview;
}

export const AbsenceOverviewChartMinimal: React.FC<
  AbsenceOverviewChartMinimalProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, absenceOverview } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const {
    remainingDaysPreviousYear,
    vacationDay,
    takenVacationDays,
    requestedVacationDays,
    acceptedVacationDays,
  } = absenceOverview ?? {
    remainingDaysPreviousYear: 0,
    vacationDay: 0,
    takenVacationDays: 0,
    requestedVacationDays: 0,
    acceptedVacationDays: 0,
  };
  const remainingVacationDays =
    vacationDay +
    remainingDaysPreviousYear -
    takenVacationDays -
    acceptedVacationDays -
    requestedVacationDays;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row
      alignItems="center"
      justifyContent="center"
      childrenGap={theme.spacing.regular}
      className={classNames(classes.root, className)}
    >
      {remainingDaysPreviousYear > 0 && (
        <Flex.Column alignItems="center">
          <ChartIcon
            isMinimal
            iconName="history"
            days={remainingDaysPreviousYear}
            color={theme.old.palette.chromaticPalette.blue}
          />
          <span className={classes.label}>
            {t('absences:absenceOverview.popOver.remainingDaysPreviousYear')}
          </span>
        </Flex.Column>
      )}
      <Flex.Column alignItems="center">
        <ChartIcon
          isMinimal
          iconName="island-tropical"
          days={vacationDay}
          color={theme.old.palette.chromaticPalette.darkBlue}
        />
        <span className={classes.label}>
          {t('absences:absenceOverview.popOver.vacationDay')}
        </span>
      </Flex.Column>
      {remainingDaysPreviousYear < 0 && (
        <Flex.Column alignItems="center">
          <ChartIcon
            isMinimal
            iconName="history"
            days={remainingDaysPreviousYear}
            color={theme.old.palette.chromaticPalette.red}
          />
          <span className={classes.label}>
            {t('absences:absenceOverview.popOver.remainingDaysPreviousYear')}
          </span>
        </Flex.Column>
      )}
      <div style={{ width: '32px', height: '100%' }}>
        <div className={classes.customDivider} />
      </div>
      <Flex.Column alignItems="center">
        <ChartIcon
          isMinimal
          iconName="calendar-alt"
          days={takenVacationDays}
          color={theme.old.palette.chromaticPalette.grey}
        />
        <span className={classes.label}>
          {t('absences:absenceOverview.popOver.takenVacationDays')}
        </span>
      </Flex.Column>
      <Flex.Column alignItems="center">
        <ChartIcon
          isMinimal
          iconName="calendar-check"
          days={acceptedVacationDays}
          color={theme.old.palette.chromaticPalette.green}
        />
        <span className={classes.label}>
          {t('absences:absenceOverview.popOver.acceptedVacationDays')}
        </span>
      </Flex.Column>
      <Flex.Column alignItems="center">
        <ChartIcon
          isMinimal
          iconName="calendar-plus"
          days={requestedVacationDays}
          color={theme.old.palette.chromaticPalette.yellow}
        />
        <span className={classes.label}>
          {t('absences:absenceOverview.popOver.requestedVacationDays')}
        </span>
      </Flex.Column>
      <Flex.Column alignItems="center">
        <ChartIcon
          isMinimal
          iconName="calendar"
          days={remainingVacationDays}
          color={theme.old.palette.chromaticPalette.darkBlue}
        />
        <span className={classes.label}>
          {t('absences:absenceOverview.popOver.remainingVacationDays')}
        </span>
      </Flex.Column>
    </Flex.Row>
  );
};

export default AbsenceOverviewChartMinimal;
