import React from 'react';
import { Popover } from 'antd';
import { VirtualTable } from '@prio365/prio365-react-library';
import { ProjectDistributionList } from '../../../models/ProjectDistributionList';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import PrioSpinner from '../../../components/PrioSpinner';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    overflow: 'hidden',
    flex: 1,
  },
  row: {
    cursor: 'pointer',
  },
  table: {
    borderRadius: '4px',
  },
  tableCell: {
    display: 'contents',
    '& > div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      marginLeft: '10px',
    },
  },
  mailPopover: {
    maxHeight: '150px',
    overflowY: 'auto',
  },
}));

interface ProjectDistributionListTableProps {
  className?: string;
  distributionLists: ProjectDistributionList[];
  onRowClick?: (
    item: ProjectDistributionList,
    index: number,
    selectedItems: ProjectDistributionList[]
  ) => VirtualListItemOnRowProps<null, null>;
  onDelete?: (records: ProjectDistributionList[]) => void;
  selectedTableItems?: ProjectDistributionList[];
  setSelectedTableItems?: (items: ProjectDistributionList[]) => void;
  showBulkActions?: boolean;
}

export const ProjectDistributionListTable: React.FC<
  ProjectDistributionListTableProps
> = (props) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    distributionLists,
    onRowClick,
    onDelete,
    selectedTableItems,
    setSelectedTableItems,
    showBulkActions = true,
  } = props;

  const { t } = useTranslation();

  const columns: Column<ProjectDistributionList>[] = [
    {
      title: t('projects:projectDistributionList.table.name'),
      id: 'name',
      accessor: 'name',
      sortingFn: (a, b) => a.name.localeCompare(b.name),
      Cell: (CellProps) => <div> {CellProps.value}</div>,
      width: 20,
      className: classes.tableCell,
    },
    {
      title: t(
        'projects:projectDistributionList.table.projectDistributionContacts'
      ),
      id: 'projectDistributionContacts',
      accessor: 'projectDistributionContacts',
      Cell: (CellProps) => {
        const joinedValue = CellProps.value
          .map((item) => item.eMail)
          .join('; ');

        return (
          <Popover
            content={
              <div className={classes.mailPopover}>
                <div
                  style={{
                    fontWeight: 600,
                    marginBottom: theme.old.spacing.unit(0.5),
                  }}
                >
                  {t('projects:projectDistributionList.form.contacts.contacts')}
                </div>
                {CellProps.value.map((item) => (
                  <div>{item.eMail}</div>
                ))}
              </div>
            }
          >
            <div>{joinedValue}</div>
          </Popover>
        );
      },
      width: 80,
      className: classes.tableCell,
    },
  ];

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <VirtualTable
        id="projectDistributionListTable"
        className={classes.table}
        classNameTableRow={classes.row}
        columns={columns}
        data={distributionLists.sort((a, b) => a.name.localeCompare(b.name))}
        rowsAreSelectable
        selectedItems={selectedTableItems}
        onSelectionChange={setSelectedTableItems}
        resizable="relative"
        onRow={onRowClick}
        loading={<PrioSpinner size="small" />}
        actionBarActions={
          showBulkActions
            ? [
                {
                  children: t('common:actions.delete'),
                  iconProp: ['fal', 'trash'],
                  onClick: () => onDelete(selectedTableItems),
                },
              ]
            : null
        }
      />
    </Flex.Column>
  );
};

export default ProjectDistributionListTable;
