import { combineReducers, Reducer } from 'redux';
import {
  FETCH_INTERNAL_PROJECT_CONTACTS_COMMIT,
  CREATE_INTERNAL_PROJECT_CONTACT_REQUEST,
  FETCH_INTERNAL_PROJECT_CONTACTS_REQUEST,
  FETCH_INTERNAL_PROJECT_CONTACTS_ROLLBACK,
  CREATE_INTERNAL_PROJECT_CONTACT_ROLLBACK,
  CREATE_INTERNAL_PROJECT_CONTACT_COMMIT,
  UPDATE_INTERNAL_PROJECT_CONTACT_REQUEST,
  UPDATE_INTERNAL_PROJECT_CONTACT_ROLLBACK,
  UPDATE_INTERNAL_PROJECT_CONTACT_COMMIT,
  UPDATE_INTERNAL_PROJECT_CONTACT_ME_NOTIFICATION,
  UPDATE_PROJECT_CONTACTS_REQUEST,
  UPDATE_PROJECT_CONTACTS_COMMIT,
  UPDATE_PROJECT_CONTACTS_ROLLBACK,
} from '../actions';
import {
  InternalProjectContact,
  UpdateProjectContact,
} from '../../../models/ProjectContacts';
import { ProjectId, ContactId, ProjectRole } from '../../../models/Types';
import { CLEAR_PRIO_CACHE } from '../../../actions';

export interface InternalProjectContactsState {
  data: DataState;
  meta: InternalProjectContactsMeta;
}

export interface DataState {
  [projectId: string]: InternalProjectContact[];
}

const data: Reducer<DataState, any> = (state = {}, action) => {
  switch (action.type) {
    case FETCH_INTERNAL_PROJECT_CONTACTS_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: payload,
      };
    }

    /** CREATE */

    case CREATE_INTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        payload,
        meta: { projectId },
      } = action;
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []),
          {
            ...payload,
            isTemporary: true,
          },
        ],
      };
    }

    case CREATE_INTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { projectId, contactId },
      } = action;
      const contacts = state[projectId];
      if (!contacts) return state;
      return {
        ...state,
        [projectId]: contacts.filter(
          (projectContact: InternalProjectContact) =>
            projectContact.contactId !== contactId
        ),
      };
    }

    case CREATE_INTERNAL_PROJECT_CONTACT_COMMIT: {
      const {
        meta: { projectId, contactId },
        payload: createdContact,
      } = action;
      const contacts = state[projectId];
      if (!contacts) return state;
      return {
        ...state,
        [projectId]: contacts.map((projectContact: InternalProjectContact) =>
          projectContact.contactId === contactId
            ? createdContact.internalProjectContacts[0]
            : projectContact
        ),
      };
    }

    /** UPDATE */
    case UPDATE_INTERNAL_PROJECT_CONTACT_ME_NOTIFICATION: {
      const { projectId, payload } = action;
      const originalContact = (state[projectId] ?? []).find(
        (projectContact) =>
          projectContact.internalProjectContactId ===
          payload.internalProjectContactId
      );
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (projectContact) =>
              projectContact.internalProjectContactId !==
              payload.internalProjectContactId
          ),
          {
            ...originalContact,
            emailNotificationEnabled: payload.emailNotificationEnabled,
          },
        ],
      };
    }

    case UPDATE_INTERNAL_PROJECT_CONTACT_REQUEST: {
      const {
        meta: { projectId, projectContacts },
      } = action;
      const changedInternalProjectContacts =
        projectContacts as InternalProjectContact[];
      const changedInternalProjectContactsIds =
        changedInternalProjectContacts.map(
          (contact) => contact.internalProjectContactId
        );
      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).filter(
            (projectContact) =>
              !changedInternalProjectContactsIds.includes(
                projectContact.internalProjectContactId
              )
          ),
          ...changedInternalProjectContacts.map((contact) => contact),
        ],
      };
    }

    case UPDATE_INTERNAL_PROJECT_CONTACT_COMMIT: {
      const {
        payload,
        meta: { projectId, projectContacts },
      } = action;

      const updatedContacts =
        payload.internalProjectContacts as InternalProjectContact[];
      const changedInternalProjectContacts =
        projectContacts as InternalProjectContact[];
      const changedInternalProjectContactsIds =
        changedInternalProjectContacts.map(
          (contact) => contact.internalProjectContactId
        );

      return {
        ...state,
        [projectId]: (state[projectId] ?? []).map((projectContact) => {
          if (
            changedInternalProjectContactsIds.includes(
              projectContact.internalProjectContactId
            )
          ) {
            return updatedContacts.find(
              (contact) =>
                contact.internalProjectContactId ===
                projectContact.internalProjectContactId
            );
          }
          return projectContact;
        }),
      };
    }

    case UPDATE_INTERNAL_PROJECT_CONTACT_ROLLBACK: {
      const {
        meta: { projectId, rollbackContacts },
      } = action;

      const _rollbackContacts = rollbackContacts as InternalProjectContact[];

      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).map((projectContact) => {
            const rollbackContact = _rollbackContacts.find(
              (contact) =>
                contact.internalProjectContactId ===
                projectContact.internalProjectContactId
            );
            return rollbackContact ?? projectContact;
          }),
        ],
      };
    }

    case UPDATE_PROJECT_CONTACTS_REQUEST: {
      const {
        meta: { projectId, request },
      } = action;
      const _request = request as UpdateProjectContact[];

      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).map((projectContact) => {
            const updatedContact = _request.find(
              (contact) =>
                contact.internalProjectContactId ===
                projectContact.internalProjectContactId
            );
            return { ...projectContact, ...(updatedContact ?? {}) };
          }),
        ],
      };
    }

    case UPDATE_PROJECT_CONTACTS_COMMIT: {
      const {
        payload,
        meta: { projectId },
      } = action;

      const _payload: InternalProjectContact[] =
        payload.internalProjectContacts ?? [];

      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).map((projectContact) => {
            const updatedContact = _payload.find(
              (contact) =>
                contact.internalProjectContactId ===
                projectContact.internalProjectContactId
            );
            return updatedContact ?? projectContact;
          }),
        ],
      };
    }

    case UPDATE_PROJECT_CONTACTS_ROLLBACK: {
      const {
        meta: { projectId, rollback },
      } = action;

      const _rollback = rollback as InternalProjectContact[];

      return {
        ...state,
        [projectId]: [
          ...(state[projectId] ?? []).map((projectContact) => {
            const rollbackContact = _rollback.find(
              (contact) =>
                contact.internalProjectContactId ===
                projectContact.internalProjectContactId
            );
            return rollbackContact ?? projectContact;
          }),
        ],
      };
    }

    case CLEAR_PRIO_CACHE: {
      return {};
    }

    default:
      return state;
  }
};

interface InternalProjectContactsMeta {
  isFetching: boolean;
  hasError: boolean;
  errorMessage?: string;
}

const meta: Reducer<InternalProjectContactsMeta, any> = (
  state = { isFetching: false, hasError: false },
  action
) => {
  switch (action.type) {
    case FETCH_INTERNAL_PROJECT_CONTACTS_REQUEST: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case FETCH_INTERNAL_PROJECT_CONTACTS_COMMIT: {
      return {
        ...state,
        isFetching: false,
      };
    }
    case FETCH_INTERNAL_PROJECT_CONTACTS_ROLLBACK: {
      return {
        ...state,
        isFetching: false,
        hasError: true,
        errorMessage:
          'projects:errorMessages.fetchInternalProjectContactsError',
      };
    }
    case CLEAR_PRIO_CACHE: {
      return { isFetching: false, hasError: false };
    }
    default:
      return state;
  }
};

export default combineReducers<InternalProjectContactsState>({
  data,
  meta,
});

export const getData: (state: InternalProjectContactsState) => DataState = (
  state: InternalProjectContactsState
) => state.data;
export const getAllInternalProjectContacts: (
  state: any,
  projectId: ProjectId
) => InternalProjectContact[] = (
  state: InternalProjectContactsState,
  projectId: ProjectId
) => (state.data[projectId] ?? []).filter((c) => !c.isArchived);

export const isProjectMember: (
  state: InternalProjectContactsState,
  projectId: ProjectId,
  contactId: ContactId
) => boolean = (state: InternalProjectContactsState, projectId, contactId) =>
  (state.data[projectId] ?? []).findIndex((c) => c.contactId === contactId) >
  -1;

export const getIsFetching: (state: InternalProjectContactsState) => boolean = (
  state
) => state.meta.isFetching;
export const getHasError: (state: InternalProjectContactsState) => boolean = (
  state
) => state.meta.hasError;
export const getErrorMessage: (
  state: InternalProjectContactsState
) => string = (state) => state.meta.errorMessage;

export const getInternalProjectRoles: (
  state: InternalProjectContactsState,
  projectId: ProjectId,
  contactId: ContactId
) => ProjectRole[] = (state, projectId, contactId) =>
  state.data[projectId]?.find((contact) => contact.contactId === contactId)
    ?.projectRoles ?? [];
