import { Moment } from 'moment';
import {
  TaskId,
  DateTimeString,
  BucketId,
  ProjectId,
  ContactId,
  MessageId,
} from './Types';

export interface Task {
  taskId?: TaskId;
  projectId?: ProjectId;
  title: string;
  description?: string;
  internalProjectContactId?: ContactId;
  bucketId?: BucketId;
  dueDateTime?: DateTimeString; //relative?
  startDateTime?: DateTimeString; //relative?
  details?: {
    references?: {
      [referenceKey: string]: {
        alias: string;
        type: string;
        previewPriority: string;
        lastModifiedBy: {
          user: {
            id: string;
          };
        };
        lastModifiedDateTime: string;
      };
    };
  };
  planId?: string;
}

export interface TaskFormModel {
  projectId?: ProjectId;
  title: string;
  description?: string;
  internalProjectContactId?: ContactId;
  dueDateTime?: Moment; //relative?
  startDateTime?: Moment; //relative?
  bucketId?: string;
}
export interface CreateTaskRequest {
  projectId?: ProjectId;
  title: string;
  description?: string;
  internalProjectContactId?: ContactId;
  bucketId?: BucketId;
  dueDateTime?: DateTimeString; //relative?
  startDateTime?: DateTimeString; //relative?
  sourceEmailUrl?: string;
  messageId?: MessageId;
}

export interface JiraFormModel {
  project: string;
  assignee?: string;
  summary: string;
  description?: string;
  issueType?: string;
}
export interface CreateJiraTask {
  project: string;
  assignee?: string;
  summary: string;
  description?: string;
  issueType?: string;
  sourceEmailUrl?: string;
}

export const emptyCreateTaskRequest: CreateTaskRequest = {
  title: '',
};

export interface JiraUser {
  accountType: string;
  accountId: string;
  active: boolean;
  applicationRoles: {
    callback: string;
    items: {
      defaultGroups: string[];
      defaultGroupsDetails: {
        groupId: string;
        name: string;
        self: string;
      }[];
      defined: boolean;
      groupDetails: {
        groupId: string;
        name: string;
        self: string;
      }[];
      groups: string[];
      hasUnlimitedSeats: boolean;
      key: string;
      name: string;
      numberOfSeats: number;
      platform: boolean;
      remainingSeats: number;
      selectedByDefault: boolean;
      userCount: number;
      userCountDescription: string;
    }[];
    maxResults: number;
    pagingCallback: string;
    size: number;
  };
  avatarUrls: {
    var16x16: string;
    var24x24: string;
    var32x32: string;
    var48x48: string;
  };
  displayName: string;
  emailAddress: string;
  expand: string;
  groups: {
    callback: string;
    items: {
      groupId: string;
      name: string;
      self: string;
    }[];
    maxResults: number;
    pagingCallback: string;
    size: number;
  };
  key: string;
  locale: string;
  name: string;
  self: string;
  varTimeZone: string;
}

export interface JiraIssueType {
  avatarId: number;
  description: string;
  entityId: string;
  hierarchyLevel: number;
  iconUrl: string;
  id: string;
  name: string;
  scope: {
    type: string;
    project: {
      projectTypeKey: string;
      avatarUrls: {
        var16x16: string;
        var24x24: string;
        var32x32: string;
        var48x48: string;
      };
      id: string;
      key: string;
      name: string;
      projectCategory: {
        description: string;
        id: string;
        name: string;
        self: string;
      };
      self: string;
      simplified: boolean;
    };
  };
  self: string;
  subtask: boolean;
}

export interface JiraProjectSelection {
  key: string;
  displayName: string;
}

export interface JiraProject {
  assigneeType: string;
  projectTypeKey: string;
  style: string;
  archived: boolean;
  archivedBy: {
    accountType: string;
    accountId: string;
    active: boolean;
    applicationRoles: {
      callback: string;
      items: {
        defaultGroups: string[];
        defaultGroupsDetails: {
          groupId: string;
          name: string;
          self: string;
        }[];
        defined: boolean;
        groupDetails: {
          groupId: string;
          name: string;
          self: string;
        }[];
        groups: string[];
        hasUnlimitedSeats: boolean;
        key: string;
        name: string;
        numberOfSeats: number;
        platform: boolean;
        remainingSeats: number;
        selectedByDefault: boolean;
        userCount: number;
        userCountDescription: string;
      }[];
      maxResults: number;
      pagingCallback: string;
      size: number;
    };
    avatarUrls: {
      var16x16: string;
      var24x24: string;
      var32x32: string;
      var48x48: string;
    };
    displayName: string;
    emailAddress: string;
    expand: string;
    groups: {
      callback: string;
      items: {
        groupId: string;
        name: string;
        self: string;
      }[];
      maxResults: number;
      pagingCallback: string;
      size: number;
    };
    key: string;
    locale: string;
    name: string;
    self: string;
    varTimeZone: string;
  };
  archivedDate: string;
  avatarUrls: {
    var16x16: string;
    var24x24: string;
    var32x32: string;
    var48x48: string;
  };
  components: {
    assigneeType: string;
    realAssigneeType: string;
    ari: string;
    assignee: {
      accountType: string;
      accountId: string;
      active: boolean;
      applicationRoles: {
        callback: string;
        items: {
          defaultGroups: string[];
          defaultGroupsDetails: {
            groupId: string;
            name: string;
            self: string;
          }[];
          defined: boolean;
          groupDetails: {
            groupId: string;
            name: string;
            self: string;
          }[];
          groups: string[];
          hasUnlimitedSeats: boolean;
          key: string;
          name: string;
          numberOfSeats: number;
          platform: boolean;
          remainingSeats: number;
          selectedByDefault: boolean;
          userCount: number;
          userCountDescription: string;
        }[];
        maxResults: number;
        pagingCallback: string;
        size: number;
      };
      avatarUrls: {
        var16x16: string;
        var24x24: string;
        var32x32: string;
        var48x48: string;
      };
      displayName: string;
      emailAddress: string;
      expand: string;
      groups: {
        callback: string;
        items: {
          groupId: string;
          name: string;
          self: string;
        }[];
        maxResults: number;
        pagingCallback: string;
        size: number;
      };
      key: string;
      locale: string;
      name: string;
      self: string;
      varTimeZone: string;
    };
    description: string;
    id: string;
    isAssigneeTypeValid: boolean;
    lead: {
      accountType: string;
      accountId: string;
      active: boolean;
      applicationRoles: {
        callback: string;
        items: {
          defaultGroups: string[];
          defaultGroupsDetails: {
            groupId: string;
            name: string;
            self: string;
          }[];
          defined: boolean;
          groupDetails: {
            groupId: string;
            name: string;
            self: string;
          }[];
          groups: string[];
          hasUnlimitedSeats: boolean;
          key: string;
          name: string;
          numberOfSeats: number;
          platform: boolean;
          remainingSeats: number;
          selectedByDefault: boolean;
          userCount: number;
          userCountDescription: string;
        }[];
        maxResults: number;
        pagingCallback: string;
        size: number;
      };
      avatarUrls: {
        var16x16: string;
        var24x24: string;
        var32x32: string;
        var48x48: string;
      };
      displayName: string;
      emailAddress: string;
      expand: string;
      groups: {
        callback: string;
        items: {
          groupId: string;
          name: string;
          self: string;
        }[];
        maxResults: number;
        pagingCallback: string;
        size: number;
      };
      key: string;
      locale: string;
      name: string;
      self: string;
      varTimeZone: string;
    };
    leadAccountId: string;
    leadUserName: string;
    metadata: {
      [key: string]: string;
    };
    name: string;
    project: string;
    projectId: number;
    realAssignee: {
      accountType: string;
      accountId: string;
      active: boolean;
      applicationRoles: {
        callback: string;
        items: {
          defaultGroups: string[];
          defaultGroupsDetails: {
            groupId: string;
            name: string;
            self: string;
          }[];
          defined: boolean;
          groupDetails: {
            groupId: string;
            name: string;
            self: string;
          }[];
          groups: string[];
          hasUnlimitedSeats: boolean;
          key: string;
          name: string;
          numberOfSeats: number;
          platform: boolean;
          remainingSeats: number;
          selectedByDefault: boolean;
          userCount: number;
          userCountDescription: string;
        }[];
        maxResults: number;
        pagingCallback: string;
        size: number;
      };
      avatarUrls: {
        var16x16: string;
        var24x24: string;
        var32x32: string;
        var48x48: string;
      };
      displayName: string;
      emailAddress: string;
      expand: string;
      groups: {
        callback: string;
        items: {
          groupId: string;
          name: string;
          self: string;
        }[];
        maxResults: number;
        pagingCallback: string;
        size: number;
      };
      key: string;
      locale: string;
      name: string;
      self: string;
      varTimeZone: string;
    };
    self: string;
  }[];
  deleted: boolean;
  deletedBy: {
    accountType: string;
    accountId: string;
    active: boolean;
    applicationRoles: {
      callback: string;
      items: {
        defaultGroups: string[];
        defaultGroupsDetails: {
          groupId: string;
          name: string;
          self: string;
        }[];
        defined: boolean;
        groupDetails: {
          groupId: string;
          name: string;
          self: string;
        }[];
        groups: string[];
        hasUnlimitedSeats: boolean;
        key: string;
        name: string;
        numberOfSeats: number;
        platform: boolean;
        remainingSeats: number;
        selectedByDefault: boolean;
        userCount: number;
        userCountDescription: string;
      }[];
      maxResults: number;
      pagingCallback: string;
      size: number;
    };
    avatarUrls: {
      var16x16: string;
      var24x24: string;
      var32x32: string;
      var48x48: string;
    };
    displayName: string;
    emailAddress: string;
    expand: string;
    groups: {
      callback: string;
      items: {
        groupId: string;
        name: string;
        self: string;
      }[];
      maxResults: number;
      pagingCallback: string;
      size: number;
    };
    key: string;
    locale: string;
    name: string;
    self: string;
    varTimeZone: string;
  };
  deletedDate: string;
  description: string;
  email: string;
  expand: string;
  favourite: boolean;
  id: string;
  insight: {
    lastIssueUpdateTime: string;
    totalIssueCount: number;
  };
  isPrivate: boolean;
  issueTypeHierarchy: {
    baseLevelId: number;
    levels: {
      aboveLevelId: number;
      belowLevelId: number;
      externalUuid: string;
      hierarchyLevelNumber: number;
      id: number;
      issueTypeIds: number[];
      level: number;
      name: string;
      projectConfigurationId: number;
    }[];
  };
  issueTypes: {
    avatarId: number;
    description: string;
    entityId: string;
    hierarchyLevel: number;
    iconUrl: string;
    id: string;
    name: string;
    scope: {
      type: string;
      project: {
        projectTypeKey: string;
        avatarUrls: {
          var16x16: string;
          var24x24: string;
          var32x32: string;
          var48x48: string;
        };
        id: string;
        key: string;
        name: string;
        projectCategory: {
          description: string;
          id: string;
          name: string;
          self: string;
        };
        self: string;
        simplified: boolean;
      };
    };
    self: string;
    subtask: boolean;
  }[];
  key: string;
  landingPageInfo: {
    attributes: {
      [key: string]: string;
    };
    boardId: number;
    boardName: string;
    projectKey: string;
    projectType: string;
    queueCategory: string;
    queueId: number;
    queueName: string;
    simpleBoard: boolean;
    simplified: boolean;
    url: string;
  };
  lead: {
    accountType: string;
    accountId: string;
    active: boolean;
    applicationRoles: {
      callback: string;
      items: {
        defaultGroups: string[];
        defaultGroupsDetails: {
          groupId: string;
          name: string;
          self: string;
        }[];
        defined: boolean;
        groupDetails: {
          groupId: string;
          name: string;
          self: string;
        }[];
        groups: string[];
        hasUnlimitedSeats: boolean;
        key: string;
        name: string;
        numberOfSeats: number;
        platform: boolean;
        remainingSeats: number;
        selectedByDefault: boolean;
        userCount: number;
        userCountDescription: string;
      }[];
      maxResults: number;
      pagingCallback: string;
      size: number;
    };
    avatarUrls: {
      var16x16: string;
      var24x24: string;
      var32x32: string;
      var48x48: string;
    };
    displayName: string;
    emailAddress: string;
    expand: string;
    groups: {
      callback: string;
      items: {
        groupId: string;
        name: string;
        self: string;
      }[];
      maxResults: number;
      pagingCallback: string;
      size: number;
    };
    key: string;
    locale: string;
    name: string;
    self: string;
    varTimeZone: string;
  };
  name: string;
  permissions: {
    canEdit: boolean;
  };
  projectCategory: {
    description: string;
    id: string;
    name: string;
    self: string;
  };
  properties: {
    [key: string]: string;
  };
  retentionTillDate: string;
  roles: {
    [key: string]: string;
  };
  self: string;
  simplified: boolean;
  url: string;
  uuid: string;
  versions: {
    approvers: {
      accountId: string;
      declineReason: string;
      description: string;
      status: string;
      additionalProperties: {
        [key: string]: string;
      };
    }[];
    archived: boolean;
    description: string;
    driver: string;
    expand: string;
    id: string;
    issuesStatusForFixVersion: {
      done: number;
      inProgress: number;
      toDo: number;
      unmapped: number;
    };
    moveUnfixedIssuesTo: string;
    name: string;
    operations: {
      href: string;
      iconClass: string;
      id: string;
      label: string;
      styleClass: string;
      title: string;
      weight: number;
    }[];
    overdue: boolean;
    project: string;
    projectId: number;
    releaseDate: string;
    released: boolean;
    self: string;
    startDate: string;
    userReleaseDate: string;
    userStartDate: string;
  }[];
}
